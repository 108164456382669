import React, { useContext, useState, useRef } from 'react';
import {
	Keyboard,
	View,
	ScrollView,
	StyleSheet,
	TouchableOpacity,
	Platform
} from 'react-native';
import {
	Button,
	Caption,
	HelperText,
	TextInput,
	Title
} from 'react-native-paper';

import { AppContext } from '~context/appContext';

import { V1Request } from '~utils/apiRequest';
import { SetAuth } from '~utils/auth';
import { useLocalData } from '~utils/localStorage';
import useExpoPushTokenRefresh from '~hooks/useExpoPushTokenRefresh';
import { Card, useTheme } from 'react-native-paper';
import call from 'react-native-phone-call';
import CallSvg from '@@icons/ic-call-us.svg';
import { WebContainer } from '~components/webContainer';
import BrandBackground from '~components/brandBackground';
import { WebAppLinks } from '~components/webAppLinks';

export const Login = ({ navigation }) => {
	const app = useContext(AppContext);
	const theme = useTheme();
	const settingsData = useLocalData('settingsData', {});
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loginFail, setLoginFail] = useState(false);
	const ref_Password = useRef();

	const CallCardTitle = 'Don\'t have a login?';
	const CallCardBody =
		'Tell us what happened and we\'ll evaluate your case for free.';

	const callOptions = {
		number: settingsData?.FirmContactPhoneNumber,
		prompt: true,
		skipCanOpen: true
	};

	const styles = StyleSheet.create({
		contentView: {
			flex: 1,
			justifyContent: 'flex-start',
			padding: 25
		},
		card: {
			padding: 5,
			borderRadius: 12,
			backgroundColor: theme.colors.tabBackground,
			marginTop: 25
		},
		cardIcon: {
			marginLeft: 15,
			marginTop: 15
		},
		cardSubtitle: {
			fontSize: 16,
			letterSpacing: -0.35
		},
		textInput: {
			marginTop: 10
		},
		login: {
			backgroundColor: theme.colors.background,
			padding: 15,
			borderRadius: 8
		},
		header: { marginBottom: 15 },
		title: { fontSize: 24 },
		caption: { fontSize: 18 },
		inputView: {
			marginBottom: 15
		},
		submitButton: {
			marginTop: 15
		},
		submitButtonText: {
			fontSize: 18,
			padding: 5
		},
		helperText: {
			marginLeft: 'auto',
			marginRight: 'auto',
			fontSize: 20
		},
		contentViewWebContainer: {
			maxWidth: '400px',
			margin: 'auto',
			marginTop: '40px'
		},
		titleWebContainer: {
			display: 'flex',
			justifyContent: 'center',
			fontSize: 28
		},
		submitButtonWeb: {
			fontSize: 20,
			padding: 5
		},
		cardSubtitleWeb: {
			fontSize: 16,
			letterSpacing: -0.35,
			marginBottom: '20px'
		},
		weblinks: {
			flex: 1,
			alignItems: 'center'
		}
	});

	const handleSubmitPress = async () => {
		const response = await V1Request(
			'get',
			'Login',
			null,
			`?userName=${email}&password=${password}`,
			'json',
			app.setLoading
		);

		if (response.status === 0) {
			Keyboard.dismiss();
			setLoginFail(false); // This only true when server responds with 403
			app.setNotificationTitle('Oops, something went wrong.');
			app.setNotificationMsg('Please try again later');
			app.setNotificationVisible(true);
		} else if (response.status === 403) {
			setLoginFail(true);
		}

		if (response.data?.success) {
			setLoginFail(false);
			response.data.email = email;
			response.data.loggedIn = true;

			app.setAuth(response.data);
			SetAuth(response.data);

			if (Platform.OS !== 'web') { useExpoPushTokenRefresh(app.expoPushToken); }

			Platform.OS === 'web' ? navigation.push('Cases') : navigation.push('CasesDrawerNavigator');
		}
	};

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<BrandBackground>
				<ScrollView>
					<WebContainer size={'sm'}>
						<View style={styles.contentViewWebContainer}>
							<View style={styles.contentView}>
								<View style={styles.header}>
									<Title style={styles.titleWebContainer}>
										Log in to view your case
									</Title>
								</View>

								<View style={styles.inputView}>
									<TextInput
										autoCapitalize="none"
										mode="outlined"
										label="Email Address"
										value={email}
										onChangeText={(email) => setEmail(email)}
										style={styles.textInput}
										onSubmitEditing={() => ref_Password.current.focus()}
										blurOnSubmit={false}
									/>

									<TextInput
										secureTextEntry
										mode="outlined"
										label="Password"
										value={password}
										onChangeText={(password) => setPassword(password)}
										style={styles.textInput}
										onSubmitEditing={(e) => handleSubmitPress(e)}
										ref={ref_Password}
									/>
								</View>

								<Button
									mode="contained"
									onPress={(e) => handleSubmitPress(e)}
									style={styles.submitButton}
									labelStyle={styles.submitButtonText}
									uppercase={false}
								>
									Log In
								</Button>

								<HelperText
									type="error"
									visible={loginFail}
									style={styles.helperText}
								>
									Invalid Email Address and/or Password. Please try again.
								</HelperText>

								<Button
									mode="text"
									compact={true}
									onPress={() => {
										navigation.navigate('PasswordResetEmail');
									}}
									style={styles.submitButton}
									labelStyle={styles.submitButtonWeb}
									uppercase={false}
								>
									Forgot Password?
								</Button>

								<TouchableOpacity>
									<Card
										style={styles.card}
										onPress={() => call(callOptions).catch(console.error)}
									>
										<CallSvg
											fill={theme.colors.text}
											highlight={theme.colors.primary}
											width={35}
											height={35}
											style={styles.cardIcon}
										/>
										<Card.Title
											title={settingsData?.FirmContactPhoneNumber ?? ''}
											subtitle={CallCardBody}
											subtitleNumberOfLines={2}
											subtitleStyle={styles.cardSubtitleWeb}
										/>
									</Card>
								</TouchableOpacity>
								<View style={styles.weblinks} >
									<WebAppLinks firebaseAndroidApn={settingsData.Firebase_Android_Apn} firebaseAppleIsi={settingsData.Firebase_Apple_Isi} />
								</View>
							</View>
						</View>
					</WebContainer>
				</ScrollView>
			</BrandBackground>
		);
	}

	function MobileView() {
		return (
			<BrandBackground>
				<ScrollView keyboardShouldPersistTaps={'handled'}>
					<View style={styles.contentView}>
						<View style={styles.login}>
							<View style={styles.header}>
								<Title style={styles.title}>Log in</Title>
								<Caption style={styles.caption}>to view your case</Caption>
							</View>

							<View style={styles.inputView}>
								<TextInput
									autoCapitalize="none"
									mode="outlined"
									label="Email Address"
									value={email}
									onChangeText={(email) => setEmail(email)}
									style={styles.textInput}
								/>

								<TextInput
									secureTextEntry
									mode="outlined"
									label="Password"
									value={password}
									onChangeText={(password) => setPassword(password)}
									style={styles.textInput}
								/>
							</View>

							<Button
								mode="contained"
								onPress={(e) => handleSubmitPress(e)}
								style={styles.submitButton}
								labelStyle={styles.submitButtonText}
								uppercase={false}
							>
							Submit
							</Button>
						</View>

						<HelperText
							type="error"
							visible={loginFail}
							style={styles.helperText}
						>
							Invalid Email Address and/or Password. Please try again.
						</HelperText>

						<Button
							mode="text"
							compact={true}
							onPress={() => {
								navigation.navigate('PasswordResetEmail');
							}}
							labelStyle={styles.submitButtonText}
							uppercase={false}
						>
							Forgot Password?
						</Button>

						<TouchableOpacity>
							<Card
								style={styles.card}
								onPress={() => call(callOptions).catch(console.error)}
							>
								<CallSvg
									fill={theme.colors.text}
									highlight={theme.colors.primary}
									width={35}
									height={35}
									style={styles.cardIcon}
								/>
								<Card.Title
									title={CallCardTitle}
									subtitle={CallCardBody}
									subtitleNumberOfLines={2}
									subtitleStyle={styles.cardSubtitle}
								/>
							</Card>
						</TouchableOpacity>
					</View>
				</ScrollView>
			</BrandBackground>
		);
	}
};
