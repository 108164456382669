import * as Font from 'expo-font';

const useFonts = async () => {
	await Font.loadAsync({
		'proxima-nova-bold': require('@@fonts/Mark_Simonson_Proxima_Nova_Bold.otf'),
		'proxima-nova-light': require('@@fonts/Mark_Simonson_Proxima_Nova_Light.otf'),
		'proxima-nova-regular': require('@@fonts/Mark_Simonson_Proxima_Nova_Regular.otf'),
		'proxima-nova-semibold': require('@@/fonts/Mark_Simonson_Proxima_Nova_Semibold.otf'),
	});
};

export default useFonts;
