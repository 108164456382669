export default `<html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8">

<meta name="Generator" content="Microsoft Word 15 (filtered)">
<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0">
<style>
<!--
 /* Font Definitions */
@font-face
	{font-family:Arial;
	panose-1:2 11 6 4 2 2 2 2 2 4;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"Segoe UI";}
 /* Style Definitions */
p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:10.0pt;
	margin-left:0in;
	line-height:115%;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
h1
	{margin-right:0in;
	margin-left:0in;
	font-size:24.0pt;
	font-family:"Times New Roman",serif;
	font-weight:bold;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:10.0pt;
	margin-left:0in;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{margin:0in;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{margin:0in;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoBodyTextIndent2, li.MsoBodyTextIndent2, div.MsoBodyTextIndent2
	{margin:0in;
	margin-bottom:.0001pt;
	text-align:center;
	text-indent:.5in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:purple;
	text-decoration:underline;}
p
	{margin-right:0in;
	margin-left:0in;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
	{margin-top:0in;
	margin-right:0in;
	margin-bottom:10.0pt;
	margin-left:0in;
	font-size:10.0pt;
	font-family:"Times New Roman",serif;
	font-weight:bold;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{margin:0in;
	margin-bottom:.0001pt;
	font-size:9.0pt;
	font-family:"Segoe UI",sans-serif;}
span.Heading1Char
	{font-family:"Times New Roman",serif;
	font-weight:bold;}
span.BalloonTextChar
	{font-family:"Segoe UI",sans-serif;}
span.CommentSubjectChar
	{font-weight:bold;}
span.BodyTextIndent2Char
	{font-family:"Times New Roman",serif;}
.MsoChpDefault
	{font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:10.0pt;
	line-height:115%;}
 /* Page Definitions */
@page WordSection1
	{size:8.5in 11.0in;
	margin:1.0in 1.0in 1.0in 1.0in;}
div.WordSection1
	{page:WordSection1;}
-->
</style>

</head>

<body lang="EN-US" link="blue" vlink="purple">

<div class="WordSection1">

<p class="MsoNormal" align="center" style="margin-top: 100; text-align:center;line-height:normal"><b>END
USER LICENSE AGREEMENT</b></p>

<p class="MsoNormal" align="center" style="text-align:center;line-height:normal"><b>Version
May 2019</b></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal">PLEASE READ THIS END USER LICENSE AGREEMENT ("LICENSE")
CAREFULLY BEFORE CLICKING THE "ACCEPT" BUTTON OR DOWNLOADING OR USING
THE <b>Plaintiff Discovery App</b> ("APPLICATION") ACCOMPANYING THIS
LICENSE. BY CLICKING THE "ACCEPT" BUTTON OR DOWNLOADING OR USING THE
APPLICATION, YOU ARE ENTERING INTO AND AGREEING TO BE BOUND BY THE TERMS OF
THIS LICENSE. IF YOU DO NOT AGREE TO THE TERMS OF THIS LICENSE, DO NOT CLICK
THE "ACCEPT" BUTTON OR DOWNLOAD OR USE THE APPLICATION. </p>

<p style="text-align:justify;text-justify:inter-ideograph"><b><span style="color:black;text-transform:uppercase">PLEASE READ THIS LICENSE CAREFULLY
AS IT CONTAINS IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND
OBLIGATIONS, INCLUDING VARIOUS LIMITATIONS AND EXCLUSIONS, AND A DISPUTE
RESOLUTION CLAUSE THAT GOVERNS HOW DISPUTES WILL BE RESOLVED. </span></b></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>1. General.</b> The Application is licensed, not sold,
to You by Vrsus Assets, LLC ("Vrsus") for use strictly in accordance
with the terms and conditions of this License, and any usage rules established
by any other third party usage rules or terms of use, such as Apple, Inc. and
such other vendors ("Usage Rules"), which are incorporated herein by
this reference. The term "Application" shall refer to and consist of
the following: (i) the software application accompanying this License,
including, without limitation, any software code, scripts, interfaces,
graphics, displays, text, documentation and other components; (ii) any updates,
modifications or enhancements to the items listed in subsection (i); and (iii) any
specific website the Application directs you to via any browser accessed via an
Apple IOS or other Apple-authorized mobile device ("Mobile Device"). &nbsp;The
License is concluded between You and Vrsus only, and not with Apple, and Vrsus,
not Apple, is solely responsible for the Application and the content thereof.</p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>2. License Grant; Restrictions on Use; Other. </b></p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>2.1 License Grant.</b> Vrsus grants You a revocable,
non-exclusive, non-transferable, limited right to install and use the
Application on a single Mobile Device owned and controlled by You, and to
access and use the Application on such Mobile Device strictly in accordance
with the terms and conditions of this License, the Usage Rules and any service
agreement associated with your Mobile Device (collectively "Related
Agreements"). &nbsp;&nbsp;You may use the Application solely in connection with
authorized Vrsus servers. You may only use the Application on any Apple IOS or
other Apple-authorized Mobile Device that you own or control and as permitted
by the Usage Rules set forth in the Apple Store Terms of Service. You may
install, run, execute all or part of any third party software only as a
component of the Application and only for use with devices produced,
manufactured, distributed, or sold by the applicable third party.</p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>2.2 Restrictions on Use.</b> You shall use the
Application strictly in accordance with the terms of the Related Agreements and
shall not: (a) decompile, reverse engineer, disassemble, attempt to derive the
source code of, or decrypt the Application or any third party components
contained therein; (b) make any modification, adaptation, improvement,
enhancement, translation or derivative work from the Application; (c) violate
any applicable laws, rules or regulations in connection with Your access or use
of the Application; (d) remove, alter or obscure any proprietary notice
(including any notice of copyright or trademark) of Vrsus or its affiliates,
partners, suppliers or the licensors of the Application; (e) use the
Application for any revenue generating endeavor, commercial enterprise, or
other purpose for which it is not designed or intended; (f) distribute the
Application to multiple Mobile Devices; (g) make the Application available over
a network or other environment permitting access or use by multiple Mobile
Devices or users at the same time; (h) use the Application for creating a
product, service or software that is, directly or indirectly, competitive with
or in any way a substitute for any services, product or software offered by Vrsus
or its affiliates; (i) use the Application to send automated queries to any
website or to send any unsolicited commercial e-mail; or (j) use any
proprietary information or interfaces of Vrsus or its affiliates or other
intellectual property of Vrsus or its affiliates in the design, development,
manufacture, licensing or distribution of any applications, accessories or
devices for use with the Application. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>2.3&nbsp; Maintenance</b>.&nbsp; You acknowledge that Apple has no
obligation whatsoever to furnish any maintenance and support services with
respect to the Application.</p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>2.4&nbsp; Product Claims.</b>&nbsp; Vrsus, not Apple, is
responsible for addressing any claims by You or any third party relating to the
Application or your possession and/or use of the Application, including, but
not limited to: (i) product liability claims; (ii) any claim that the
Application fails to conform to any applicable legal or regulatory requirement;
and (iii) claims arising under consumer protection or similar legislation.</p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>3. Intellectual Property Rights.</b> </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>3.1 Rights to Application.</b> You acknowledge and agree
that the Application and all copyrights, patents, trademarks, trade secrets and
other intellectual property rights associated therewith are, and shall remain,
the property of Vrsus or its suppliers (to the extent third party products are
contained therein). Furthermore, You acknowledge and agree that the source and
object code of the Application and the format, directories, queries,
algorithms, structure and organization of the Application are the intellectual
property and proprietary and confidential information of Vrsus and its
affiliates, licensors and suppliers. Except as expressly stated in this
License, You are not granted any intellectual property rights in or to the
Application by implication, estoppel or other legal theory, and all rights in
and to the Application not expressly granted in this License are hereby
reserved and retained by Vrsus. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>3.2 Third Party Software.</b> The Application may
utilize or include third party software that is subject to open source and
third party license terms ("Third Party Software"). You acknowledge
and agree that Your right to use such Third Party Software as part of the Application
is subject to and governed by the terms and conditions of the open source or
third party license applicable to such Third Party Software, including, without
limitation, any applicable acknowledgements, license terms and disclaimers
contained therein. In the event of a conflict between the terms of this License
and the terms of such open source or third party licenses, the terms of the
open source or third party licenses shall control with regard to Your use of
the relevant Third Party Software. In no event, shall the Application or components
thereof be deemed to be "open source" or "publicly available"
software. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>3.3 Vrsus's Marks.</b> You are not authorized to use the
trademarks of Vrsus or its affiliates in any advertising, publicity or in any
other commercial manner without the prior written consent of Vrsus, which may
be withheld for any or no reason. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>3.4 Infringement Acknowledgement.</b> You and Vrsus acknowledge
and agree that, in the event of a third party claim that the Application or
Your possession or use of the Application infringes any third party's
intellectual property rights, You will promptly notify Vrsus in writing of such
a claim. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>4. Restriction on Transfer.</b> You may not rent, lease,
lend, sublicense or transfer the Application, this License or any of the rights
granted hereunder. Any attempted transfer in contravention of this provision
shall be null and void and of no force or effect. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>5. Use of Information.</b> </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>5.1 Consent to Use Information.</b> You hereby authorize
and consent to the collection, storage and use, by Vrsus and its affiliates,
partners and agents, of any information or data that You provide to the
Application and any information and data related to or derived from Your use of
the Application ("Information"). </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>6. Third Party Content and Services.</b> </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>6.1 General.</b> You acknowledge that the Application may
permit access to products, services, web-sites, advertisements, promotions,
recommendations, advice, information, and materials created and provided by
advertisers, publishers, content partners, marketing agents, vendors and other
third parties ("Third Party Content and Services"). </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>6.2 Disclaimer.</b> You acknowledge that Vrsus does not
investigate, monitor, represent or endorse the Third Party Content and Services
(including any third party websites available through the Application).
Furthermore, Your access to and use of the Third Party Content and Services is
at Your sole discretion and risk, and Vrsus and its affiliates, partners,
suppliers and licensors shall have no liability to You arising out of or in
connection with Your access to and use of the Third Party Content and Services.
Vrsus and its affiliates hereby disclaim any representation, warranty or
guaranty regarding the Third Party Content and Services, whether express,
implied or statutory, including, without limitation, the implied warranties of
merchantability or fitness for a particular purpose, and any representation,
warranty or guaranty regarding the availability, quality, reliability,
features, appropriates, accuracy, completeness, or legality of the Third Party
Content and Services. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>6.3 Third Party Terms of Service.</b> You acknowledge
and agree that Your access to and use of the Third Party Content and Services
and any correspondence or business dealings between You and any third party
located using the Application are governed by and require Your acceptance of
the terms of service of such third party, including, without limitation, any
terms, privacy policies, conditions, representations, warranties or disclaimers
contained therein. Furthermore, You acknowledge and agree that the Third Party
Content and Services and any related third party terms of service are subject
to change by the applicable third party at its sole discretion and without any
notice. You assume all risks arising out of or resulting from your transaction
of business over the Internet and with any third party, and you agree that Vrsus
and its affiliates, partners, suppliers and licensors are not responsible or
liable for any loss or result of the presence of information about or links to
such advertisers or service providers. Furthermore, You acknowledge and agree
that You are not being granted a license to (i) the Third Party Content and
Services; (ii) any products, services, processes or technology described in or
offered by the Third Party Content and Services; or (iii) any copyright,
trademark, patent or other intellectual property right in the Third Party
Content or Services or any products, services, processes or technology
described or offered therein. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>6.4 Endorsements.</b> You acknowledge and agree that the
provision of access to Third Party Content and Services shall not constitute or
imply any endorsement by Vrsus or its affiliates of such Third Party Content
and Services. Vrsus reserves the right to restrict or deny access to any Third
Party Content and Services otherwise accessible through the Application,
although Vrsus has no obligation to restrict or deny access even if requested
by You. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>6.5 Inappropriate Materials.</b> You understand that by
accessing and using the Third Party Content and Services, You may encounter
information, materials and subject matter (i) that You or others may deem
offensive, indecent, or objectionable; (ii) which may or may not be identified
as having explicit language, and (iii) that automatically and unintentionally
appears in search results, as a link or reference to objectionable material.
Notwithstanding the foregoing, You agree to use the Third Party Content and
Services at Your sole risk and that Vrsus and its affiliates, partners,
suppliers and licensors shall have no liability to You for information,
material or subject matter that is found to be offensive, indecent, or
objectionable. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>6.6 Use of Third Party Content and Services.</b> You
agree that the Third Party Content and Services contain proprietary information
and material that is owned by Vrsus and its affiliates, partners, suppliers and
licensors and is protected by applicable intellectual property and other laws,
including, without limitation, pursuant to copyright, and that You will not use
such proprietary information or materials in any way whatsoever except for
permitted use of the Third Party Content and Services. No portion of the Third
Party Content and Services may be reproduced in any form or by any means. You
agree not to modify, rent, lease, loan, sell, distribute, or create derivative
works based on the Third Party Content and Services, in any manner, and You
shall not exploit the Third Party Content and Services in any unauthorized way
whatsoever, including, without limitation, by trespass or burdening network
capacity. You agree that You will not use any Third Party Content and Services
in a manner that would infringe or violate the rights of any other party, and
that Vrsus is not in any way responsible for any such use by You. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>7. Term and Termination.</b> </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>7.1 Term.</b> This License shall be effective until
terminated. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>7.2 Termination.</b> Vrsus may, in its sole and absolute
discretion, at any time and for any or no reason, suspend or terminate this
License and the rights afforded to You hereunder with or without prior notice.
Furthermore, if You fail to comply with any terms and conditions of this
License, then this License and any rights afforded to You hereunder shall
terminate automatically, without any notice or other action by Vrsus. Upon the
termination of this License, You shall (i) cease all use of the Application; (ii)
immediately uninstall the Application from the Mobile Device on which it
resides; and (iii) immediately delete all data associated with the Application
from the Mobile Device on which it resides. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>8. Disclaimer of Warranties.</b> YOU UNDERSTAND AND
EXPRESSLY AGREE THAT USE OF THE APPLICATION IS AT YOUR SOLE RISK.&nbsp; THE APPLICATION,
AND ACCESS TO ANY THIRD PARTY CONTENT AND SERVICES, IS PROVIDED TO YOU "AS
IS" AND "AS AVAILABLE," WITHOUT WARRANTY OF ANY KIND, EITHER
EXPRESS OR IMPLIED.&nbsp; VRSUS DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR
USE OF THE APPLICATION WILL BE UNINTERRUPTED, ERROR FREE, OR VIRUS FREE, OR
THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS. IN ADDITION, NO WARRANTY IS
MADE AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE APPLICATION, OR AS
TO THE QUALITY, RELIABILITY, OR CONTENT OF ANY PRODUCT, SERVICE, OR INFORMATION
PROVIDED THROUGH OR IN CONNECTION WITH THE APPLICATION.&nbsp; TO THE EXTENT
PERMITTED BY APPLICABLE LAW, VRSUS DISCLAIMS ANY AND ALL WARRANTIES, INCLUDING,
BUT NOT LIMITED TO: (i) ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY,
USEFULNESS, OR CONTENT OF INFORMATION, PRODUCTS OR SERVICES; AND (ii) ANY
WARRANTIES OF TITLE, OF NON-INFRINGEMENT, OF MERCHANTABILITY, OF FITNESS FOR A
PARTICULAR PURPOSE, OR WARRANTIES ARISING FROM A COURSE OF DEALING, TRADE
USAGE, OR TRADE PRACTICE. THIS DISCLAIMER APPLIES TO ANY DAMAGES OR INJURY
CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION,
DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE
FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE
OF RECORDS OR INFORMATION, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR,
NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION.&nbsp; ALTHOUGH VRSUS USES REASONABLE
SECURITY AND ENCRYPTION PROVISIONS, WE DO NOT GUARANTEE AND MAKE NO
REPRESENTATIONS OR WARRANTIES CONCERNING OUR ABILITY TO PREVENT UNAUTHORIZED
ACCESS OF OR TAMPERING WITH THE APPLICATION OR INFORMATION ACCESSIBLE THROUGH
THE APPLICATION. YOU ACKNOWLEDGE THAT INFORMATION TRANSMITTED THROUGH THE
INTERNET OR ACCESSIBLE THROUGH THE INTERNET IS NEVER COMPLETELY SECURE.</p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>9. Limitation of Liability.</b> NEITHER VRSUS NOR ANY OF
ITS DIRECTORS, MANAGERS, EMPLOYEES, AFFILIATES, AGENTS, REPRESENTATIVES, CUSTOMERS,
SUPPLIERS, OR LICENSORS (COLLECTIVELY, "VRSUS ASSOCIATES") WILL HAVE
ANY LIABILITY TO YOU OR ANY THIRD PARTY FOR ANY COMPENSATORY, DIRECT, SPECIAL,
INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ATTORNEYS' FEES,
REGARDLESS OF THE SOURCE OF THE CLAIM AND EVEN IF VRSUS OR THE VRSUS ASSOCIATE
HAS BEEN INFORMED OR IS AWARE OF THE POSSIBILITY OF SUCH DAMAGES.&nbsp; BECAUSE SOME
STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
FOR CONSEQUENTIAL, INCIDENTAL OR SPECIAL DAMAGES, IN SUCH STATES AND
JURISDICTIONS LIABILITY IS LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.&nbsp; IN
NO EVENT SHALL VRSUS'S AGGREGATE LIABILITY UNDER THESE TERMS OF USE EXCEED ONE HUNDRED
DOLLARS ($100.00). YOU SPECIFICALLY ACKNOWLEDGE THAT NEITHER VRSUS NOR ANY VRSUS
ASSOCIATE IS LIABLE FOR THE DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER
USERS OR THIRD PARTIES AND THAT THE RISK OF INJURY FROM THE FOREGOING RESTS
ENTIRELY WITH YOU.&nbsp; CESSATION OF YOUR USE OF THE APPLICATION IS YOUR SOLE RIGHT
AND REMEDY WITH RESPECT TO ANY DISPUTE WITH VRSUS. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>10. Indemnification.</b> You shall indemnify, defend and
hold harmless Vrsus, its affiliates, partners, suppliers and licensors, and
each of their respective officers, directors, agents and employees (the "Indemnified
Parties") from and against any claim, proceeding, loss, damage, fine,
penalty, interest and expense (including, without limitation, fees for
attorneys and other professional advisors) arising out of or in connection with
the following: (i) Your access to or use of the Application or Third Party
Content and Services; (ii) Your breach of this License; (iii) Your violation of
law; (iv) Your negligence or willful misconduct; or (v) Your violation of the
rights of a third party, including the infringement by You of any intellectual
property or misappropriation of any proprietary right or trade secret of any
person or entity. These obligations will survive any expiration or termination
of the License. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>11. Compatibility.</b> Vrsus does not warrant that the
Application will be compatible or interoperable with Your Mobile Device or any
other piece of hardware, software, equipment or device installed on or used in
connection with Your Mobile Device. Furthermore, You acknowledge that
compatibility and interoperability problems can cause the performance of Your Mobile
Device to diminish or fail completely, and may result in permanent the damage
to Your Mobile Device, loss of the data located on Your Mobile Device, and
corruption of the software and files located on Your Mobile Device. You
acknowledge and agree that Vrsus and its affiliates, partners, suppliers and
licensors shall have no liability to You for any losses suffered resulting from
or arising in connection with compatibility or interoperability problems. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>12. Product Claims.</b> You acknowledge that You (not Vrsus)
are responsible for addressing any third party claims relating to Your use or
possession of the Application, and agree to notify Vrsus of any third party
claims relating to the Application of which You become aware. Furthermore, You
hereby release Vrsus and its affiliates from any liability resulting from Your
use or possession of the Application, including, without limitation, the
following: (i) any product liability claims; (ii) any claim that the
Application fails to conform to any applicable legal or regulatory requirement;
and (iii) any claim arising under consumer protection or similar legislation. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>13. Miscellaneous.</b> </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>13.1 Governing Law.</b> This License shall be governed
and construed in accordance with the laws of the State of Indiana, without
regard to its conflict of laws provisions.&nbsp; All disputes shall be submitted to
arbitration in accordance with the rules of the American Arbitration
Association.&nbsp; The parties shall appoint a mutually agreeable
arbitrator.&nbsp; In the event the parties are unable to agree to a single
arbitrator, the dispute shall be submitted to a panel of three (3)
arbitrators.&nbsp; Each party shall appoint an arbitrator and the two
arbitrators so appointed shall then select a third arbitrator.&nbsp; The
arbitrators shall apply applicable federal laws and regulations and the laws of
the state of Indiana, and shall not use equitable or other principles which
would permit the arbitrator to ignore the law.&nbsp; The sole and exclusive location
for any arbitration shall be in Indianapolis, Indiana.&nbsp; The decision of the
arbitrators shall be binding and conclusive on all parties involved, and
judgment upon their decision may be entered in a court of competent
jurisdiction.&nbsp; The prevailing party in any such arbitration shall be
entitled to collect from the non-prevailing party all costs of the arbitration,
including reasonable attorneys’ fees.&nbsp; Arbitration shall proceed solely on
an individual basis without the right for any claims to be arbitrated on a
class action basis, or on a basis involving claims brought in a purported
representative capacity on behalf of others.&nbsp; Nothing in this Section shall
limit the right of any party to obtain from a court provisional or ancillary
remedies such as, but not limited to, injunctive relief, before, during or
after the pendency of any arbitration, or in lieu of such proceedings.&nbsp; The
parties agree that any dispute arising under or related to this License that is
excluded from arbitration for any reason shall be subject to the exclusive
jurisdiction of the appropriate state or federal court in Indianapolis, Indiana
and each party consents to the jurisdiction of such court in any such action or
proceeding and waives any objection to such venue. TO THE FULLEST EXTENT
PERMITTED BY LAW, AND AS SEPARATELY BARGAINED-FOR CONSIDERATION, EACH PARTY
HEREBY WAIVES ANY RIGHT TO TRIAL BY JURY IN ANY ACTION, SUIT, PROCEEDING, OR
COUNTERCLAIM OF ANY KIND ARISING OUT OF OR RELATING TO THIS LICENSE.&nbsp; This
License shall not be governed by the United Nations Convention on Contracts for
the International Sale of Goods, the application of which is expressly
excluded.</p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>13.2 Severability.</b> If any provision of this License
is held to be invalid or unenforceable with respect to a party, the remainder
of this License, or the application of such provision to persons other than
those to whom it is held invalid or unenforceable shall not be affected and
each remaining provision of this License shall be valid and enforceable to the
fullest extent permitted by law. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>13.3 Waiver.</b> Except as provided herein, the failure
to exercise a right or require performance of an obligation under this License
shall not effect a party's ability to exercise such right or require such
performance at any time thereafter nor shall the waiver of a breach constitute
waiver of any subsequent breach. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>13.4 Export Control.</b> You may not use or otherwise
export or re-export the Application except as authorized by United States law
and the laws of the jurisdiction(s) in which the Application was obtained. You
represent and warrant that You are not (x) located in any country that is
subject to a U.S. Government embargo, or that has been designated by the U.S.
Government as a "terrorist supporting" country, or (y) listed on any
U.S. Government list of prohibited or restricted parties including the Treasury
Department's list of Specially Designated Nationals or the U.S. Department of
Commerce Denied Person's List or Entity List. You also agree that You will not
use the Application for any purposes prohibited by applicable law. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>13.5 Modification or Amendment.</b> Vrsus may modify or
amend the terms of this License by including such modifications or amendments
at the log-in screen of the Application. You will be deemed to have agreed to
any such modification or amendment by Your accepting the new terms and continuing
to use the Application following the date. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>13.6 Survival.</b> The following sections of this
License and any other provisions of this License which by their express
language or by their context are intended to survive the termination of this
License shall survive such termination: 1, 2.2, 3, 4, 5, 7, 8, 9, 10, 12 and
13. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>13.7 Assignment.</b> Except as permitted in Section 4,
You shall not assign this License or any rights or obligations herein without
the prior written consent of Vrsus and any attempted assignment in
contravention of this provision shall be null and void and of no force or
effect. This License shall be binding upon and inure to the benefit of the
parties and their respective successors and permitted assigns.</p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>13.8 Entire Agreement.</b> This License including the
documents incorporated herein by reference constitute the entire agreement with
respect to the use of the Application licensed hereunder and supersedes all
prior or contemporaneous understandings regarding such subject matter.</p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>13.9 Notices.</b>&nbsp; Any notices required or permitted
hereunder shall be deemed to have been given if in writing and delivered
personally, sent by overnight delivery or by first class, registered, or
certified mail, postage prepaid and addressed to the receiving party at its
address set forth above (or to such other address as may be notified from one
party to the other following the same notice procedures).&nbsp; Licensee shall send
a copy of all such notices to the following: Vrsus Assets, LLC, 8888 Keystone
Crossing, Suite 1610, Indianapolis, Indiana 46240.</p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>13.10. Third Party Beneficiary.</b>&nbsp; You and Vrsus each
acknowledge and agree that Apple, and Apple's subsidiaries, are third party
beneficiaries of this License, and, upon your acceptance of the terms and
conditions of this License, Apple will have the right (and will be deemed to
have accepted the right) to enforce the License against You as a third party
beneficiary thereof.</p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>14.&nbsp; Developer Information.</b>&nbsp; The developer of the
Application is Vrsus Assets, LLC. Questions, complaints or claims with respect
to the Application should be directed to: support@casepacer.com. </p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph;
line-height:normal"><b>15.&nbsp; Copyright Notice.</b>&nbsp; The Application is Copyright
© Vrsus Assets, LLC 2019.&nbsp; All rights reserved.</p>

<p class="MsoNormal" style="text-align:justify;text-justify:inter-ideograph">&nbsp;</p>

</div>

</body></html>`;