import React, { useContext, useState } from 'react';
import { Image, Platform, StyleSheet, TouchableOpacity } from 'react-native';
import {
	TextInput,
	Button,
	Dialog,
	List,
	Paragraph,
	Portal,
	Text,
	useTheme
} from 'react-native-paper';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import { AppContext } from '~context/appContext';
import { V1Request } from '~utils/apiRequest';
import update from 'immutability-helper';
import { AttachmentIOS } from './attachmentIOS';
import { AttachmentAndroid } from './attachmentAndroid';
import { AttachmentIcon } from './attachmentIcon';
import { Validation } from '~components/formInputs/validation';

export const parseFileInfo = (fa, tfq) => ({
	uploaded: true,
	recordFileId: fa.RecordFileId,
	questionId: tfq?.Id,
	type: fa.IsImage ? 'image' : 'file',
	description: fa.IsImage ? 'Image' : 'File',
	name: fa.FileName,
	uri: fa.Url,
	HelperData: tfq?.HelperData,
});

export const Attachment = ({ form, question, files, setFiles }) => {
	const theme = useTheme();
	const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
	const [fileDelete, setFileDelete] = useState({});

	const app = useContext(AppContext);

	const showConfirmDialog = (questionId, index) => {
		setFileDelete({ questionId, index });
		setConfirmDeleteVisible(true);
	};

	const hideConfirmDialog = () => setConfirmDeleteVisible(false);

	const confirmPicker = async (value) => {
		if (value === 'camera') handleCamera();
		else if (value === 'photo') handlePhoto(); 
		else if (value === 'file') handleFile();
	};

	const handleCamera = async () => {
		let permissions = await ImagePicker.getCameraPermissionsAsync();
		if (!permissions.granted) await ImagePicker.requestCameraPermissionsAsync();

		const result = await ImagePicker.launchCameraAsync({
			allowsEditing: false,
			aspect: [4, 3],
			quality: 0.75
		});
		
		if (result.canceled) return;
		
		result.mimeType = `image/${result.assets[0].uri.split('.').pop()}`;
		result.name = result.assets[0].uri.split('/').pop();		
		result.recordFileId = null;
		result.description = 'Camera';
		result.uploaded = false;
		result.questionId = question.Id;
		result.HelperData = question.HelperData;
		setFiles(update(files, { $push: [result] }));		
	};

	const handlePhoto = async () => {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: false,
			aspect: [4, 3],
			quality: 0.75
		});
		
		if (result.canceled) return;
		
		result.mimeType = `image/${result.assets[0].uri.split('.').pop()}`;
		result.name = result.assets[0].uri.split('/').pop();
		result.recordFileId = null;
		result.description = 'Photo';
		result.uploaded = false;
		result.questionId = question.Id;		
		result.HelperData = question.HelperData;
		setFiles(update(files, { $push: [result] }));		
	};

	const handleFile = async () => {
		const result = await DocumentPicker.getDocumentAsync();
		
		if (result.canceled) return;
		
		result.mimeType = result.assets[0].mimeType;
		result.name = result.assets[0].name;
		result.uploaded = false;
		result.recordFileId = null;
		result.questionId = question.Id;
		result.description = 'File';
		result.HelperData = question.HelperData;
		setFiles(update(files, { $push: [result] }));
	};

	const deleteFile = async (fileDelete) => {
		// If file has been uploaded.  First delete file server side...
		const file = files.filter((f) => f.questionId === fileDelete.questionId)[fileDelete.index];
		if (file.uploaded) {
			app.setLoadingMessage('Deleting File...');
			hideConfirmDialog();
			const response = await V1Request(
				'post',
				'DeleteFile',
				null,
				{
					lawsuitId: form.LawsuitId,
					recordFileId: file.recordFileId,
					formId: form.ClientFormId,
					screenId: question.ClientFormScreenId,
					questionId: file.questionId
				},
				'json',
				app.setLoading
			);
			app.setLoadingMessage('');
			if (response.status === 200) {
				setFiles(files.filter((f) => f.uri !== file.uri));
			}
		} else {
			setFiles(files.filter((f) => f.uri !== file.uri));
			hideConfirmDialog();
		}
	};

	return (
		<>
			<Text>{question.Question}</Text>
			{Platform.OS === 'android' ? (
				<AttachmentAndroid confirmPicker={confirmPicker} />
			) : Platform.OS === 'web' ? (
				<TextInput
					right={
						<TextInput.Icon icon="tray-arrow-up" color={theme.colors.primary}
							onPress={() => confirmPicker('file')} />
					}
					mode="outlined"
					showSoftInputOnFocus={false}
					placeholder="File Upload"
					onClick={() => confirmPicker('file')}
				/>
			) : (
				<AttachmentIOS confirmPicker={confirmPicker} />
			)}
			<FilesList
				files={files?.filter((x) => x.questionId === question.Id)}
				showConfirmDialog={showConfirmDialog} />
			<Validation question={question} />
			<Portal>
				<Dialog
					visible={confirmDeleteVisible}
					onDismiss={() => hideConfirmDialog()}
				>
					<Dialog.Title>Please Confirm</Dialog.Title>
					<Dialog.Content>
						<Paragraph>Are you sure you want to delete?</Paragraph>
					</Dialog.Content>
					<Dialog.Actions>
						<Button onPress={() => deleteFile(fileDelete)}>Yes</Button>
						<Button onPress={() => hideConfirmDialog()}>Cancel</Button>
					</Dialog.Actions>
				</Dialog>
			</Portal>
		</>
	);
};


export function FilesList({ files, showConfirmDialog }) {
	const theme = useTheme();
	return (<>
		{files?.map((f, index) => {
			const num = index + 1;
			return (
				<List.Item
					index
					key={index}
					title={'Attachment #' + num}
					description={f.description !== 'File' ? f.description : f.name}
					left={() =>
						f.type === 'image' ? (
							<Image source={{ uri: f.uri }} style={styles.listImageLeft} />
						) : (
							<List.Icon
								icon={AttachmentIcon(f.name)}
								style={styles.listIconLeft}
							/>
						)
					}
					right={() => showConfirmDialog && (
						<TouchableOpacity
							onPress={() => showConfirmDialog(f.questionId, index)}
						>
							<List.Icon icon="delete-forever" color={theme.colors.error} />
						</TouchableOpacity>
					)}
				/>
			);
		})}
	</>);
}

const styles = StyleSheet.create({
	listIconLeft: {
		marginRight: 0,
		paddingRight: 15
	},
	listImageLeft: {
		marginRight: 10,
		width: 40,
		height: 40,
		alignSelf: 'center'
	}
});