import React, { useState } from 'react';
import { Platform, View, Text, ScrollView, StyleSheet } from 'react-native';
import { Button, Divider, useTheme } from 'react-native-paper';
import { FlashList } from '@shopify/flash-list';

import { useLocalData } from '~utils/localStorage';
import BlogItem from '~screens/blog/partials/blogItem';
import moment from 'moment';
import BrandBackground from '~components/brandBackground';
import { WebContainer } from '~components/webContainer';
import useMQ from '~hooks/useMQ';

export const Blogs = () => {
	const mq = useMQ();
	const { colors } = useTheme();

	const [currentPage, setcurrentPage] = useState(1);
	const [itemsPerPage, setitemsPerPage] = useState(12);

	const [pageNumberLimit, setpageNumberLimit] = useState(5);
	const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
	const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

	const blogContainer_sm = {
		width: '90%',
		paddingLeft: '10%',
		margin: 0,
		marginTop: 0,
		marginBottom: 0,
		borderRadius: 0,
		gridTemplateColumns: '1fr'
	};

	const blogContainer_lg = {
		gridTemplateColumns: '1fr 1fr'
	};

	const styles = StyleSheet.create({
		contentView: {
			flex: 1,
			justifyContent: 'flex-start'
		},
		divider: {
			marginTop: 5,
			height: 3
		},
		mainView: {
			backgroundColor: colors.surface
		},
		headlineText: {
			fontFamily: 'proxima-nova-bold',
			fontSize: 26
		},
		blogContainer: {
			display: 'grid',
			gridTemplateColumns: '1fr 1fr 1fr',
			...(!mq.xl ? blogContainer_lg : null), // If at least lg
			// ...(!mq.lg ? blogContainer_md : null), // If at least md
			...(!mq.md ? blogContainer_sm : null) // If at least sm
		},
		buttonContainer: {
			justifyContent: 'flex-end',
			flexDirection: 'row',
			paddingBottom: 10,
			paddingRight: 20,
			marginTop: 20
		}
	});

	const blogsData = useLocalData('blogsData', [], (b) =>
		b.sort((a, b) => moment(b.Published) - moment(a.Published))
	);
	const handleClick = (number) => {
		setcurrentPage(Number(number.target.textContent));
	};

	const pages = [];
	for (let i = 1; i <= Math.ceil(blogsData.length / itemsPerPage); i++) {
		pages.push(i);
	}

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = blogsData.slice(indexOfFirstItem, indexOfLastItem);

	const renderPageNumbers = pages.map((number) => {
		if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
			return (
				<Button key={number} onPress={(event) => handleClick(event)}>
					<View>{number}</View>
				</Button>
			);
		} else {
			return null;
		}
	});

	const handleNextbtn = () => {
		setcurrentPage(currentPage + 1);

		if (currentPage + 1 > maxPageNumberLimit) {
			setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
			setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
		}
	};

	const handlePrevbtn = () => {
		setcurrentPage(currentPage - 1);

		if ((currentPage - 1) % pageNumberLimit == 0) {
			setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
			setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
		}
	};

	const renderData = (data) => {
		return (
			<View style={styles.blogContainer}>
				{data.map((blog) => {
					const { Id, Summary, Body, Title, Published } = blog;
					const Category = blog.CPDirectBlogCategory.Name;

					return (
						<BlogItem
							key={Id}
							id={Id}
							summary={Summary}
							body={Body}
							title={Title}
							category={Category}
							date={Published}
							imgUrl={blog.CPDirectBlogImages[0]?.PublicUrl}
						/>
					);
				})}
			</View>
		);
	};

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<BrandBackground>
				<ScrollView>
					<WebContainer>
						<Text style={styles.headlineText}>Blog</Text>
						<Divider bold style={styles.divider} />
						{renderData(currentItems)}
						<View style={styles.buttonContainer}>
							<Button
								mode="contained"
								onPress={handlePrevbtn}
								disabled={currentPage == pages[0] ? true : false}
							>
								Prev
							</Button>
							{renderPageNumbers}
							<Button
								mode="contained"
								onPress={handleNextbtn}
								disabled={currentPage == pages[pages.length - 1] ? true : false}
							>
								Next
							</Button>
						</View>
					</WebContainer>
				</ScrollView>
			</BrandBackground>
		);
	}

	function MobileView() {
		return (
			<View style={styles.contentView}>
				<FlashList
					data={blogsData}
					estimatedItemSize={325}
					renderItem={({ item }) => {
						const { Id, Summary, Body, Title, Published } = item;
						const Category = item.CPDirectBlogCategory.Name;
						return (
							<BlogItem
								id={Id}
								summary={Summary}
								body={Body}
								title={Title}
								category={Category}
								date={Published}
								imgUrl={item.CPDirectBlogImages[0]?.PublicUrl}
							/>
						);
					}}
					keyExtractor={(item) => item.Id.toString()}
				/>
			</View>
		);
	}
};
