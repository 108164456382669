import React from 'react';
import { Image, Platform, ScrollView, StyleSheet, View } from 'react-native';
import { Text, Title } from 'react-native-paper';

import { useLocalData } from '~utils/localStorage';
import CallNow from '~components/callNow';
import AttorneyList from '~screens/contact/partials/attorneyList';
import { AboutImage } from '~components/aboutImage';
import BrandBackground from '~components/brandBackground';
import { WebContainer } from '~components/webContainer';

export const About = () => {
	const settingsData = useLocalData('settingsData', {});
	const attorneysData = useLocalData('attorneysData', []);

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<BrandBackground>
				<ScrollView>
					<WebContainer>
						<Image style={styles.aboutImageWeb} source={AboutImage()} />

						<Text style={styles.headlineWeb}>
							{settingsData?.AboutFirmTitle}
						</Text>
						<Text style={styles.bodyWeb}>{settingsData?.AboutFirmBody}</Text>

						<View style={styles.attorneysWeb}>
							<Title style={styles.title}>Meet Our Team</Title>
							<View style={styles.attorneysImages}>
								<AttorneyList attorneys={attorneysData} />
							</View>
						</View>
					</WebContainer>
				</ScrollView>
			</BrandBackground>
		);
	}

	function MobileView() {
		return (
			<>
				<ScrollView>
					<Image style={styles.aboutImage} source={AboutImage()} />
					<View style={styles.bodyContainer}>
						<Text style={styles.headlineText}>
							{settingsData?.AboutFirmTitle}
						</Text>
						<Text style={styles.mainText}>{settingsData?.AboutFirmBody}</Text>
					</View>
					<View style={styles.attorneysView}>
						<Title style={styles.title}>Meet Our Team</Title>
						<AttorneyList attorneys={attorneysData} />
					</View>
				</ScrollView>
				<CallNow number={settingsData?.FirmContactPhoneNumber} />
			</>
		);
	}
};

const styles = StyleSheet.create({
	aboutImage: {
		width: '100%',
		height: 225,
		alignSelf: 'center',
		resizeMode: 'contain'
	},
	aboutImageWeb: {
		width: '100%',
		aspectRatio: 2.6,
		margin: 'auto',
		marginBottom: 30,
		borderRadius: 10

	},
	bodyContainer: {
		paddingLeft: 25,
		paddingRight: 25,
		marginBottom: 50
	},

	headlineWeb: {
		fontFamily: 'proxima-nova-bold',
		fontSize: 44,
		fontWeight: 'bold',
		marginRight: '3%',
		letterSpacing: 0,
	},
	mainText: {
		fontFamily: 'proxima-nova-light',
		fontWeight: '400',
		fontSize: 22,
		marginTop: 15,
		letterSpacing: -0.35,
		lineHeight: 22
	},

	headlineText: {
		fontFamily: 'proxima-nova-regular',
		fontSize: 26
	},
	bodyWeb: {
		fontFamily: 'proxima-nova-light',
		fontSize: '1.1rem',
		marginTop: 15,
		letterSpacing: -0.35,
		lineHeight: 26
	},
	attorneysView: {
		paddingLeft: 25,
		paddingRight: 25,
		marginBottom: 50
	},
	attorneysWeb: {
		width: '100%',
		margin: 'auto',
		marginTop: 30,
		marginBottom: 0
	},
	attorneysImages: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexWrap: 'wrap'
	},
	title: {
		fontSize: 26,
		fontWeight: 'bold',
		letterSpacing: -0.5,
		lineHeight: 32
	}
});
