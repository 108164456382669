import React, { useRef } from 'react';
import { Picker } from '@react-native-picker/picker';
import { TextInput, useTheme } from 'react-native-paper';
import { Keyboard } from 'react-native';

export const DropdownAndroid = ({ pickerItems, answer, handleValueChanged, placeholder, icon }) => {
	const theme = useTheme();
	const pickerRef = useRef();

	function open() {
		Keyboard.dismiss();
		pickerRef.current.focus();
	}

	return (
		<>
			<TextInput
				right={
					<TextInput.Icon icon={icon ?? 'chevron-down'} color={theme.colors.primary} />
				}
				mode="outlined"
				showSoftInputOnFocus={false}
				placeholder={placeholder}
				onFocus={() => open()}
				value={answer}
			/>
			<Picker
				ref={pickerRef}
				style={{ display: 'none' }}
				selectedValue={answer}
				onValueChange={(value) => handleValueChanged(value)}
			>
				{pickerItems}
			</Picker>
		</>
	);
};
