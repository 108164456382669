import React, { useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { MessageItem } from '~screens/case/messages/partials/messageItem';
import { V1Request } from '~utils/apiRequest';
import { useRoute } from '@react-navigation/native';

export const MessageList = ({ messages, setMessages, sendersAvatars }) => {
	const [hasMore, setHasMore] = useState(true);
	const route = useRoute();
	const stylesWeb = StyleSheet.create({
		messageContainer: { marginBottom: 90 }
	});
	const styles = StyleSheet.create({
		messageContainer: {  marginBottom: 70 }
	});

	const fetchMoreMessages = async () => {
		if (hasMore) {
			const response = await V1Request(
				'get',
				`GetSubMessages?lawsuitId=${route.params.lawsuitId}&top=${10}&skip=${messages.length}&lastId=${0}&clearUnread=${true}`,
				null,
				null,
				'json'
			);
			if (response.data.includes('<!DOCTYPE html>')) return;

			if (response.data?.length > 0) {
				setMessages((prevMessages) => [...prevMessages, ...response.data]);
			} else {
				setHasMore(false);
			}}
	};

	return (
		<FlatList
			style={
				Platform.OS === 'web'
					? stylesWeb.messageContainer
					: styles.messageContainer
			}
			key={'!'}
			keyExtractor={(item) => '!' + item.id}
			data={messages}
			renderItem={({ item }) => {
				const filteredArray = sendersAvatars.filter(u => u.userId === item.userId);
				return (
					<View key={item.id}>
						<MessageItem message={item} avatar={filteredArray.length > 0 ? filteredArray[0].imageUrl : null}/>
					</View>
				);
			}}
			showsVerticalScrollIndicator={true}
			inverted={true}
			onEndReachedThreshold={0.2}
			onEndReached={fetchMoreMessages}
		/>
	);
};
