import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Image, StyleSheet } from 'react-native';
import { useTheme } from 'react-native-paper';
import { BrandLogoLight } from '~components/brandLogoLight';
import { BrandLogoDark } from '~components/brandLogoDark';

export const LogoTitle = () => {
	const styles = StyleSheet.create({
		logoImage: {
			maxWidth: 275,
			height: 35,
			marginBottom: 10,
		},
	});

	const theme = useTheme();
	const [logoImage, setLogoImage] = useState(
		theme.dark ? BrandLogoDark : BrandLogoLight
	);

	useEffect(() => {
		setLogoImage(theme.dark ? BrandLogoDark : BrandLogoLight);
	}, [theme]);

	return <Image style={styles.logoImage} resizeMode={'contain'} source={logoImage} />;
};

export const LogoWeb = () => {
	const theme = useTheme();
	const [logoWidth, setLogoWidth] = useState(200);
	const [logoImage, setLogoImage] = useState(
		theme.dark ? BrandLogoDark : BrandLogoLight
	);	

	useEffect(() => {
		setLogoImage(theme.dark ? BrandLogoLight : BrandLogoLight);
	}, [theme]);

	useLayoutEffect(() => {
		Image.getSize(logoImage, (width, height) => {
			const divisor = height / 41;
			setLogoWidth(width / divisor);
		});
	},[]);

	const styles = StyleSheet.create({
		logoImage: {
			height: 41,
			width: logoWidth,
			marginLeft: 15,
			marginTop: 5
		},
	});

	return <Image style={styles.logoImage} resizeMode={'contain'}  source={logoImage} />;
};
