import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';

import DrawerContent from '~navigators/drawer/drawerContent';
import { MainStack } from '~navigators/stacks/mainStack';

const Drawer = createDrawerNavigator();

const DrawerNavigator = () => {
  
	return (
		<Drawer.Navigator id='root' drawerContent={props => <DrawerContent {...props}/>} screenOptions={{headerShown:false}} >
			<Drawer.Screen name="Main" component={MainStack}/>
		</Drawer.Navigator>
	);
};

export default DrawerNavigator;
