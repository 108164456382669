import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { Button, Divider, Text, Title } from 'react-native-paper';
import { HeaderBackButton } from '@react-navigation/elements';

import { update } from '~utils/immutability-helper-helper';
import { LoginRedirect } from '~utils/loginRedirect';
import { AppContext } from '~context/appContext';
import { V1Request } from '~utils/apiRequest';
import { ShowQuestions } from '~utils/formLogic';

import { Attachment, parseFileInfo } from '~components/formInputs/attachment';
import { CheckboxForm } from '~components/formInputs/checkbox';
import { CurrencyField } from '~components/formInputs/currencyField';
import { DatePicker } from '~components/formInputs/datePicker';
import { DateTimePicker } from '~components/formInputs/dateTimePicker';
import { DropDown } from '~components/formInputs/dropDown';
import { RadioButton } from '~components/formInputs/radioButton';
import { TextArea } from '~components/formInputs/textArea';
import { TextField } from '~components/formInputs/textField';
import { YesNo } from '~components/formInputs/yesNo';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import BrandBackground from '~components/brandBackground';
import { WebContainer } from '~components/webContainer';

//import testQuestions from '@@/testData/testQuestions';

export const Form = ({ route, navigation }) => {
	const [questions, setQuestions] = useState([]);
	const [form, setForm] = useState({});
	const [files, setFiles] = useState([]); // Stores files if there are any attachment type questions
	const [submitButtonVisible, setSubmitButtonVisible] = useState(false);

	const app = useContext(AppContext);

	let { lawsuitId, name, clientFormId, screenId } = route.params;
	const screens = form?.ClientForm?.ScreenIds ?? [];
	if (!screenId) screenId = screens[0];
	let screenIndex = screens.indexOf(screenId);
	// generate display info for header
	const screenDescr =
		screenIndex >= 0 ? `${screenIndex + 1} / ${screens.length}` : '';
	// if screen isn't the first screen, get the previous screen id
	const prevScreenId = screenIndex > 0 && screens[screenIndex - 1];

	const styles = StyleSheet.create({
		contentView: {
			flex: 1,
			justifyContent: 'flex-start',
			padding: 25
		},
		submitButton: {
			justifyContent: 'center',
			height: 55,
			marginTop: 25,
			marginBottom: 25,
			display: submitButtonVisible ? null : 'none'
		},
		submitButtonText: {
			fontSize: 18,
			padding: 5
		},
		paginationText: {
			fontSize: 14,
			position: 'absolute',
			marginRight: -15,
			right: 18,
			top:15
			
		},
		paginationTextWeb: {
			fontSize: 14,
			textAlign: 'right'
		},
		titleTextWeb: {
			textAlign: 'center'
		},
		divider: {
			marginTop: 15
		},
		formTopBar: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			display: submitButtonVisible ? null : 'none'
		}		
	});

	const backButton = <HeaderBackButton
		onPress={() => {
			if (!prevScreenId) {
				navigation.replace('Case', {
					lawsuitId,
					name
				});
			} else
				navigation.replace('Form', {
					lawsuitId,
					clientFormId,
					screenId: prevScreenId
				});
		}}
	/>;

	useLayoutEffect(() => {
		navigation.setOptions({
			headerLeft: () => backButton,
			headerTitle: form?.ClientForm?.Name,
			headerRight: () => (
				<Text style={styles.paginationText}>{screenDescr} </Text>
			)
		});
	}, [navigation, form]);

	useEffect(() => {
		app.setLoadingMessage('');
		loadData();
	}, []);

	const getFilesFromQuestions = (questions) => {
		let tempFiles = [];
		const tempFileQuestions = questions.filter(
			(cfq) => cfq.ClientFormComponent?.Name === 'Attachment'
		);

		try {
			tempFileQuestions.map((tfq) => {
				if (tfq.FormattedAnswer) {
					tfq.FormattedAnswer.map((fa) => {
						const result = parseFileInfo(fa, tfq);
						tempFiles.push(result);
					});
				}
			});

			return tempFiles;
		} catch (err) {
			// This alert is temporary for debugging on production app
			console.log(err);
			alert(err.name + '\n\n' + err.message);
		}
	};

	const loadData = async () => {
		const response = await V1Request(
			'get',
			`GetForm/${lawsuitId}?form=${clientFormId}&screen=${screenId}`,
			null,
			null,
			'json',
			app.setLoading
		);

		if (response.status === 401 || response.status === 403) {
			LoginRedirect(app, navigation);
			return;
		}

		const clientFormQuestions = response.data.screen.ClientFormQuestions;
		setForm(response.data.form);
		setQuestions(clientFormQuestions);

		const tempFiles = getFilesFromQuestions(clientFormQuestions);
		setFiles(tempFiles);
		setSubmitButtonVisible(true);
	};

	const handleNext = async () => {
		const [success, nextScreenId] = await saveScreen();
		if (success) {
			if (!nextScreenId) 
				navigation.replace('FormReview', { lawsuitId, name, clientFormId });
			else
				navigation.replace('Form', { lawsuitId, name, clientFormId, screenId: nextScreenId });
		}
	};


	const saveScreen = async () => {
		// screen id can be null on the first page only, so if it's null, get the first page id from the form data
		if (!screenId) {
			console.error('screenId is null');
			return [false];
		}
		// Save page and go to next
		const response = await V1Request(
			'post',
			`SaveForm/${lawsuitId}`,
			questions,
			{ form: clientFormId, screen: screenId },
			'json',
			app.setLoading
		);

		if (response.status === 200) {
			if (files.filter((f) => !f.uploaded).length > 0) {
				await uploadFiles(files.filter((f) => !f.uploaded));
			}
			return [true, response.data.nextScreenId];
		} else {
			//Alert something went wrong, update all questions
			response.data.screen.ClientFormQuestions?.map((question) => {
				setQuestions((qs) =>
					update(qs, [(q) => q.Id === question.Id, '$set'], question)
				);
			});
			return [false];
		}
	};

	const uploadFiles = async () => {
		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if (file.uploaded === false) {
				let formData = new FormData();
				if (file.assets[0].file) {
					// for web
					formData.append(file.assets[0].file.name, file.assets[0].file); 
				} 
				formData.append('files', {
					file: file.assets[0],
					uri: file.assets[0].uri,
					name: file.name,
					type: file.mimeType
				});
				app.setLoadingMessage(`Uploading File ${i + 1} of ${files.length}`);

				await V1Request(
					'post',
					'UploadFile',
					formData,
					{
						lawsuitId,
						recordName: files[i].HelperData.AttachmentDefaults.RecordName,
						formId: clientFormId,
						screenId,
						questionId: files[i].questionId,
						recordTypeId: files[i].HelperData.AttachmentDefaults.RecordTypeId,
						recordSubtypeId: null
					},
					'json',
					app.setLoading
				);
				app.setLoadingMessage('');
			}
		}
	};
	const updateScreen = (id, answer) => {
		setQuestions((qs) =>
			update(qs, [(q) => q.Id === id, 'Answer', '$set'], answer)
		);
	};

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<BrandBackground>
				<ScrollView>
					<WebContainer size="md">
						<View style={styles.formTopBar}>
							{backButton}
							<Title style={styles.titleTextWeb}>
								{form?.ClientForm?.Name}
							</Title>
							<Text style={styles.paginationTextWeb}>{screenDescr}</Text>
						</View>
						<Divider style={styles.divider} />
						<View style={styles.contentView}>
							{Questions()}
							<Button
								mode="contained"
								onPress={handleNext}
								style={styles.submitButton}
								labelStyle={styles.submitButtonText}
								uppercase={false}
							>
								Next
							</Button>
							<Text style={styles.paginationTextWeb}>{screenDescr}</Text>
						</View>
					</WebContainer>
				</ScrollView>
			</BrandBackground>
		);
	}

	function MobileView() {
		return (
			<KeyboardAwareScrollView
				extraHeight={Platform.OS === 'android' ? 25 : 0}
				enableOnAndroid
				enableResetScrollToCoords={false}
			>
				<View style={styles.contentView}>
					{Questions()}
					<Button
						mode="contained"
						onPress={handleNext}
						style={styles.submitButton}
						labelStyle={styles.submitButtonText}
						uppercase={false}
					>
						Next
					</Button>
				</View>
			</KeyboardAwareScrollView>
		);
	}

	function Questions() {
		return questions?.filter(ShowQuestions).map((q) => {
			// prettier-ignore
			/* eslint-disable indent */
			switch (q?.ClientFormComponent?.Name) {
				case 'Attachment': return <Attachment key={q.Id} form={form} question={q} files={files} setFiles={setFiles} />;
				case 'Checkbox': return <CheckboxForm key={q.Id} question={q} updateScreen={updateScreen} />;
				case 'CurrencyField': return <CurrencyField key={q.Id} question={q} updateScreen={updateScreen} />;
				case 'DatePicker': return <DatePicker key={q.Id} question={q} updateScreen={updateScreen} />;
				case 'DateTimePicker': return <DateTimePicker key={q.Id} question={q} updateScreen={updateScreen} />;
				case 'DropDown': return <DropDown key={q.Id} question={q} updateScreen={updateScreen} />;
				case 'RadioButton': return <RadioButton key={q.Id} question={q} updateScreen={updateScreen} />;
				case 'TextArea': return <TextArea key={q.Id} question={q} updateScreen={updateScreen} />;
				case 'TextField': return <TextField key={q.Id} question={q} updateScreen={updateScreen} />;
				case 'YesNo': return <YesNo key={q.Id} question={q} updateScreen={updateScreen} />;
				default: return <TextField key={q.Id} question={q} updateScreen={updateScreen} />;
			}
		});
	}
};
