import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { WebView } from 'react-native-webview';

const CPWebView = () => {
	const route = useRoute();
	return <>{Platform.OS === 'web' ? BrowserView() : MobileView()}</>;
	function MobileView() {
		return (
			<WebView source={{ uri: route.params.link }} />
		);
	}
	function BrowserView() {
		useEffect(() => {
			window.open(route.params.link, '_blank');
		},[]);
		return (
			<>
			</>
		);
	}
};

export default CPWebView;