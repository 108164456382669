import React, { useContext, useState, useRef } from 'react';
import {
	StyleSheet,
	TouchableOpacity,
	View,
	Platform,
	TextInput as RNTextInput
} from 'react-native';
import { TextInput, useTheme } from 'react-native-paper';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { V1Request } from '~utils/apiRequest';
import update from 'immutability-helper';
import { AppContext } from '~context/appContext';

export const MessageResponse = ({
	lawsuitId,
	messages,
	setMessages,
	lastId,
	setLastId,
	setMessageSending
}) => {
	const theme = useTheme();
	const messageInput = useRef();
	const app = useContext(AppContext);

	const [message, setMessage] = useState('');

	const sendMessage = async () => {
		if (message) {
			setMessageSending(true);
			const response = await V1Request(
				'post',
				`SendMessage?lawsuitId=${lawsuitId}&lastId=${lastId}`,
				message,
				null,
				'json',
				app.setLoading
			);

			if (response.data) {
				setMessages(update(messages, { $unshift: response.data }));
				setMessage('');
				setLastId(response.data[0]?.id);
			}
		}

		messageInput?.current?.focus();
		setMessageSending(false);
	};

	const styles = StyleSheet.create({
		inputView: {
			flex: 1,
			height: 40,
			borderRadius: 20,
			margin: 10,
			backgroundColor: theme.colors.messageInputBox
		},
		input: {
			width: '100%',
			paddingLeft: 20,
			fontSize: 20,
			height: 40,
			backgroundColor: theme.colors.transparent
		},
		sendButtonView: {
			width: 50,
			margin: 0,
			padding: 0,
			marginTop: 10
		},
		sendButton: {
			backgroundColor: theme.colors.primary,
			borderRadius: 40,
			height: 40,
			width: 40,
			alignItems: 'center',
			justifyContent: 'center'
		}
	});

	const stylesWeb = StyleSheet.create({
		responseView: {
			flexDirection: 'row',
			width: '100%',
			position: 'absolute',
			bottom: 0,
			right: 0,
			backgroundColor: theme.colors.messageSendBox
		}
	});

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<View style={stylesWeb.responseView}>
				<View style={styles.inputView}>
					<TextInput
						ref={messageInput}
						underlineColor="transparent"
						placeholder={'Message'}
						placeholderTextColor={theme.colors.placeholder}
						style={styles.input}
						onChangeText={(text) => {
							setMessage(text);
						}}
						textColor={theme.colors.text}
						activeUnderlineColor={theme.colors.transparent}
						value={message}
						onSubmitEditing={(e) => sendMessage(e)}
					/>
				</View>
				<View style={styles.sendButtonView}>
					<TouchableOpacity
						style={styles.sendButton}
						onPress={() => sendMessage()}
					>
						<Icon name={'send'} size={25} color={theme.colors.primaryLight} />
					</TouchableOpacity>
				</View>
			</View>
		);
	}

	function MobileView() {
		return (
			<>
				<View style={styles.inputView}>
					<RNTextInput
						ref={messageInput}
						placeholder={'Message'}
						placeholderTextColor={theme.colors.placeholder}
						style={styles.input}
						onChangeText={(text) => {
							setMessage(text);
						}}
						color={theme.colors.text}
						value={message}
						onSubmitEditing={(e) => sendMessage(e)}
					/>
				</View>
				<View style={styles.sendButtonView}>
					<TouchableOpacity
						style={styles.sendButton}
						onPress={() => sendMessage()}
					>
						<Icon name={'send'} size={25} color={theme.colors.primaryLight} />
					</TouchableOpacity>
				</View>
			</>
		);
	}
};
