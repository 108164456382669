
import cpdirect from '@@images/cpdirect/about.jpg';
import powrog from '@@images/powrog/about.jpg';
import flg from '@@images/flg/about.jpg';
import mmlf from '@@images/mmlf/about.jpg';
import klaw from '@@images/klaw/about.jpg';
import flaw from '@@images/flaw/about.jpg';
import cfs from '@@images/cfs/about.jpg';
import slaw from '@@images/slaw/about.jpg';
import mmdc from '@@images/mmdc/about.jpg';
import ksbr from '@@images/ksbr/about.jpg';
import vanstone from '@@images/vanstone/about.jpg';
import wieand from '@@images/wieand/about.jpg';
import { Brand } from '~utils/brand';

export const AboutImage = () => {
	switch (Brand()) {
	case 'cpdirect':
		return cpdirect;
	case 'powrog':
		return powrog;
	case 'flg':
		return flg;
	case 'mmlf':
		return mmlf;
	case 'klaw':
		return klaw;
	case 'flaw':
		return flaw;
	case 'cfs':
		return cfs;
	case 'slaw':
		return slaw;
	case 'mmdc':
		return mmdc;
	case 'ksbr':
		return ksbr;
	case 'vanstone':
		return vanstone;
	case 'wieand':
		return wieand;
	default:
		return cpdirect;
	}
};
