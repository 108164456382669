export function AttachmentIcon(fileName) {
	if (!fileName) return 'file';
	const extension = fileName.split('.').pop();
	if (!extension) return 'file'; // Default case, if no file extension

	switch (extension.toLowerCase()) {
	case 'xls':
	case 'xlsx':
		return 'file-excel';
	case 'gif':
		return 'file-gif-box';
	case 'jpg':
	case 'jpeg':
		return 'file-jpg-box';
	case 'mp3':
	case 'aac':
	case 'flac':
	case 'ogg':
	case 'wma':
	case 'wav':
	case 'pcm':
	case 'aiff':
	case 'mpa':
		return 'file-music';
	case 'pdf':
		return 'file-pdf-box';
	case 'png':
		return 'file-png-box';
	case 'ppt':
	case 'pptx':
		return 'file-powerpoint';
	case 'doc':
	case 'docx':
		return 'file-word';
	case 'xml':
		return 'file-xml-box';
	case 'bmp':
	case 'tif':
	case 'tiff':
	case 'raw':
	case 'psd':
	case 'eps':
	case 'ai':
	case 'indd':
	case 'heic':
		return 'file-image';
	case 'avi':
	case 'mkv':
	case 'mp4':
	case 'mpg':
	case 'mpeg':
	case 'wmv':
	case '3gp':
	case 'mov':
	case 'flv':
	case 'avchd':
	case 'webm':
	case 'asf':
	case 'm4v':
		return 'file-video';
	case 'zip':
	case '7z':
	case 'rar':
	case 'gz':
	case 'tar':
		return 'zip-box';

	default:
		return 'file';
	}
}

export function AttachmentIconColor(fileName) {
	if (!fileName) return '#808080';

	const extension = fileName.split('.').pop();
	if (!extension) return 'file'; // Default case, if no file extension

	switch (extension.toLowerCase()) {
	case 'xls':
	case 'xlsx':
		return '#1F7244';
	case 'gif':
	case 'jpg':
	case 'jpeg':
	case 'png':
	case 'bmp':
	case 'tif':
	case 'tiff':
	case 'raw':
	case 'psd':
	case 'eps':
	case 'ai':
	case 'indd':
	case 'heic':
		return '#28AFEA';
	case 'mp3':
	case 'aac':
	case 'flac':
	case 'ogg':
	case 'wma':
	case 'wav':
	case 'pcm':
	case 'aiff':
	case 'mpa':
		return '#FC852D';
	case 'pdf':
		return '#B30B00';
	case 'ppt':
	case 'pptx':
		return '#D04625';
	case 'doc':
	case 'docx':
		return '#295497';
	case 'xml':
		return '#F59C30';
	case 'avi':
	case 'mkv':
	case 'mp4':
	case 'mpg':
	case 'mpeg':
	case 'wmv':
	case '3gp':
	case 'mov':
	case 'flv':
	case 'avchd':
	case 'webm':
	case 'asf':
	case 'm4v':
		return '#1CBB9B';
	case 'zip':
	case '7z':
	case 'rar':
	case 'gz':
	case 'tar':
		return '#E7CD41';
	default:
		return '#808080';
	}
}
