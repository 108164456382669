import React, { useContext, useEffect, useState } from 'react';
import { Platform, View, StyleSheet } from 'react-native';
import StepIndicator from 'react-native-step-indicator';
import { useTheme } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { CaseContext } from '~context/caseContext';

export const CaseProgress = ({ caseProgress }) => {
	const theme = useTheme();
	const navigation = useNavigation();
	const _case = useContext(CaseContext);
	const [currentPosition, setCurrentPosition] = useState(0);

	useEffect(() => {
		setCurrentPosition(caseProgress?.ActiveCaseProgress?.SortOrder);
	}, [caseProgress]);

	const styles = StyleSheet.create({
		caseProgressView: {
			paddingTop: 10,
			paddingBottom: 20,
			backgroundColor: theme.colors.surface
		}
	});

	const labels = caseProgress?.CaseProgresses?.map(x => x.ShortName ? x.ShortName : x.Name);
	const customStyles = {
		stepIndicatorSize: 25,
		currentStepIndicatorSize: 30,
		separatorStrokeWidth: 2,
		currentStepStrokeWidth: 3,
		stepStrokeCurrentColor: theme.colors.primary,
		stepStrokeWidth: 3,
		stepStrokeFinishedColor: theme.colors.primary,
		stepStrokeUnFinishedColor: '#aaaaaa',
		separatorFinishedColor: theme.colors.primary,
		separatorUnFinishedColor: '#aaaaaa',
		stepIndicatorFinishedColor: theme.colors.primary,
		stepIndicatorUnFinishedColor: '#ffffff',
		stepIndicatorCurrentColor: '#ffffff',
		stepIndicatorLabelFontSize: 13,
		currentStepIndicatorLabelFontSize: 13,
		stepIndicatorLabelCurrentColor: theme.colors.primary,
		stepIndicatorLabelFinishedColor: '#ffffff',
		stepIndicatorLabelUnFinishedColor: '#aaaaaa',
		labelColor: theme.colors.text,
		labelSize: 12,
		currentStepLabelColor: theme.colors.primary
	};

	if (caseProgress?.CaseProgresses?.length > 0) {
		return (
			<View style={styles.caseProgressView}>
				<StepIndicator
					onPress={() => {
						if (Platform.OS === 'web') return;

						_case.setCaseProgress(caseProgress);
						navigation.openDrawer();
					}}
					stepCount={caseProgress?.CaseProgresses?.length}
					currentPosition={currentPosition}
					customStyles={customStyles}
					labels={labels}
				/>
			</View>
		);
	}
	else return null;
};