import axios from 'axios';
import buildQuery from 'odata-query';
import { Uuid } from '~utils/uuid';
import { nativeApplicationVersion } from 'expo-application';
import Constants from 'expo-constants';
import { FirmId } from '~utils/brand';
import qs from 'qs';

import { GetAuth } from '~utils/auth';
import { GetLocalData } from '~utils/localStorage';

const _ = require('lodash');
const firmId = FirmId();
const appVersion = nativeApplicationVersion;
export const urlV1Prefix = Constants.expoConfig.extra.v1ApiUrl;
export const urlApiPrefix = Constants.expoConfig.extra.apiBaseUrl;
export const urlODataPrefix = Constants.expoConfig.extra.odataBaseUrl;
// export const debug = Constants.expoConfig.extra.debug;

function objectConvert(method, endpoint, data, query) {
	let rest = {};
	if (typeof method === 'object') {
		let url;
		({ method, endpoint, url, data, query, ...rest } = method);
		endpoint = url || endpoint;
		if (!method) method = 'GET';
	}
	return [method, endpoint, data, query, rest];
}

const timeout = 200000;
                
const debug = true;

export async function V1Request(
	method,
	endpoint,
	data,
	query,
	responseType = 'json',
	setLoading
) {
	let p2;
	[method, endpoint, data, query, p2] = objectConvert(
		method,
		endpoint,
		data,
		query
	);

	const localSettingsData = await GetLocalData('settingsData');
	const addlFirmIds = localSettingsData?.AdditionalFirmIds;

	//request options added to every request
	const headers = {
		'Mobile-Device-Id': await Uuid(),
		'CasePacer-Firm-Id': `${firmId},${addlFirmIds}`,
		'App-Version': appVersion,
	};

	// Exceptions for using authorzation on request
	if (endpoint.toLowerCase() !== 'login') {
		const auth = await GetAuth();
		// ToDo: Check for expired tokens
		if (auth?.token) {
			headers.Authorization = 'Bearer ' + auth?.token;
		}
	}
	

	if (query && typeof query === 'object') {
		query = '?' + qs.stringify(query, { encodeValuesOnly: true });
	}

	const url = urlV1Prefix + endpoint + (query ?? '');
	if (setLoading) setLoading(true);
	const response = await axios({
		method,
		url,
		data,
		headers,
		responseType,
		timeout: timeout,
		withCredentials: true,
		...p2,
	}).catch((error) => {
		if (debug) {
			console.log(`(${error.response?.status}) API Request: ${url}`);
		}
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			console.log(error.response?.data);
			console.log(error.response?.status);
			console.log(error.response?.headers);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log('Error', error.message);
		}
		// console.log(error.config);
		if (setLoading) setLoading(false);
		return error.response;
	});
	if (debug) {
		console.log(`(${response.status}) API Request: ${url}`);
	}
	if (setLoading) setLoading(false);
	return response;
}

export async function ODataRequest(
	method,
	endpoint,
	postData,
	query,
	setLoading
) {
	let p2;
	[method, endpoint, postData, query, p2] = objectConvert(
		method,
		endpoint,
		postData,
		query
	);

	if (query && typeof query === 'object') {
		query = buildQuery(query);
	}
	const url = urlODataPrefix + endpoint + (query ?? '');

	// Use a copy of data since mutating data will possibly effect a page using that data
	const data = _.cloneDeep(postData);

	if (method === 'put' || method === 'post' || method === 'patch') {
		if (method === 'post') {
			// Remove Id Property from data.  Id property will break deserialization when posting to API Odata controller
			delete data.Id;
		}

		// Read-only (unmapped EDM model properties)
		const unmappedProperties = Object.keys(data).filter(function filterProps(
			k
		) {
			return k.indexOf('_') === 0; // unmapped properties should begin with _
		});
		for (const key in unmappedProperties) {
			delete data[unmappedProperties[key]];
		}
	}

	if (setLoading) setLoading(true);

	const response = await axios({
		method,
		url,
		data,
		responseType: 'json',
		withCredentials: true,
		timeout: timeout,
		...p2,
	}).catch((error) => {
		if (error.response) {
			console.log('error.response:', error.response);
			// Server responded with error
			// console.log(error.response.data);
			// console.log(error.response.status);
			// console.log(error.response.headers);
		} else if (error.request) {
			// Client id not Receive a response
			// console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			// console.log('Error', error.message);
		}
		// console.log(error.config);

		if (debug) {
			console.log(`(${response?.status}) API Request: ${url}`);
		}

		const errorMessage = error.response?.data?.error?.message;
		if (errorMessage) {
			const modelState = JSON.parse(error.response?.data?.error?.message);
			if (modelState) {
				const errorMessages = {};
				Object.entries(modelState).forEach((error2) => {
					const [key, value] = error2;
					errorMessages[key] = value;
				});
				error.errorMessages = errorMessages;
			}
		}
		if (setLoading) setLoading(false);
		return error;
	});
	if (setLoading) setLoading(false);
	return response;
}

export async function ApiRequest(
	method,
	endpoint,
	data,
	query,
	responseType = 'json',
	setLoading
) {
	let p2;
	[method, endpoint, data, query, p2] = objectConvert(
		method,
		endpoint,
		data,
		query
	);

	if (query && typeof query === 'object') {
		query = '?' + qs.stringify(query, { encodeValuesOnly: true });
	}

	const url = urlApiPrefix + endpoint + (query ?? '');
	if (debug) {
		console.log('API Request: ' + url);
	}

	if (setLoading) setLoading(true);

	const response = await axios({
		method,
		url,
		data,
		responseType,
		withCredentials: true,
		timeout: timeout,
		...p2,
	}).catch((error) => {
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			// console.log(error.response?.data);
			// console.log(error.response?.status);
			// console.log(error.response?.headers);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			// console.log(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			// console.log('Error', error.message);
		}
		// console.log(error.config);
		if (setLoading) setLoading(false);
		return error.response;
	});
	if (setLoading) setLoading(false);
	return response;
}
