import React, { useLayoutEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { TermsOfService } from '~screens/case/termsOfService';
import { PreLogin } from '~screens/case/preLogin';
import { Login } from '~screens/case/login';
import { Register } from '~screens/case/register';
import { PasswordReset } from '~screens/case/passwordReset';
import { PasswordResetEmail } from '~screens/case/passwordResetEmail';
import { PasswordResetEmailSuccess } from '~screens/case/passwordResetEmailSuccess';

import { HeaderLeft } from '~components/headerLeft';

const Stack = createStackNavigator();

export const AuthStack = ({ navigation, route }) => {
	useLayoutEffect(() => {
		navigation.setOptions({ headerShown: false });
	}, [navigation, route]);

	return (
		<Stack.Navigator
			initialRouteName='PreLogin'
		>
			<Stack.Screen
				name="PreLogin"
				component={PreLogin}
				options={{
					headerLeft: () => null,
					headerShown: false
				}}
			/>
			<Stack.Screen
				name="Login"
				component={Login}
				options={{
					headerLeft: () => <HeaderLeft screen="PreLogin" />,
					headerTitle: () => null
				}}
			/>
			<Stack.Screen
				name="PasswordReset"
				component={PasswordReset}
				options={{
					title: 'Reset Password',
					headerLeft: () => <HeaderLeft screen="Login" />
				}}
			/>
			<Stack.Screen
				name="PasswordResetEmail"
				component={PasswordResetEmail}
				options={{
					headerLeft: () => <HeaderLeft screen="Login" />,
					headerTitle: () => null
				}}
			/>
			<Stack.Screen
				name="PasswordResetEmailSuccess"
				component={PasswordResetEmailSuccess}
				options={{
					headerLeft: () => <HeaderLeft screen="Login" />,
					headerTitle: () => null
				}}
			/>
			<Stack.Screen
				name="TermsOfService"
				component={TermsOfService}
				options={{ title: 'Terms of Service' }}
			/>
			<Stack.Screen
				name="Register"
				component={Register}
				options={{
					headerLeft: () => <HeaderLeft screen="Login" />,
					headerTitle: () => null
				}}
			/>
		</Stack.Navigator>
	);
};
