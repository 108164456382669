import React from 'react';
import { View, SafeAreaView, StyleSheet, Platform } from 'react-native';
import { Button, Caption, Title, useTheme } from 'react-native-paper';
import EmailSvg from '@@icons/ic-mail-dt.svg';
import { WebContainer } from '~components/webContainer';
import BrandBackground from '~components/brandBackground';

export const PasswordResetEmailSuccess = ({ navigation }) => {
	const theme = useTheme();
	const styles = StyleSheet.create({
		outerView: {
			flex: 1,
			backgroundColor: theme.colors.background,
			justifyContent: 'center'
		},
		innerView: {
			paddingHorizontal: '5%',
			marginTop: '4%',
			alignItems: 'center'
		},
		title: { fontSize: 24, marginVertical: 25 },
		caption: { fontSize: 18, marginVertical: 25 },
		submitButton: {
			marginTop: 15,
			marginBottom: 15
		},
		submitButtonText: {
			fontSize: 18,
			padding: 5
		},
		titleWeb: {
			fontSize: 24,
			marginVertical: 25,
			textAlign: 'center'
		}
	});

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<BrandBackground>
				<WebContainer size="sm">
					<View style={styles.innerView}>
						<EmailSvg
							fill={theme.colors.primary}
							highlight={theme.colors.primary}
							width={100}
							height={100}
						/>
						<Title style={styles.titleWeb}>Password Reset Email Sent</Title>
						<Caption style={styles.caption}>
							Check your email and follow the link provided to complete the
							password reset process.
						</Caption>

						<Button
							mode="text"
							compact={true}
							onPress={() => {
								navigation.push('Login');
							}}
							style={styles.submitButton}
							labelStyle={styles.submitButtonText}
							uppercase={false}
						>
							Back to Log In Screen
						</Button>
					</View>
				</WebContainer>
			</BrandBackground>
		);
	}

	function MobileView() {
		return (
			<SafeAreaView style={styles.outerView} forceInset={{ top: 'always' }}>
				<View style={styles.innerView}>
					{/* <EmailSvg
					fill={theme.colors.primary}
					highlight={theme.colors.primary}
					width={100}
					height={100}
					marginBottom={'5%'}
				/> */}
					<Title style={styles.title}>Password Reset Email Sent</Title>
					<Caption style={styles.caption}>
						Check your email and follow the link provided to complete the
						password reset process.
					</Caption>

					<Button
						mode="text"
						compact={true}
						onPress={() => {
							navigation.push('Login');
						}}
						style={styles.submitButton}
						labelStyle={styles.submitButtonText}
						uppercase={false}
					>
						Back to Log In Screen
					</Button>
				</View>
			</SafeAreaView>
		);
	}
};
