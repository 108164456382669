import React, { useContext } from 'react';
import { Dialog, Button, Paragraph, Portal } from 'react-native-paper';

import { AppContext } from '~context/appContext';

export const Notification = () => {
	const app = useContext(AppContext);
	const hideDialog = () => app.setNotificationVisible(false);

	const title = 'Oops, something went wrong.';
	const msg = 'Please try again later';

	return (
		<Portal>
			<Dialog visible={app.notificationVisible} onDismiss={hideDialog}>
				<Dialog.Title>{title}</Dialog.Title>
				<Dialog.Content>
					<Paragraph>{msg}</Paragraph>
				</Dialog.Content>
				<Dialog.Actions>
					<Button onPress={hideDialog}>Ok</Button>
				</Dialog.Actions>
			</Dialog>
		</Portal>
	);
};
