import React, { useContext, useEffect, useState } from 'react';
import {
	ImageBackground,
	Platform,
	StyleSheet,
	useWindowDimensions,
	View
} from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';

import { LoginRedirect } from '~utils/loginRedirect';
import { AppContext } from '~context/appContext';
import { CaseContext } from '~context/caseContext';
import { FAB, useTheme } from 'react-native-paper';
import { FlatList } from 'react-native-gesture-handler';
import { FileItem } from '~screens/case/files/partials/fileItem';
import { DocusignItem } from '~screens/case/files/partials/docusignItem';
import { V1Request } from '~utils/apiRequest';
import update from 'immutability-helper';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';

import {  FABAttachmentAndroid } from '~components/formInputs/attachmentAndroid';
import { FABAttachmentIOS } from '~components/formInputs/attachmentIOS';
import bg_dark from '@@images/0_shared/generic_bg_dark.jpg';
import bg_light from '@@images/0_shared/generic_bg_light.jpg';

export const Files = () => {
	const app = useContext(AppContext);
	const _case = useContext(CaseContext);
	const navigation = useNavigation();
	const route = useRoute();
	const { height } = useWindowDimensions();
	const theme = useTheme();

	const [files, setFiles] = useState([]);
		
	const [bg, setBg] = useState(
		theme.dark ? bg_dark : bg_light
	);
	
	const loadFiles = async () => {
		const response = await V1Request(
			'get',
			`ListFiles?lawsuitId=${route.params.lawsuitId}`,
			null,
			null,
			'json',
			app.setLoading
		);
		if (response.status === 401 || response.status === 403) {
			LoginRedirect(app, navigation);
			return;
		} else {
			for (let i = 0; i < response.data.length; i++ ) {
				response.data[i].DocuSignJson = JSON.parse(response.data[i].DocuSignJson);
			}	
			setFiles(response.data.sort((_a, b) => b.DocuSignJson?.Status === 'pending' ? 1 : -1));
			if(response.data.filter((item) => item.DocuSignJson))
				_case.setFilesBadge(true);
			else
				_case.setFilesBadge(false);
		}
	};

	useEffect (() => {
		const loadData = async () => {
			loadFiles();
		};
		loadData();
	},[]);

	useEffect(() => {
		setBg(theme.dark ? bg_dark : bg_light);
	}, [theme]);

	const styles = StyleSheet.create({
		backgroundImage: {
			flex: 1,
			justifyContent: 'center'
		},
		contentView: {
			flex: 1,
			marginTop: 0
		},
		contentViewMobile: {
			padding: 25
		},
		fab: {
			backgroundColor: theme.colors.primary,
			position: 'absolute',
			margin: 16,
			right: 0,
			bottom: 0,
			zIndex: 1000
		}
	});

	const stylesWeb = StyleSheet.create({
		filesContainer: {
			minHeight: 400,
			height: height - 360,
			marginBottom: -40,
			paddingBottom: 50

		},
		contentView: {
			flex: 1,
			marginTop: 20,			
		},
	});

	const confirmPicker = async (value) => {
		if (value === 'camera') {
			handleCamera();
		} else if (value === 'photo') {
			handlePhoto();
		} else if (value === 'file') {
			handleFile();
		}
	};

	const handleCamera = async () => {
		let permissions = await ImagePicker.getCameraPermissionsAsync();
		if (!permissions.granted) await ImagePicker.requestCameraPermissionsAsync();

		const result = await ImagePicker.launchCameraAsync({
			allowsEditing: false,
			aspect: [4, 3],
			quality: 0.75
		});

		if (result.canceled) return;
		
		result.mimeType = `image/${result.assets[0].uri.split('.').pop()}`;
		result.name = `camera_photo.${result.assets[0].uri.split('.').pop()}`;
		result.recordFileId = null;
		result.description = 'Camera';
		setFiles(update(files, { $push: [result] }));
		uploadFile(result);				
	};

	const handlePhoto = async () => {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: false,
			aspect: [4, 3],
			quality: 0.75
		});
		
		if (result.canceled) return;
		
		result.mimeType = `image/${result.assets[0].uri.split('.').pop()}`;
		result.name = `photo.${result.assets[0].uri.split('.').pop()}`;
		result.recordFileId = null;
		result.description = 'Photo';
		setFiles(update(files, { $push: [result] }));
		uploadFile(result);		
	};

	const handleFile = async () => {
		const result = await DocumentPicker.getDocumentAsync();
		
		if (result.canceled) return;

		result.mimeType = result.assets[0].mimeType;
		result.name = result.assets[0].name;
		result.uploaded = false;
		result.recordFileId = null;
		result.description = 'File';
		
		uploadFile(result);
		setFiles(update(files, { $push: [result] }));
	};

	const uploadFile = async (result) => {
		let formData = new FormData();
		let fileResult = result.assets[0]; 
		console.log('result: ', result);
		
		if (fileResult.file) formData.append(fileResult.file.name, fileResult.file);
		
		formData.append('files', {
			uri: fileResult.uri,
			name: result.name,
			type: result.mimeType
		});
		app.setLoadingMessage(
			`Uploading File ${files.length + 1} of ${files.length + 1}`
		);
		await V1Request(
			'post',
			'UploadFileManual',
			formData,
			{
				lawsuitId: route.params.lawsuitId,
				recordName: result.name
			},
			'json',
			app.setLoading
		);

		loadFiles();
		app.setLoadingMessage('');
	};


	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;
	
	function WebView() {
		return (
			<>
				<FAB style={styles.fab} icon="upload" onPress={() => handleFile()} />
				<View style={stylesWeb.filesContainer}>
					<FlatList
						style={stylesWeb.contentView}
						keyExtractor={(item) => '!' + item.RecordFileId}
						data={files}
						renderItem={({ item }) => {
							if (item.DocuSignJson && item.DocuSignJson.EnvelopeId !== null) {
								return (
									<View key={item.Id}>
										<DocusignItem file={item} />
									</View>
								);
							}
							return (
								<View key={item.Id}>
									<FileItem file={item} />
								</View>
							);
						}}
						showsVerticalScrollIndicator={true}
					/>
				</View>
			</>
			
		);
	}

	function MobileView() {
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={bg}
			>
				<View style={styles.contentView}>
					{Platform.OS === 'android' ? (
						<FABAttachmentAndroid confirmPicker={confirmPicker} />
					) : Platform.OS === 'ios' ? (
						<FABAttachmentIOS confirmPicker={confirmPicker} />
					) : null}
					<FlatList
						style={styles.contentViewMobile}
						keyExtractor={(item) => '!' + item.RecordFileId}
						data={files}
						renderItem={({ item }) => {
							if (item.DocuSignJson && item.DocuSignJson.EnvelopeId !== null) {
								return (
									<View key={item.Id}>
										<DocusignItem file={item} />
									</View>
								);
							}
							return (
								<View key={item.Id}>
									<FileItem file={item} />
								</View>
							);
						}}
						showsVerticalScrollIndicator={true}
					/>
				
				</View>
			</ImageBackground>
			
		);
	}
};
