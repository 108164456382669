import * as SecureStore from '~utils/secureStore';

const authModel = {
	agreedTerms: false,
	loggedIn: false,
	email: '',
	token: '',
	expiration: ''
};

export const SetAuth = async (auth) => {
	if (!auth) await SecureStore.setItemAsync('auth', JSON.stringify(authModel));
	else await SecureStore.setItemAsync('auth', JSON.stringify(auth));
};

export const GetAuth = async () => {
	let auth = undefined;
	try {
		auth = await SecureStore.getItemAsync('auth');		
	} catch {
		// An error occurred trying getItemAsync.  This seems to happen sometimes on Android production builds only.
		console.log(
			'Error occured trying to get existing UUID via SecureStore.getItemAsync(secure_deviceid)'
		);
	}
	if (auth) return JSON.parse(auth);
	return null; 
};
