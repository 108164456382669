import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Platform, ScrollView, StyleSheet, Text, useWindowDimensions } from 'react-native';
import { TabView, SceneMap } from 'react-native-tab-view';

import { HeaderBackButton } from '@react-navigation/elements';

import { AppContext } from '~context/appContext';
import { CaseContext } from '~context/caseContext';
import { LoginRedirect } from '~utils/loginRedirect';
import { V1Request } from '~utils/apiRequest';

import BrandBackground from '~components/brandBackground';
import { WebContainer } from '~components/webContainer';
import { CaseTabBar } from '~components/caseTabBar';
import { CaseProgress } from '~components/caseProgress';

import { Forms } from '~screens/case/forms';
import { Messages } from '~screens/case/messages';
import { Files } from './files/index';
import { Treatment } from '~screens/case/treatment';
import { Calendar } from '~screens/case/calendar';
import { GetLocalData } from '~utils/localStorage';

const renderScene = SceneMap({
	forms: Forms,
	messages: Messages,
	files: Files,
	treatment: Treatment,
	calendar: Calendar,
});

const styles = StyleSheet.create({
	caseTitle: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: 18,
		marginBottom: 10,
		marginTop: -20
	}
});

// eslint-disable-next-line no-unused-vars
export const Case = ({ route, navigation, }) => {
	const app = useContext(AppContext);
	const _case = useContext(CaseContext);
	const layout = useWindowDimensions();
		
	const { lawsuitId, tab } = route.params;
	const [index, setIndex] = useState(tab ? tab : 0);
	const [routes, setRoutes] = useState([
		{ key: 'forms', title: 'Forms' },
		{ key: 'messages', title: 'Messages' },
		{ key: 'files', title: 'Files' },
		{ key: 'treatment', title: 'Treatment' },
		{ key: 'calendar', title: 'Calendar' },
	]);
	
	useEffect(() => {
		const fetchData = async () => {
			const localSettingsData = await GetLocalData('settingsData');
			if(!localSettingsData.MsgEnabled) setRoutes((prev) => prev.filter((x) => x.key !== 'messages'));
		};
		fetchData();
	}, []);
	
	useEffect(() => {
		// On web, params are lost if case page is refreshed, 
		// loaded from a link, or if user is navigated to it 
		// from a back button.  Need to check if params are lost
		// and get them and store them in case context
		if (tab) {
			_case.setActiveRoute(routes[tab].key);
		} else {
			_case.setActiveRoute('');
		}
		
		if (!_case.lawsuitId) {
			const loadData = async () => {
				const response = await V1Request(
					'get',
					`GetCase/${lawsuitId}`,
					null,
					`?lawsuitId=${lawsuitId}`,
					'json',
					app.setLoading
				);

				if (response.status === 401 || response.status === 403) {
					LoginRedirect(app, navigation);
					return;
				} else {
					const thisCase = response.data;
					_case.setLawsuitId(thisCase.LawsuitId);
					_case.setCaseName(thisCase.Name);
					_case.setClient(thisCase.Client);
				}

				const response2 = await V1Request(
					'get',
					'GetCaseProgress',
					null,
					`?lawsuitId=${lawsuitId}`,
					'json',
					null
				);

				_case.setCaseProgress(response2.data);
			};
			loadData();
		}

		// Set active tab if set
		if (tab) {
			handleIndexChanged(tab);
		}
	}, []);

	useLayoutEffect(() => {
		navigation.setOptions({
			headerLeft: () => backButton,
			headerTitle: _case.client
		});
	}, [navigation]);

	const backButton = (
		<HeaderBackButton onPress={() => navigation.replace('Cases')} />
	);

	const handleIndexChanged = (index) => {
		setIndex(index);
		_case.setActiveRoute(routes[index].key);
	};
		
	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function MobileView() {
		const renderTabBar = (props, index, setIndex) => (
			<CaseTabBar {...props} index={index} setIndex={setIndex} />
		);
		return (
			<>
				<TabView
					navigationState={{ index, routes }}
					renderScene={renderScene}
					onIndexChange={handleIndexChanged}
					renderTabBar={renderTabBar}
					initialLayout={{ width: layout.width }}
				/>
				<CaseProgress caseProgress={_case.caseProgress} />
				
			</>
		);
	}

	function WebView() {
		const renderTabBar = (props, index, setIndex) => (
			<CaseTabBar {...props} index={index} setIndex={setIndex} />
		);
		return (
			<BrandBackground>
				<ScrollView>
					<WebContainer size={'md'} paddingBottom={0}>
						<Text style={styles.caseTitle}>{_case.client}</Text>
						<TabView
							navigationState={{ index, routes }}
							renderScene={renderScene}
							onIndexChange={handleIndexChanged}
							renderTabBar={renderTabBar}
							initialLayout={{ width: layout.width }}
						/>
						<CaseProgress caseProgress={_case.caseProgress} />
					</WebContainer>
				</ScrollView>
			</BrandBackground>
		);
	}
};

