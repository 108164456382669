import React, { useEffect, useContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import { AppContext } from '~context/appContext';
import { V1Request } from '~utils/apiRequest';

export const Redirect = ({ route }) => {
	const nav = useNavigation();
	const app = useContext(AppContext);
	const data = route.params.data;
	useEffect(() => {
		const redirectToCase = async () => {
			switch (data.identifier) {
			case 'message':
				nav.navigate('CasesDrawerNavigator', { screen: 'CaseStack', params: { screen: 'Case', params: { lawsuitId: data.lawsuitId, tab: 1 }}});
				break;
			case 'docusign': {
				const response = await V1Request('get', 'GetRecipientViewUrl', null, `?recordId=${data.recordId}&userEmail=${app.auth.email}`, 'json', null);
				if (response.data !== null) {
					nav.navigate('CPWebView', { link: response.data.Url });
				} else {
					nav.navigate('CasesDrawerNavigator', { screen: 'CaseStack', params: { screen: 'Cases'}});
				}
				break;
			}
			}
		};
		redirectToCase();
	});
	return (
		<>
		</>);

};
