import { GetLocalData } from '~utils/localStorage';
import { ODataRequest } from '~utils/apiRequest';
import { SyncLocalRemote } from '~utils/syncLocalRemote';
import { FirmId } from '~utils/brand';

const firmId = FirmId();

const useDataRefresh = async () => {
	const localSettingsData = await GetLocalData('settingsData');
	const localAttorneysData = await GetLocalData('attorneysData');
	const localBlogsData = await GetLocalData('blogsData');

	const prSettings = (async function() {
		const settingsResponse = await ODataRequest(
			'get',
			`PublicCPDirectSetting/${firmId}`,
			null,
			'?$expand=CPDirectSettingLocations'
		);

		if (settingsResponse.data) {
			SyncLocalRemote('settingsData', localSettingsData, settingsResponse.data);
		}
	})();

	const prUsers = (async function() {
		const attorneysResponse = await ODataRequest(
			'get',
			'PublicCasePacerUser',
			null,
			`?count=true&$filter=ParentFirmId eq ${firmId} and AttorneyListingEnable eq true&$expand=CasePacerUserType&$select=PartyId,FirstName,LastName,MiddleName,AttorneyListingTitle,AttorneyListingEnable`
		);

		if (attorneysResponse.data?.value) {
			SyncLocalRemote(
				'attorneysData',
				localAttorneysData,
				attorneysResponse.data.value
			);
		}
	})();

	const prBlogs = (async function() {
		const blogsResponse = await ODataRequest(
			'get',
			'PublicCPDirectBlog',
			null,
			`?$count=true&$filter=FirmId eq ${firmId}&$expand=CPDirectBlogCategory,CPDirectBlogImages`
		);

		if (blogsResponse.data?.value) {
			SyncLocalRemote('blogsData', localBlogsData, blogsResponse.data.value);
		}
	})();

	const retval = {
		swr: localSettingsData && localAttorneysData && localBlogsData,
		refresh: Promise.all([prSettings, prUsers, prBlogs]),
	};
	if (!retval.swr) await retval.refresh;
	return retval;
};

export const useSettingsRefresh = async () => {
	const localSettingsData = await GetLocalData('settingsData');

	const prSettings = (async function() {
		const settingsResponse = await ODataRequest(
			'get',
			`PublicCPDirectSetting/${firmId}`,
			null,
			'?$expand=CPDirectSettingLocations'
		);

		if (settingsResponse.data) {
			SyncLocalRemote('settingsData', localSettingsData, settingsResponse.data);
		}
	})();

	const retval = {
		swr: localSettingsData,
		refresh: Promise.all([prSettings]),
	};
	if (!retval.swr) await retval.refresh;
	return retval;
};

export default useDataRefresh;
