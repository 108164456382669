import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';

import CasesDrawerContent from '~navigators/drawer/casesDrawerContent';
import { CaseStack } from '~navigators/stacks/caseStack';

const Drawer = createDrawerNavigator();

const CasesDrawerNavigator = () => {

	return (
		<Drawer.Navigator drawerContent={props => <CasesDrawerContent {...props} />} screenOptions={{
			headerShown: false,
			drawerType: 'front',
			drawerPosition: 'right',
			swipeEnabled: false
		}} >
			<Drawer.Screen name="CaseStack" component={CaseStack} />
		</Drawer.Navigator>
	);
};

export default CasesDrawerNavigator;
