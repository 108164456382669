import React, { useEffect, useState } from 'react';
import { Keyboard, Platform, StyleSheet, View } from 'react-native';
import { Text, TextInput, useTheme } from 'react-native-paper';
import DateTimePickerModal from 'react-native-modal-datetime-picker'; // Used for Mobile
import { DatePickerModal } from 'react-native-paper-dates'; // Used for web
import { Validation } from '~components/formInputs/validation';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

const styles = StyleSheet.create({
	questionView: {
		marginVertical: 10
	}
});

const fmt = 'MM/dd/yyyy';

export const DatePicker = ({ question, updateScreen }) => {
	const dateToString = (date) => !date ? '' : format(date, fmt);

	const stringToDate = (date) => {
		const parsedDate = parse(date, fmt, 0);
		if (isValid(parsedDate)) return parsedDate;
		return null;
	};

	const [answer, setAnswer] = useState(stringToDate(question.Answer));
	const [datePickerVisibility, setDatePickerVisibility] = useState(false);
	const theme = useTheme();

	useEffect(() => {
		updateScreen(question.Id, dateToString(answer));
	}, [answer]);

	function open() {
		Keyboard.dismiss();
		setDatePickerVisibility(true);
	}

	function close() {
		setDatePickerVisibility(false);
	}

	const handleConfirm = (date) => {
		setAnswer(new Date(date));
		close();
	};

	const DatePicker = () => {
		if (Platform.OS === 'web') {
			return (
				<DatePickerModal
					visible={datePickerVisibility}
					onConfirm={d => handleConfirm(d.date)}
					onDismiss={() => close()}
					mode="single"
					date={answer ?? new Date()}
				/>
			);
		} else {
			return (
				<DateTimePickerModal
					isVisible={datePickerVisibility}
					mode="date"
					buttonTextColorIOS={theme.colors.primary}
					onConfirm={handleConfirm}
					onCancel={() => close()}
					date={answer ?? new Date()} 
				/>
			);
		}
	};

	return (
		<View style={styles.questionView}>
			<Text>{question.Question}</Text>
			<TextInput
				right={
					<TextInput.Icon
						icon="calendar-range"
						color={theme.colors.primary}
					/>
				}
				mode="outlined"
				showSoftInputOnFocus={false}
				onFocus={() => open()}
				// onPressIn={() => open()}
				onBlur={() => close}
				value={dateToString(answer)}
			/>
			{DatePicker()}
			<Validation question={question} />
		</View>
	);
};

/// Used for non Case Forms
export const DatePickerBasic = ({ question, answer, setAnswer }) => {
	const dateToString = (date) => !date ? '' : format(date, fmt);
	const [datePickerVisibility, setDatePickerVisibility] = useState(false);
	const theme = useTheme();

	function open() {
		Keyboard.dismiss();
		setDatePickerVisibility(true);
	}

	function close() {
		setDatePickerVisibility(false);
	}

	const handleConfirm = (date) => {
		if (date) setAnswer(new Date(date));
		else setAnswer(new Date());
		close();
	};

	const DatePicker = () => {
		if (Platform.OS === 'web') {
			return (
				<DatePickerModal
					visible={datePickerVisibility}
					onConfirm={d => handleConfirm(d.date)}
					onDismiss={() => close()}
					mode="single"
					date={answer}
				/>
			);
		} else {
			return (
				<DateTimePickerModal
					isVisible={datePickerVisibility}
					mode="date"
					buttonTextColorIOS={theme.colors.primary}
					onConfirm={handleConfirm}
					onCancel={() => close()}
					date={answer} 
				/>
			);
		}
	};

	return (
		<View style={styles.questionView}>
			<Text>{question}</Text>
			<TextInput
				right={
					<TextInput.Icon
						icon="calendar-range"
						color={theme.colors.primary}
					/>
				}
				mode="outlined"
				showSoftInputOnFocus={false}
				onFocus={() => open()}
				// onPressIn={() => open()}
				onBlur={() => close}
				value={dateToString(answer)}
			/>
			{DatePicker()}
		</View>
	);
};
