import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, ToggleButton, useTheme } from 'react-native-paper';
import { Validation } from '~components/formInputs/validation';

const styles = StyleSheet.create({
	questionView: {
		marginVertical: 10
	}
});

export const YesNo = ({ question, updateScreen }) => {
	const theme = useTheme();
	const [answer, setAnswer] = useState(question.Answer);
	const [yesColor, setYesColor] = useState(answer === 'true' ? theme.colors.primary : theme.colors.disabled);
	const [noColor, setNoColor] = useState(answer === 'false' ? theme.colors.primary : theme.colors.disabled);
	
	return (
		<View style={styles.questionView}>
			<Text>{question.Question}</Text>
			<ToggleButton.Row
				style={{marginTop: 5}}
				value={answer}
				onValueChange={(newValue) => {
					setAnswer(newValue);
					if (newValue === 'true') {
						setYesColor(theme.colors.primary);
						setNoColor(theme.colors.disabled);
					} else if (newValue ==='false') {
						setYesColor(theme.colors.disabled);
						setNoColor(theme.colors.primary);
					}
					else {
						setYesColor(theme.colors.disabled);
						setNoColor(theme.colors.disabled);
					}
					updateScreen(question.Id, newValue);
				}}				
			>
				<ToggleButton style={{height:56, width:56}} icon='alpha-y-box' iconColor={yesColor} size={45} value="true" />
				<ToggleButton style={{height:56, width:56}} icon='alpha-n-box' iconColor={noColor} size={45} value="false" />
			</ToggleButton.Row>
			<Validation question={question} />
		</View>
	);
};

export const YesNoBasic = ({ question, answer, setAnswer }) => {
	const theme = useTheme();
	const [yesColor, setYesColor] = useState(answer === 'true' ? theme.colors.primary : theme.colors.disabled);
	const [noColor, setNoColor] = useState(answer === 'false' ? theme.colors.primary : theme.colors.disabled);
	
	return (
		<View style={styles.questionView}>
			<Text>{question}</Text>
			<ToggleButton.Row
				style={{marginTop: 5}}
				value={answer}
				onValueChange={(newValue) => {
					setAnswer(newValue);
					if (newValue === 'true') {
						setYesColor(theme.colors.primary);
						setNoColor(theme.colors.disabled);
						setAnswer(true);
					} else if (newValue ==='false') {
						setYesColor(theme.colors.disabled);
						setNoColor(theme.colors.primary);
						setAnswer(false);
					}
					else {
						setYesColor(theme.colors.disabled);
						setNoColor(theme.colors.disabled);
						setAnswer(false);
					}
				}}				
			>
				<ToggleButton style={{height:56, width:56}} icon='alpha-y-box' color={yesColor} size={45} value="true" />
				<ToggleButton style={{height:56, width:56}} icon='alpha-n-box' color={noColor} size={45} value="false" />
			</ToggleButton.Row>
		</View>
	);
};
