import React, { useContext, useState } from 'react';
import { View, ScrollView, StyleSheet, Keyboard, Platform } from 'react-native';
import {
	Button,
	Caption,
	HelperText,
	TextInput,
	Title
} from 'react-native-paper';
import { AppContext } from '~context/appContext';
import { V1Request } from '~utils/apiRequest';
import { WebContainer } from '~components/webContainer';
import BrandBackground from '~components/brandBackground';

export const PasswordReset = ({ navigation, route }) => {
	const app = useContext(AppContext);
	const [userName] = useState(route.params?.username ?? '');
	const [password] = useState('');
	const [token] = useState(route.params?.token ?? '');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [resetFail, setResetFail] = useState(false);
	const [resetFailMessage, setResetFailMessage] = useState('');

	const styles = StyleSheet.create({
		contentView: {
			flex: 1,
			justifyContent: 'flex-start',
			padding: 25
		},
		textInput: {
			marginTop: 10
		},
		header: { marginBottom: 15 },
		title: { fontSize: 24 },
		caption: { fontSize: 18 },
		inputView: {
			marginBottom: 15
		},
		submitButton: {
			marginTop: 15,
			marginBottom: 15
		},
		submitButtonText: {
			fontSize: 18,
			padding: 5
		},
		helperText: {
			marginLeft: 'auto',
			marginRight: 'auto',
			fontSize: 20
		},
		contentWeb: {
			maxWidth: '500px',
			margin: 'auto',
			marginTop: '20px'
		}
	});

	const handleSubmitPress = async () => {
		const response = await V1Request(
			'post',
			'resetpassword',
			null,
			`?userName=${userName}&password=${password}&token=${token}&newPassword=${newPassword}&confirmPassword=${confirmPassword}`,
			'json',
			app.setLoading
		);

		Keyboard.dismiss();

		if (response.status === 0) {
			setResetFail(false);
			app.setNotificationTitle('Oops, something went wrong.');
			app.setNotificationMsg('Please try again later');
			app.setNotificationVisible(true);
		} else if (response.status === 403) {
			setResetFailMessage('Access Denied.  Please contact your firm.');
			setResetFail(true);
		}

		if (response.data?.success) {
			setResetFail(false);
			navigation.push('Login');
		} else {
			setResetFailMessage(response.data?.error);
			setResetFail(true);
		}
	};

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<BrandBackground>
				<ScrollView>
					<WebContainer size={'sm'}>
						<View style={styles.contentWeb}>
							<View style={styles.contentView}>
								<View style={styles.header}>
									<Title style={styles.title}>Reset Password</Title>
									<Caption style={styles.caption}>
										Please enter and confirm your new password below.
									</Caption>
								</View>

								<View style={styles.inputView}>
									<TextInput
										secureTextEntry
										mode="outlined"
										label="Password"
										value={newPassword}
										onChangeText={(newPassword) => setNewPassword(newPassword)}
										style={styles.textInput}
									/>

									<TextInput
										secureTextEntry
										mode="outlined"
										label="Confirm Password"
										value={confirmPassword}
										onChangeText={(confirmPassword) =>
											setConfirmPassword(confirmPassword)
										}
										style={styles.textInput}
									/>
								</View>

								<Button
									mode="contained"
									onPress={(e) => handleSubmitPress(e)}
									style={styles.submitButton}
									labelStyle={styles.submitButtonText}
									uppercase={false}
								>
									Reset Password
								</Button>

								<HelperText
									type="error"
									visible={resetFail}
									style={styles.helperText}
								>
									{resetFailMessage}
								</HelperText>
							</View>
						</View>
					</WebContainer>
				</ScrollView>
			</BrandBackground>
		);
	}

	function MobileView() {
		return (
			<ScrollView keyboardShouldPersistTaps={'handled'}>
				<View style={styles.contentView}>
					<View style={styles.header}>
						<Title style={styles.title}>Reset Password</Title>
						<Caption style={styles.caption}>
							Please enter and confirm your new password below.
						</Caption>
					</View>

					<View style={styles.inputView}>
						<TextInput
							secureTextEntry
							mode="outlined"
							label="Password"
							value={newPassword}
							onChangeText={(newPassword) => setNewPassword(newPassword)}
							style={styles.textInput}
						/>

						<TextInput
							secureTextEntry
							mode="outlined"
							label="Confirm Password"
							value={confirmPassword}
							onChangeText={(confirmPassword) =>
								setConfirmPassword(confirmPassword)
							}
							style={styles.textInput}
						/>
					</View>

					<Button
						mode="contained"
						onPress={(e) => handleSubmitPress(e)}
						style={styles.submitButton}
						labelStyle={styles.submitButtonText}
						uppercase={false}
					>
						Reset Password
					</Button>

					<HelperText
						type="error"
						visible={resetFail}
						style={styles.helperText}
					>
						{resetFailMessage}
					</HelperText>
				</View>
			</ScrollView>
		);
	}
};
