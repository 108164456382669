import React from 'react';
import { StyleSheet, Image, Pressable, Linking } from 'react-native';
import Google from '@@images/0_shared/googlePlay.png';
import Apple from '@@images/0_shared/appleStore.png';

export const WebAppLinks = ({ firebaseAppleIsi, firebaseAndroidApn }) => {
	const styles = StyleSheet.create({
		googleLogo: {
			height: '65px',
			width: '220px',
			cursor: 'pointer',
			marginTop: 15,
			
		},
		appleLogo: {
			height: '65px',
			width: '193px',
			cursor: 'pointer',
			marginTop: 15,
			marginBottom: 15,
			marginLeft: 15
			
		}
	});

	return (
		<>
			<Pressable
				onPress={() => {
					Linking.openURL(
						`http://play.google.com/store/apps/details?id=${firebaseAndroidApn}`
					);
				}}
			>
				<Image resizeMode="center" source={Google} style={styles.googleLogo} />
			</Pressable>
			<Pressable
				onPress={() => {
					Linking.openURL(
						`https://apps.apple.com/us/app/id${firebaseAppleIsi}`
					);
				}}
			>
				<Image resizeMode="center" source={Apple} style={styles.appleLogo} />
			</Pressable>
		</>
	);
};
