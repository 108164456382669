import React, { useState } from 'react';
import { Keyboard, StyleSheet, TouchableHighlight, View } from 'react-native';
// prettier-ignore
import { Divider, Modal, Portal, Text, TextInput, useTheme } from 'react-native-paper';
import { Picker } from '@react-native-picker/picker';

export const DropdownIOS = ({ pickerItems, answer, handleValueChanged, placeholder, icon }) => {
	const theme = useTheme();
	const [pickerVisible, setPickerVisible] = useState(false);

	function open() {
		Keyboard.dismiss();
		setPickerVisible(true);
	}

	function close() {
		setPickerVisible(false);
	}

	const styles = StyleSheet.create({
		modalContent: {
			backgroundColor: theme.colors.surface,
			marginHorizontal: 10,
			borderRadius: 10
		},
		confirmView: {
			alignItems: 'center',
			justifyContent: 'center',
			height: 50,
			borderRadius: 10
		},
		confirmText: {
			color: theme.colors.primary,
			fontSize: 20,
			fontWeight: 'bold'
		},
		pickerItems: {
			color: theme.colors.text
		}
	});

	return (
		<>
			<TextInput
				right={
					<TextInput.Icon icon={icon ?? 'chevron-down'} color={theme.colors.primary} />
				}
				mode="outlined"
				showSoftInputOnFocus={false}
				placeholder={placeholder}
				onFocus={() => open()}
				value={answer}
			/>
			<Portal>
				<Modal
					visible={pickerVisible}
					transparent={true}
					presentationStyle="overFullScreen"
					onDismiss={() => close()}
					animationType="fade"
				>
					<View style={styles.modalContent}>
						<Picker
							itemStyle={styles.pickerItems}
							selectedValue={answer}
							onValueChange={(value) => handleValueChanged(value)}
						>
							{pickerItems}
						</Picker>
						<Divider />
						<TouchableHighlight
							style={styles.confirmView}
							onPress={() => close()}
						>
							<Text style={styles.confirmText}>Confirm</Text>
						</TouchableHighlight>
					</View>
				</Modal>
			</Portal>
		</>
	);
};