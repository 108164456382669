import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { ActivityIndicator, Headline, useTheme } from 'react-native-paper';

import { AppContext } from '~context/appContext';

export const Loading = () => {
	const app = useContext(AppContext);
	const theme = useTheme();

	const styles = StyleSheet.create({
		// eslint-disable-next-line react-native/no-unused-styles
		loadingView: {
			flex: 1,
			position: 'absolute',
			width: '100%',
			height: '100%',
			zIndex: 1,
			backgroundColor: theme.colors.semiTransparent
		},
		message: {
			marginTop: 25,
			color: theme.colors.primary
		},
		activityIndicator: {
			position: 'absolute',
			alignItems: 'center',
			justifyContent: 'center',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0
		}
	});

	if (app.loading) {
		return (
			<View style={styles.loadingView}>
				<View style={styles.activityIndicator}>
					<ActivityIndicator
						animating={app.loading}
						size={'large'}
						color={theme.colors.primary}
					/>
					<Headline style={styles.message}>{app.loadingMessage}</Headline>
				</View>
			</View>
		);
	} else return null;
};
