import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, TextInput } from 'react-native-paper';

const styles = StyleSheet.create({
	questionView: {
		marginVertical: 10
	}
});

export const RadioButton = ({ question, updateScreen }) => {
	const [answer, setAnswer] = useState(question.Answer);
	return (
		<View style={styles.questionView}>
			<Text>{question.Question}</Text>
			<TextInput
				mode="outlined"
				value={answer}
				onChangeText={(answer) => {
					setAnswer(answer);
					updateScreen(question.Id, answer);
				}}
			/>
		</View>
	);
};
