import React, { useContext, useState } from 'react';
import { Keyboard, View, ScrollView, StyleSheet, Platform } from 'react-native';
import { Button, Caption, TextInput, Title } from 'react-native-paper';

import { AppContext } from '~context/appContext';
import { V1Request } from '~utils/apiRequest';
import { WebContainer } from '~components/webContainer';
import BrandBackground from '~components/brandBackground';

export const PasswordResetEmail = ({ navigation }) => {
	const [email, setEmail] = useState('');
	const app = useContext(AppContext);

	const handleSendPress = async () => {
		const response = await V1Request(
			'get',
			'ResetPasswordEmail',
			null,
			`?userName=${email}`,
			'json',
			app.setLoading
		);

		// prettier-ignore
		if (response.status === 0) {
			Keyboard.dismiss();
			app.setNotificationTitle('Oops, something went wrong.');
			app.setNotificationMsg('Please try again later');
			app.setNotificationVisible(true);
		} 
		else if  ( response.data?.msg === 'Error sending email; if problem persists please contact a firm representative.') {
			app.setNotificationTitle('Oops, something went wrong.');
			app.setNotificationMsg('Please try again later');
			app.setNotificationVisible(true);
		} else {
			//If successful navigate to ResetSuccess Page
			navigation.navigate('PasswordResetEmailSuccess');
		}
	};

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<BrandBackground>
				<ScrollView>
					<WebContainer size={'sm'}>
						<View style={styles.contentViewWeb}>
							<View style={styles.contentView}>
								<View style={styles.header}>
									<Title style={styles.titleWeb}>Reset Password</Title>
									<Caption style={styles.captionWeb}>
										To reset your password, please enter the email address
										associated with your account.
									</Caption>
								</View>

								<View style={styles.inputView}>
									<TextInput
										autoCapitalize="none"
										mode="outlined"
										label="Email Address"
										keyboardType="email-address"
										value={email}
										onChangeText={(email) => setEmail(email)}
										style={styles.textInput}
									></TextInput>
								</View>

								<View style={styles.viewBtn}>
									<Button
										mode="contained"
										onPress={(e) => handleSendPress(e)}
										style={styles.submitButton}
										labelStyle={styles.submitButtonText}
										uppercase={false}
									>
										Send
									</Button>

									<Button
										mode="text"
										compact={true}
										onPress={() => {
											navigation.navigate('Login');
										}}
										style={styles.submitButton}
										labelStyle={styles.submitButtonText}
										uppercase={false}
									>
										Back to Log In Screen
									</Button>
								</View>
							</View>
						</View>
					</WebContainer>
				</ScrollView>
			</BrandBackground>
		);
	}

	function MobileView() {
		return (
			<ScrollView keyboardShouldPersistTaps={'handled'}>
				<View style={styles.contentView}>
					<View style={styles.header}>
						<Title style={styles.title}>Reset Password</Title>
						<Caption style={styles.caption}>
							To reset your password, please enter the email address associated
							with your account.
						</Caption>
					</View>

					<View style={styles.inputView}>
						<TextInput
							autoCapitalize="none"
							mode="outlined"
							label="Email Address"
							keyboardType="email-address"
							value={email}
							onChangeText={(email) => setEmail(email)}
							style={styles.textInput}
						></TextInput>
					</View>

					<View style={styles.viewBtn}>
						<Button
							mode="contained"
							onPress={(e) => handleSendPress(e)}
							style={styles.submitButton}
							labelStyle={styles.submitButtonText}
							uppercase={false}
						>
							Send
						</Button>

						<Button
							mode="text"
							compact={true}
							onPress={() => {
								navigation.navigate('Login');
							}}
							style={styles.submitButton}
							labelStyle={styles.submitButtonText}
							uppercase={false}
						>
							Back to Log In Screen
						</Button>
					</View>
				</View>
			</ScrollView>
		);
	}
};

const styles = StyleSheet.create({
	contentView: {
		flex: 1,
		justifyContent: 'flex-start',
		padding: 25
	},
	header: { marginBottom: 15 },
	title: { fontSize: 24 },
	caption: { fontSize: 18 },
	inputView: {
		marginBottom: 15
	},
	textInput: {
		marginTop: 10
	},
	submitButton: {
		marginTop: 15,
		marginBottom: 15
	},
	submitButtonText: {
		fontSize: 18,
		padding: 5
	},
	contentViewWeb: {
		maxWidth: '400px',
		margin: 'auto'
	},
	titleWeb: {
		fontSize: 24,
		fontWeight: 800,
		marginTop: '40px',
		textAlign: 'center'
	},
	captionWeb: {
		fontSize: 18,
		marginTop: '20px'
	}
});
