import React, { useEffect, useState } from 'react';
import { Keyboard, StyleSheet, View, Platform } from 'react-native';
import { Text, TextInput, useTheme } from 'react-native-paper';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import { Validation } from '~components/formInputs/validation';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

const styles = StyleSheet.create({
	questionView: {
		marginVertical: 10
	}
});

const fmt = 'MM/dd/yyyy hh:mm a';

export const DateTimePicker = ({ question, updateScreen }) => {
	const dateTimeToString = (date) => !date ? '' : format(date, fmt);

	const stringToDateTime = (date) => {
		const parsedDate = parse(date, fmt, 0);
		if (isValid(parsedDate)) return parsedDate;
		return null;
	};
	
	const [answer, setAnswer] = useState(stringToDateTime(question.Answer));
	const [datePickerVisibility, setDatePickerVisibility] = useState(false);
	const theme = useTheme();

	useEffect(() => {
		updateScreen(question.Id, dateTimeToString(answer));
	}, [answer]);

	function open() {
		Keyboard.dismiss();
		setDatePickerVisibility(true);
	}

	function close() {
		setDatePickerVisibility(false);
	}

	const handleConfirm = (date) => {
		setAnswer(new Date(date));
		close();
	};
	const ActivePicker = Platform.OS === 'web' ? WebDateTimePicker : DateTimePickerModal;
	return (
		<View style={styles.questionView}>
			<Text>{question.Question}</Text>
			<TextInput
				right={
					<TextInput.Icon icon="calendar-range" color={theme.colors.primary} />
				}
				mode="outlined"
				showSoftInputOnFocus={false}
				onFocus={() => open()}
				value={dateTimeToString(answer)}
			/>
			<ActivePicker
				isVisible={datePickerVisibility}
				mode="datetime"
				buttonTextColorIOS={theme.colors.primary}
				onConfirm={handleConfirm}
				onCancel={() => close()}
				date={answer ?? new Date()}
			/>
			<Validation question={question} />
		</View>
	);

	// eslint-disable-next-line no-unused-vars
	function WebDateTimePicker({ mode, isVisible, onCancel, onConfirm, date, ...props }) {
		const [datePart, setDatePart] = useState();
		if (!datePart) {
			return <DatePickerModal
				{...props}
				visible={isVisible}
				onConfirm={d => {
					setDatePart(d.date);
					// onConfirm(d.date);
				}}
				onDismiss={onCancel}
				mode='single'
				date={date}
			/>;
		} else {
			return <TimePickerModal
				{...props}
				visible={isVisible}
				hours={date?.getHours()}
				minutes={date?.getMinutes()}
				onConfirm={t => {
					datePart.setHours(t.hours);
					datePart.setMinutes(t.minutes);
					onConfirm(datePart);
				}}
				onDismiss={onCancel}
				mode='single'
			/>;
		}
	}
};
