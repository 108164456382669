import React, { useLayoutEffect } from 'react';
import {
	Platform,
	StyleSheet,
	View,
	ScrollView
} from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { HeaderBackButton } from '@react-navigation/elements';
import { Divider, Text, Title } from 'react-native-paper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import BrandBackground from '~components/brandBackground';
import { WebContainer } from '~components/webContainer';

import { DateToString} from '~utils/dateHelper';

export const TreatmentDetails=() => {
	
	const navigation = useNavigation();
	const route = useRoute();
		
	const { treatment } = route.params;
	
	const styles = StyleSheet.create({
		contentView: {
			flex: 1,
			justifyContent: 'flex-start',
			padding: 25,			
		},
		questionView: {
			marginVertical: 10
		},
		answerText: {
			fontFamily: 'proxima-nova-bold',
			marginVertical: 10,
			fontSize: 18,
			fontStyle: 'italic'
		},
		titleTextWeb: {
			textAlign: 'center'
		},
		divider: {
			marginTop: 15
		},
		formTopBar: {
			flexDirection: 'row',
			justifyContent: 'space-between',
		}
	});

	useLayoutEffect(() => {
		navigation.setOptions({
			headerTitle: 'Treatment Details'
		});
	}, [navigation]);

	const backButton = <HeaderBackButton
		onPress={() => {
			navigation.push('Case', { lawsuitId: route.params.lawsuitId,  tab: 3 });
		}}
	/>;

	function Questions() {
		return (
			<View style={styles.contentView}>
				<View style={styles.questionView}>
					<Text>What is the name of the medical provider who provided treatment?</Text>
					<Text style={styles.answerText}>
						{treatment.MedicalProviderName}
					</Text>
					<Divider />
				</View>
					
				{treatment.AddressLine1 &&
					(<View style={styles.questionView}>
						<Text>Address Line 1</Text>
						<Text style={styles.answerText}>
							{treatment.AddressLine1}
						</Text>
						<Divider />
					</View>)
				}

				{treatment.AddressLine2 &&
					(<View style={styles.questionView}>
						<Text>Address Line 2</Text>
						<Text style={styles.answerText}>
							{treatment.AddressLine2}
						</Text>
						<Divider />
					</View>)
				}

				{treatment.City &&
					(<View style={styles.questionView}>
						<Text>City</Text>
						<Text style={styles.answerText}>
							{treatment.City}
						</Text>
						<Divider />
					</View>)
				}

				{treatment.State &&
					(<View style={styles.questionView}>
						<Text>State</Text>
						<Text style={styles.answerText}>
							{treatment.State}
						</Text>
						<Divider />
					</View>)
				}

				{treatment.ZipCode &&
					(<View style={styles.questionView}>
						<Text>Zip Code</Text>
						<Text style={styles.answerText}>
							{treatment.ZipCode}
						</Text>
						<Divider />
					</View>)
				}
					
				<View style={styles.questionView}>
					<Text>What was the date of the treatment?</Text>
					<Text style={styles.answerText}>
						{DateToString(new Date(treatment.TreatmentDate))}
					</Text>
					<Divider />
				</View>

				<View style={styles.questionView}>
					<Text>Provide a description of the treatment.</Text>
					<Text style={styles.answerText}>
						{treatment.TreatmentNote}
					</Text>
					<Divider />
				</View>
			</View>
		);
	}

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<BrandBackground>
				<ScrollView>
					<WebContainer size="md">
						<View style={styles.formTopBar}>
							{backButton}
							<Title style={styles.titleTextWeb}>
								Treatment Details
							</Title>
							<Divider style={styles.divider} />
						</View>
						{Questions()}
					</WebContainer>
				</ScrollView>
			</BrandBackground>
		);
	}

	function MobileView() {
		return (
			<KeyboardAwareScrollView
				extraHeight={Platform.OS === 'android' ? 25 : 0}
				enableOnAndroid
				enableResetScrollToCoords={false}
			>
				{Questions()}
			</KeyboardAwareScrollView>
		);
	}
};
