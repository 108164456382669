import React, { useState } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { Text, TextInput } from 'react-native-paper';
import { Validation } from '~components/formInputs/validation';

const styles = StyleSheet.create({
	questionView: {
		marginVertical: 10
	},
});

export const TextArea = ({ question, updateScreen }) => {
	const [answer, setAnswer] = useState(question.Answer);
	
	const _handleChange = (e) => {
		e.target.style.height = 0;
		e.target.style.height = `${e.target.scrollHeight + 20}px`;
	};
	
	return <>
		{Platform.OS === 'web' ? WebView() : MobileView()}
	</>;

	function WebView(){
		return(
			<View style={styles.questionView}>
				<Text>{question.Question}</Text>
				<TextInput
					mode="outlined"
					multiline={true}
					numberOfLines={2}
					value={answer ?? ''}
					onChange={_handleChange}
					onChangeText={(answer) => {
						setAnswer(answer);
						updateScreen(question.Id, answer);
					}}
				/>
				<Validation question={question} />
			</View>
		);
	}

	function MobileView() {
		return(
			<View style={styles.questionView}>
				<Text>{question.Question}</Text>
				<TextInput
					mode="outlined"
					multiline={true}
					returnKeyType={'done'}
					returnKeyLabel={'done'}
					value={answer ?? ''}
					onChangeText={(answer) => {
						setAnswer(answer);
						updateScreen(question.Id, answer);
					}}
				/>
				<Validation question={question} />
			</View>
		);
	}
};

/// Used for non Case Forms
export const TextAreaBasic = ({ question, answer, setAnswer }) => {
	
	const _handleChange = (e) => {
		e.target.style.height = 0;
		e.target.style.height = `${e.target.scrollHeight + 20}px`;
	};
	
	return <>
		{Platform.OS === 'web' ? WebView() : MobileView()}
	</>;

	function WebView(){
		return(
			<View style={styles.questionView}>
				<Text>{question}</Text>
				<TextInput
					mode="outlined"
					multiline={true}
					numberOfLines={2}
					value={answer}
					onChange={_handleChange}
					onChangeText={(answer) => {
						setAnswer(answer);
					}}
				/>
			</View>
		);
	}

	function MobileView() {
		return(
			<View style={styles.questionView}>
				<Text>{question}</Text>
				<TextInput
					mode="outlined"
					multiline={true}
					numberOfLines={2}
					returnKeyType={'next'}
					returnKeyLabel={'next'}
					value={answer}
					onChangeText={(answer) => {
						setAnswer(answer);
					}}
				/>
			</View>
		);
	}
};

