import React, { useState } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { Text } from 'react-native-paper';
import { DropdownIOS } from './dropDownIOS';
import { DropdownAndroid } from './dropDownAndroid';
import { DropdownWeb } from './dropDownWeb';
import { Validation } from '~components/formInputs/validation';

const styles = StyleSheet.create({
	questionView: {
		marginVertical: 10
	}
});

export const DropDown = ({ question, updateScreen }) => {
	const [answer, setAnswer] = useState(question.Answer);

	const values = question.HelperData.DropDown;
	let pickerItems = <Picker.Item value="" key="" label="Select..." />;

	pickerItems = values?.map((obj, index) => {
		return <Picker.Item value={obj?.Value} key={index} label={obj?.Text} />;
	});

	const handleValueChanged = (value) => {
		setAnswer(value);
		updateScreen(question.Id, value);
	};

	return (
		<View style={styles.questionView}>
			<Text>{question.Question}</Text>
			{Platform.OS === 'android' ? (
				<DropdownAndroid
					pickerItems={pickerItems}
					answer={answer ?? ''}
					handleValueChanged={handleValueChanged}
				/>
			) : Platform.OS === 'web' ? (
				<DropdownWeb
					pickerItems={pickerItems}
					answer={answer ?? ''}
					handleValueChanged={handleValueChanged}
				/>
			) : (
				<DropdownIOS
					pickerItems={pickerItems}
					answer={answer ?? ''}
					handleValueChanged={handleValueChanged}
				/>
			)}
			<Validation question={question} />
		</View>
	);
};

export const DropDownBasic = ({ question, answer, setAnswer, values }) => {
	
	let pickerItems = values?.map((obj) => {
		return <Picker.Item value={obj.Name} key={obj.Id} label={obj.Name} />;
	});

	pickerItems.unshift(<Picker.Item value="" key={-1} label="" />);
	
	const handleValueChanged = (value) => {
		var existingMp = values.filter(v => {
			return v.Name === value;
		});
		if (existingMp.length > 0 || existingMp.Id === null) {
			setAnswer(existingMp[0]);
		} 
	};
	
	return (
		<View style={styles.questionView}>
			<Text>{question}</Text>
			{Platform.OS === 'android' ? (
				<DropdownAndroid
					pickerItems={pickerItems}
					answer={answer.Name}
					handleValueChanged={handleValueChanged}
				/>
			) : Platform.OS === 'web' ? (
				<DropdownWeb
					pickerItems={pickerItems}
					answer={answer.Name}
					handleValueChanged={handleValueChanged}
				/>
			) : (
				<DropdownIOS
					pickerItems={pickerItems}
					answer={answer.Name}
					handleValueChanged={handleValueChanged}
				/>
			)}
		</View>
	);
};
