import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import moment from 'moment';
import UserAvatar, {
	generateBackgroundColor,
	bgColors
} from '../../../../../modified_node_modules/react-native-user-avatar';
import { MessageBody } from '~screens/case/messages/partials/messageBody';

export const MessageItem = ({ message, avatar }) => {
	const { colors } = useTheme();

	const momentTime = moment(message.sent);
	const fmt = momentTime.isBefore(moment(), 'day') ? 'M/d/yy h:mm a' : 'h:mm a';
	
	const time = momentTime.format(fmt);

	const styles = StyleSheet.create({
		messageContainer: {
			marginLeft: 10,
			paddingVertical: 10,
			marginVertical: 5,
			flexDirection: 'row',
			userSelect: 'text'
		},
		sentMainContainer: {
			display: 'flex',
			flexDirection: 'row',
			maxWidth: '100%',
			justifyContent: 'flex-end'
		},
		avatar: {
			height: 40,
			alignSelf: 'flex-end'
		},
		bodyContainer: {
			maxWidth: '80%',
			borderRadius: 25,
			paddingHorizontal: 15,
			marginHorizontal: 10,
			paddingTop: 5,
			paddingBottom: 5,
			alignSelf: 'flex-start'
		},
		receivedBodyContaier: {
			backgroundColor: colors.surface
		},
		sentBodyContainer: {
			backgroundColor: colors.primary
		},
		nameText: {
			fontWeight: '700',
			fontSize: 14,
			color: generateBackgroundColor(message.name, null, bgColors)
		},
		bodyText: {
			alignSelf: 'flex-start',
			fontSize: 18
		},
		bodyReceivedText: {
			color: colors.messageReceivedText
		},
		bodySentText: {
			color: colors.surface
		},
		timeStampView: {
			alignSelf: 'flex-end'
		},
		timeStampText: {
			fontSize: 12
		},
		timeStampReceivedText: {
			color: colors.backdrop
		},
		timeStampSentText: {
			color: colors.lightGray
		},
	});

	const ReceivedMessage = (
		<View style={styles.messageContainer}>
			<UserAvatar style={styles.avatar} size={40} name={message.name} src={avatar} />
			<View style={[styles.bodyContainer, styles.receivedBodyContaier]}>
				<Text style={styles.nameText}>{message.name}</Text>
				<Text style={[styles.bodyText, styles.bodyReceivedText]}>
					<MessageBody messageBody={message.body} msgType={message.type} />
				</Text>
				<View style={styles.timeStampView}>
					<Text style={[styles.timeStampText, styles.timeStampReceivedText]}>
						{time}
					</Text>
				</View>
			</View>
		</View>
	);

	const SentMessage = (
		<View style={[styles.messageContainer, styles.sentMainContainer]}>
			<View style={[styles.bodyContainer, styles.sentBodyContainer]}>
				<Text style={[styles.bodyText, styles.bodySentText]}>
					<MessageBody messageBody={message.body} msgType={message.type} />
				</Text>
				<View style={styles.timeStampView}>
					<Text style={[styles.timeStampText, styles.timeStampSentText]}>
						{time}
					</Text>
				</View>
			</View>
		</View>
	);

	return (
		<View>{message.type === 'received' ? ReceivedMessage : SentMessage}</View>
	);
};
