import React from 'react';
import { Card, Paragraph } from 'react-native-paper';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useNavigation, useRoute } from '@react-navigation/native';

import { HoverCard } from '~components/hoverCard';
import { DateToString} from '~utils/dateHelper';

export const TreatmentItem = ({ treatment }) => {
	const navigation = useNavigation();
	const route = useRoute();
	
	const treatmentDate = new Date(treatment.TreatmentDate);
	const formatDate = DateToString(treatmentDate);

	const handlePress = () => {
		navigation.navigate('TreatmentDetails', {lawsuitId: route.params.lawsuitId, treatment: treatment});
	};

	return (
		<>
			<TouchableOpacity
				onPress={() => handlePress()}
			>
				<HoverCard>
					<Card.Title title={treatment.MedicalProviderName} subtitle={formatDate}/>
					<Card.Content>
						<Paragraph>{treatment.TreatmentNote}</Paragraph>
					</Card.Content>
				</HoverCard>
			</TouchableOpacity>
		</>		
	);
};
