import { AttachmentIcon, AttachmentIconColor } from '~components/formInputs/attachmentIcon';
import React, { useContext } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Text, useTheme } from 'react-native-paper';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { format, isValid, parseISO } from 'date-fns';
import * as Linking from 'expo-linking';

import { V1Request } from '~utils/apiRequest';
import { AppContext } from '~context/appContext';
import { HoverCard } from '~components/hoverCard';

export const FileItem = ({ file }) => {
	const app = useContext(AppContext);
	const theme = useTheme();

	const fmt = 'MM/dd/yyyy';
	const dateToString = (date) => (!date ? '' : format(date, fmt));

	const stringToDate = (date) => {
		const parsedDate = parseISO(date, fmt, 0);
		if (isValid(parsedDate)) return parsedDate;
		return null;
	};

	const handleDownloadFile = async (recordFileId) => {
		const response = await V1Request(
			'get',
			`GetFileUrl?recordFileId=${recordFileId}`,
			null,
			null,
			'json',
			app.setLoading
		);
		Linking.openURL(response.data);
	};

	const styles = StyleSheet.create({
		fileStatus: {
			fontSize: 16,
			letterSpacing: -0.35,
			color: theme.colors.placeholder,
			flex: Platform.OS === 'web' ? 2 : 1.5,
			textAlign: 'center'
		},
		fileInfo: {
			letterSpacing: -0.35,
			color: theme.colors.placeholder,
			flex: Platform.OS === 'web' ? 6 : 4
		},
		uploadedDate: {
			fontSize: 14,
			letterSpacing: -0.35,
			color: theme.colors.placeholder,
			textAlign: 'right',
			marginRight: 15
		},
		cardTextContainer: {
			flex: 1,
			flexDirection: 'row',
		},
		rightContainer: {
			flex: Platform.OS === 'web' ? 9 : 2,
			flexDirection: 'column'
		},
		detailsView: {
			marginTop: 15
		},
		fileUploadType: {
			fontSize: 15,
			color: theme.colors.placeholder
		}
	});
	return (
		<>
			<TouchableOpacity
				onPress={() => {
					handleDownloadFile(file.RecordFileId, file.FileName);
				}}
			>
				<HoverCard>
					<View style={styles.detailsView}>
						<View style={styles.cardTextContainer}>
							<Text style={styles.fileStatus}>
								<Icon
									name={AttachmentIcon(file.FileName || file.name)}
									color={AttachmentIconColor(file.FileName || file.name)}
									size={45}
									style={{ flex: 1 }}
								/>
							</Text>
							<View style={styles.fileInfo}>
								<Text style={{ fontSize: 15 }}>
									{file.FileName || file.name}
								</Text>
								<Text style={styles.fileUploadType}>
									{`File upload type: ${file.SubtypeName}`}
								</Text>
							</View>
							<View style={styles.rightContainer}>
								<Text style={styles.uploadedDate}>
									{dateToString(stringToDate(file.UploadedAt)) ||
										format(new Date(), fmt)}
									{/* Need to get upload date from backend instead? This is jsut a temp placeholder*/}
								</Text>
							</View>
						</View>
					</View>
				</HoverCard>
			</TouchableOpacity>
		</>
	);
};
