import React, { useContext, useEffect, useState } from 'react';
import {
	ImageBackground,
	Platform,
	StyleSheet,
	useWindowDimensions,
	View,
	ScrollView
} from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Text, useTheme } from 'react-native-paper';

import { AppContext } from '~context/appContext';
import { LoginRedirect } from '~utils/loginRedirect';
import { V1Request } from '~utils/apiRequest';

import bg_dark from '@@images/0_shared/generic_bg_dark.jpg';
import bg_light from '@@images/0_shared/generic_bg_light.jpg';

import { CalendarItem } from '~screens/case/calendar/partials/calendarItem';

export const Calendar = () => {
	const app = useContext(AppContext);
	const theme = useTheme();
	const navigation = useNavigation();
	const route = useRoute();
	const [appointments, setAppointments] = useState([]); // List of Medical Providers
	const [loaded, setLoaded] = useState(false);

	const [bg, setBg] = useState(
		theme.dark ? bg_dark : bg_light
	);

	useEffect(() => {
		setBg(theme.dark ? bg_dark : bg_light);
	}, [theme]);

	useEffect(() => {
		const loadData = async () => {
			setLoaded(true);
			const response = await V1Request(
				'get',
				'GetAppointments',
				null,
				`?lawsuitId=${route.params.lawsuitId}`,
				'json',
				app.setLoading
			);
			if (response.status === 401 || response.status === 403) {
				LoginRedirect(app, navigation);
				return;
			} else {
				setLoaded(true);
				setAppointments(response.data.appointments);
			}
		};
		loadData();
	},[]);


	const { height } = useWindowDimensions();

	const contentViewPadding = Platform.OS === 'web' ? 0 : 25;

	const styles = StyleSheet.create({
		backgroundImage: {
			flex: 1,
			justifyContent: 'center'
		},
		contentView: {
			flex: 1,
			justifyContent: 'flex-start',
			padding: contentViewPadding,
			paddingTop: 25
		},
		noAppointments: {
			fontSize: 28,
			margin: 28,
			textAlign: 'center',
			color: theme.colors.primary
		}
	});

	const stylesWeb = StyleSheet.create({
		appointmentContainer: {
			minHeight: 400,
			height: height - 360,
			marginBottom: -40,
		},
		contentView: {
			flex: 1,
			marginTop: 20
		}
	});

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<>
				<ScrollView style={stylesWeb.appointmentContainer}>
					<View style={stylesWeb.contentView}>
						{loaded && appointments.length == 0 && (
							<Text style={styles.noAppointments}>
								No appointments have been shared yet
							</Text>
						)}
						{appointments.map((a, index) => {
							return(<CalendarItem key={index} appointment={a} />);
						})}
					</View>				
				</ScrollView>
			</>
		);
	}

	function MobileView() {
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={bg}
			>
				<ScrollView>
					<View style={styles.contentView}>
						{loaded && appointments.length == 0 && (
							<Text style={styles.noAppointments}>
								No appointments have been shared yet
							</Text>
						)}
						{appointments.map((a, index) => {
							return(<CalendarItem key={index} appointment={a} />);
						})}
					</View>
				</ScrollView>
			</ImageBackground>
		);
	}
};
