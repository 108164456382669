import React, {useContext} from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { AppContext } from '~context/appContext';

//Case Pages
import { TermsOfService } from '~screens/case/termsOfService';
import { PreLogin } from '~screens/case/preLogin';
import { Login } from '~screens/case/login';
import { Register } from '~screens/case/register';
import { PasswordReset } from '~screens/case/passwordReset';
import { PasswordResetEmail } from '~screens/case/passwordResetEmail';
import { PasswordResetEmailSuccess } from '~screens/case/passwordResetEmailSuccess';
import { Cases } from '~screens/case/cases';
import { Case } from '~screens/case/case';
import { Form } from '~screens/case/forms/form';
import { FormReview } from '~screens/case/forms/formReview';
import { TreatmentForm } from '~screens/case/treatment/partials/treatmentForm';
import { TreatmentDetails } from '~screens/case/treatment/partials/treatmentDetails';

//Contact Page
import { Contact } from '~screens/contact/contact';

// Blog Pages
import { Blogs } from '~screens/blog/blogs';
import { Blog } from '~screens/blog/blog';

// About Page
import { About } from '~screens/about/about';

import { WebNavBar } from '~components/webNavBar';

const Stack = createStackNavigator();

export const WebStack = () => {
	const app = useContext(AppContext);
	const initialRouteName = app.auth?.loggedIn ? 'Cases' : 'PreLogin';
	return (
		<>
			<WebNavBar />
			<Stack.Navigator
				initialRouteName={initialRouteName}
				screenOptions={{
					headerShown: false
				}}
			>
				<Stack.Screen
					name="PreLogin"
					component={PreLogin}
					options={{ title: 'Welcome' }}
				/>
				<Stack.Screen
					name="TermsOfService"
					component={TermsOfService}
					options={{ title: 'Terms of Service' }}
				/>
				<Stack.Screen name="Register" component={Register} />
				<Stack.Screen name="Login" component={Login} />
				<Stack.Screen
					name="Cases"
					component={Cases}
					options={{ title: 'My Cases' }}
				/>
				<Stack.Screen name="Case" component={Case} options={{ title: 'Case' }} />
				<Stack.Screen
					name="Form"
					component={Form}
					options={{
						title: 'Form'
					}}
				/>
				<Stack.Screen
					name="FormReview"
					component={FormReview}
					options={{
						title: 'Review Form'
					}}
				/>
				<Stack.Screen
					name="TreatmentForm"
					component={TreatmentForm}
					options={{
						title: 'TreatmentForm'
					}}
				/>
				<Stack.Screen
					name="TreatmentDetails"
					component={TreatmentDetails}
					options={{
						title: 'TreatmentDetails'
					}}
				/>
				<Stack.Screen
					name="PasswordReset"
					component={PasswordReset}
					options={{
						title: 'Reset Password'
					}}
				/>
				<Stack.Screen
					name="PasswordResetEmail"
					component={PasswordResetEmail}
					options={{
						title: 'Password Reset'
					}}
				/>
				<Stack.Screen
					name="PasswordResetEmailSuccess"
					component={PasswordResetEmailSuccess}
					options={{
						title: 'Password Reset'
					}}
				/>

				<Stack.Screen name="Contact" component={Contact} />

				<Stack.Screen name="Blogs" component={Blogs} />
				<Stack.Screen name="Blog" component={Blog} />

				<Stack.Screen name="About" component={About} />
			</Stack.Navigator>
		</>
	);
};
