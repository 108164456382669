import React from 'react';
import { StyleSheet, TouchableOpacity, View, Platform } from 'react-native';
import { Card, useTheme, Text } from 'react-native-paper';
import call from 'react-native-phone-call';

import LoginSvg from '@@icons/ic-login.svg';
import CallSvg from '@@icons/ic-call-us.svg';
import { useLocalData } from '~utils/localStorage';
import BrandBackground from '~components/brandBackground';
import { WebContainer } from '~components/webContainer';
import { ScrollView } from 'react-native-gesture-handler';
import { WebAppLinks } from '~components/webAppLinks';

const LoginCardTitle = 'Log in to my case';
const LoginCardBody = 'Everything you need to know about your ongoing case.';
const CallCardTitle = 'Give us a call';
const CallCardBody = 'Think you have a potential case? Speak to our team now.';

// eslint-disable-next-line no-unused-vars
export const PreLogin = ({ route, navigation }) => {
	const theme = useTheme();
	const settingsData = useLocalData('settingsData', {});
	
	const styles = StyleSheet.create({
		contentView: {
			flex: 1,
			justifyContent: 'flex-start',
			padding: 25
		},
		card: {
			padding: 5,
			borderRadius: 12,
			backgroundColor: theme.colors.tabBackground,
			marginBottom: 25
		},
		cardIcon: {
			marginLeft: 15,
			marginTop: 15
		},
		cardSubtitle: {
			fontSize: 16,
			letterSpacing: -0.35
		},
		introTitle: {
			fontSize: 44,
			color: theme.colors.surface,
			margin: '20px'
		},
		introSub: {
			fontSize: 20,
			fontFamily: 'proxima-nova-light',
			color: theme.colors.surface,
			margin: '20px',
			marginTop: '0px'
		}
	});

	const stylesWeb = StyleSheet.create({
		welcomeContainerWeb: {
			flex: 1
		},
		cardContainerWeb: {
			flex: 1,
			flexDirection: 'row',
			justifyContent: 'center',
			flexWrap: 'wrap',
			marginTop: '40px'
		},
		cardWeb: {
			flex: 1,
			padding: 5,
			borderRadius: 12,
			backgroundColor: theme.colors.tabBackground,
			margin: 20,
			marginBottom: 25,
			cursor: 'pointer',
			minWidth: '250px'
		},
		storeLinks: {
			flexDirection: 'row',
			justifyContent: 'flex-end',
			marginRight: 20,
			flexWrap: 'wrap'
		},
		cardSubtitleWeb: {
			fontSize: 16,
			letterSpacing: -0.35,
			marginBottom: '30px',
			minHeight: 45
		}
	});

	const callOptions = {
		number: settingsData?.FirmContactPhoneNumber ?? '', // String value with the number to call
		prompt: true, // Optional boolean property. Determines if the user should be prompted prior to the call
		skipCanOpen: true // Skip the canOpenURL check
	};

	return <>{Platform.OS === 'web' ? <WebView /> : <MobileView />}</>;

	function WebView() {
		return (
			<BrandBackground>
				<ScrollView>
					<WebContainer color={'transparent'}>
						<View style={stylesWeb.welcomeContainerWeb}>
							<Text style={styles.introTitle}>Welcome!</Text>
							<Text style={styles.introSub}>
								Thanks for downloading the {settingsData?.FirmName ?? ''} App.
								This app will help you enter and manage the details of your case
								in order to get you the largest settlement as fast as possible.
								All responses are 100% confidential and won’t be shared with
								outside organizations.{' '}
							</Text>
							<View style={stylesWeb.cardContainerWeb}>
								<Card
									style={stylesWeb.cardWeb}
									onPress={() => navigation.navigate('Login')}
								>
									<LoginSvg
										fill={theme.colors.text}
										highlight={theme.colors.primary}
										width={35}
										height={35}
										style={styles.cardIcon}
									/>
									<Card.Title
										title={LoginCardTitle}
										subtitle={LoginCardBody}
										subtitleNumberOfLines={4}
										subtitleStyle={stylesWeb.cardSubtitleWeb}
									/>
								</Card>
								<Card
									style={stylesWeb.cardWeb}
									onPress={() => call(callOptions).catch(console.error)}
								>
									<CallSvg
										fill={theme.colors.text}
										highlight={theme.colors.primary}
										width={35}
										height={35}
										style={styles.cardIcon}
									/>
									<Card.Title
										title={settingsData?.FirmContactPhoneNumber ?? ''}
										subtitle={CallCardBody}
										subtitleNumberOfLines={4}
										subtitleStyle={stylesWeb.cardSubtitleWeb}
									/>
								</Card>
							</View>
							<View style={stylesWeb.storeLinks}>
								<WebAppLinks
									firebaseAndroidApn={settingsData.Firebase_Android_Apn}
									firebaseAppleIsi={settingsData.Firebase_Apple_Isi}
								/>
							</View>
						</View>
					</WebContainer>
				</ScrollView>
			</BrandBackground>
		);
	}

	function MobileView() {
		return (
			<BrandBackground>
				<View style={styles.contentView}>
					<TouchableOpacity onPress={() => navigation.navigate('Login')}>
						<Card style={styles.card}>
							<LoginSvg
								fill={theme.colors.text}
								highlight={theme.colors.primary}
								width={35}
								height={35}
								style={styles.cardIcon}
							/>
							<Card.Title
								title={LoginCardTitle}
								subtitle={LoginCardBody}
								subtitleNumberOfLines={2}
								subtitleStyle={styles.cardSubtitle}
							/>
						</Card>
					</TouchableOpacity>

					<TouchableOpacity>
						<Card
							style={styles.card}
							onPress={() => call(callOptions).catch(console.error)}
						>
							<CallSvg
								fill={theme.colors.text}
								highlight={theme.colors.primary}
								width={35}
								height={35}
								style={styles.cardIcon}
							/>
							<Card.Title
								title={CallCardTitle}
								subtitle={CallCardBody}
								subtitleNumberOfLines={2}
								subtitleStyle={styles.cardSubtitle}
							/>
						</Card>
					</TouchableOpacity>
				</View>
			</BrandBackground>
		);
	}
};
