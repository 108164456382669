import AsyncStorage from '@react-native-async-storage/async-storage';
import { useState, useEffect } from 'react';
import { DeviceEventEmitter } from 'react-native';
const eventPrefix = 'LocalStorage-';

export const useLocalData = (key, initialState, cb) => {
	const [data, setData] = useState(initialState);
	const refresh = () => GetLocalData(key).then(async d => {
		if (typeof cb === 'function') d = await cb(d);
		setData(d);
	});
	useEffect(() => {
		refresh();
		const subscription = DeviceEventEmitter.addListener(eventPrefix + key, (...a) => {
			refresh();
		});
		return () => subscription.remove();
	}, []);
	return data;
};

export const GetLocalData = async (key) => {
	try {
		const jsonValue = await AsyncStorage.getItem(key);
		return jsonValue != null ? JSON.parse(jsonValue) : null;
	} catch (e) {
		// error reading value
		console.log(e);
	}
};

export const SetLocalData = async (key, value) => {
	try {
		const jsonValue = JSON.stringify(value);
		await AsyncStorage.setItem(key, jsonValue);
		DeviceEventEmitter.emit(eventPrefix + key);
	} catch (e) {
		// saving error
	}
};