import 'expo-dev-client';
import 'react-native-gesture-handler';
import '@expo/match-media';

import React, {
	useCallback,
	useRef,
	useState,
	useEffect,
	useMemo, 
} from 'react';
import { Platform, useColorScheme, View } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider as PaperProvider } from 'react-native-paper';
import {
	NavigationContainer,
	useNavigationContainerRef,
	createNavigationContainerRef
} from '@react-navigation/native';
import * as SplashScreen from 'expo-splash-screen';
import * as Linking from 'expo-linking';

import { DefaultTheme, DarkTheme } from './theme';
import DrawerNavigator from '~navigators/drawer/drawerNavigator';
import { WebStack } from '~navigators/stacks/webStack';
import { getPathFromState } from '@react-navigation/native';
import { AppContext } from '~context/appContext';
import { CaseContext } from '~context/caseContext';
import useFonts from '~hooks/useFonts';
import useDataRefresh from '~hooks/useDataRefresh';
import useExpoPushTokenRefresh from '~hooks/useExpoPushTokenRefresh';
import { GetAuth } from '~utils/auth';
import { ProcessLink } from '~utils/deepLink';
import { Loading } from '~components/loading';
import { Notification } from '~components/notification';
import { Brand } from '~utils/brand';
import { en, registerTranslation } from 'react-native-paper-dates';
import { GetLocalData, SetLocalData } from '~utils/localStorage';

import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';

global.__reanimatedWorkletInit = () => {};

export const navigationRef = createNavigationContainerRef();

Notifications.setNotificationHandler({
	handleNotification: async () => ({
		shouldShowAlert: true,
		shouldPlaySound: false,
		shouldSetBadge: false
	})
});

async function registerForPushNotificationsAsync() {
	let token;
	if (Device.isDevice) {
		const { status: existingStatus } =
			await Notifications.getPermissionsAsync();
		let finalStatus = existingStatus;
		if (existingStatus !== 'granted') {
			const { status } = await Notifications.requestPermissionsAsync();
			finalStatus = status;
		}
		const pushNotificationAlertDisplayed = await GetLocalData('PushNotificationAlertDisplayed');
		if (finalStatus !== 'granted' && !pushNotificationAlertDisplayed) {			
			alert('Push Notifications are disabled.  Please go to your settings and enable push notifications to receive messages and updates about your case.');
			SetLocalData('PushNotificationAlertDisplayed', true);
			return;
		}
		token = (await Notifications.getExpoPushTokenAsync()).data;
	} else {
		alert('Must use physical device for Push Notifications');
	}
	
	if (Platform.OS === 'android') {
		Notifications.setNotificationChannelAsync('default', {
			name: 'default',
			importance: Notifications.AndroidImportance.MAX,
			vibrationPattern: [0, 250, 250, 250],
			lightColor: '#FF231F7C'
		});
	}	
	return token;
}

export default function App() {
	const navigationRef = useNavigationContainerRef();
	const colorScheme = useColorScheme();
	const [isThemeDark, setIsThemeDark] = useState(
		colorScheme === 'dark' && Platform.OS !== 'web' ? true : false
	);
	const [auth, setAuth] = useState({});
	const [agreedTerms, setAgreedTerms] = useState(false);
	const [brand, setBrand] = useState(Brand());
	const [lastException, setLastException] = useState({});
	const [loading, setLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [notificationVisible, setNotificationVisible] = useState(false);
	const [appIsReady, setAppIsReady] = useState(false);
	const [expoPushToken, setExpoPushToken] = useState('');
	
	// Case Context Values
	const [lawsuitId, setLawsuitId] = useState();
	const [caseName, setCaseName] = useState();
	const [client, setClient] = useState();
	const [formsBadge, setFormsBadge] = useState(false);
	const [messagesBadge, setMessagesBadge] = useState(false);
	const [filesBadge, setFilesBadge] = useState(false);
	const [treatmentsBadge, setTreatmentsBadge] = useState(false);
	const [caseProgress, setCaseProgress] = useState([]);
	const [initialRoute, setInitialRoute] = useState();
	const [activeRoute, setActiveRoute] = useState();
	
	const [notification, setNotification] = useState(false);
	const notificationListener = useRef();
	const responseListener = useRef();
	
	
	const toggleTheme = () => setIsThemeDark((t) => !t);
	
	let deepLink = undefined;
	const link = Linking.useURL();
	if (link) {
		deepLink = ProcessLink(link);
	}
	
	const app = useMemo(
		() => ({
			agreedTerms,
			setAgreedTerms,
			auth,
			setAuth,
			brand,
			setBrand,
			lastException,
			setLastException,
			loading,
			setLoading,
			loadingMessage,
			setLoadingMessage,
			toggleTheme,
			isThemeDark,
			setNotificationVisible,
			notificationVisible,
			expoPushToken,
			setExpoPushToken,
			navigationRef
		}),
		[
			agreedTerms,
			setAgreedTerms,
			auth,
			setAuth,
			brand,
			setBrand,
			lastException,
			setLastException,
			loading,
			setLoading,
			loadingMessage,
			setLoadingMessage,
			toggleTheme,
			isThemeDark,
			setNotificationVisible,
			notificationVisible,
			expoPushToken,
			setExpoPushToken,
			navigationRef
		]
	);
		
	const _case = useMemo(
		() => ({
			lawsuitId,
			setLawsuitId,
			caseName,
			setCaseName,
			client,
			setClient,
			caseProgress,
			setCaseProgress,
			initialRoute,
			setInitialRoute,
			formsBadge,
			setFormsBadge,
			messagesBadge,
			setMessagesBadge,
			filesBadge,
			setFilesBadge,
			treatmentsBadge,
			setTreatmentsBadge,
			activeRoute,
			setActiveRoute
		}),
		[
			lawsuitId,
			setLawsuitId,
			caseName,
			setCaseName,
			client,
			setClient,
			caseProgress,
			setCaseProgress,
			initialRoute,
			setInitialRoute,
			formsBadge,
			setFormsBadge,
			messagesBadge,
			setMessagesBadge,
			filesBadge,
			setFilesBadge,
			treatmentsBadge,
			setTreatmentsBadge,
			activeRoute,
			setActiveRoute
		]
	);

	let theme = isThemeDark ? DarkTheme : DefaultTheme;
		
	const handleOpenURL = async (event) => {
		let deepLink = ProcessLink(event.url);
		if (deepLink)
			navigationRef.navigate('CaseTab', {
				screen: deepLink.page,
				params: deepLink.params
			});
	};

		
	useEffect(() => {
		async function prepare() {
			try {
				const tempAuth = await GetAuth();
				setAuth(tempAuth);
				await SplashScreen.preventAutoHideAsync();
				await useDataRefresh();
				registerTranslation('en', en);
				await useFonts();

				if (Platform.OS !== 'web') {
					// Push Notifications listeners
					registerForPushNotificationsAsync().then((token) => {
						setExpoPushToken(token);
						if (tempAuth?.loggedIn) {
							useExpoPushTokenRefresh(token);
						}
					});

					notificationListener.current =
						Notifications.addNotificationReceivedListener((notification) => {
							setNotification(notification);
						});

					responseListener.current =
						Notifications.addNotificationResponseReceivedListener(
							(response) => {
								if (navigationRef.isReady()){
									navigationRef.navigate('Redirect', {
										data: response.notification.request.content.data
									}
									);
								}else{
									null;
								}
							}
						);
				}
			} catch (e) {
				console.warn(e);
			} finally {
				setAppIsReady(true);
				Linking.addEventListener('url', handleOpenURL);
			}
		}
		prepare();
		if (Platform.OS !== 'web') {
			return () => {
				Notifications.removeNotificationSubscription(
					notificationListener.current
				);
				Notifications.removeNotificationSubscription(responseListener.current);
			};
		}
	}, []);

	const onLayoutRootView = useCallback(async () => {
		if (appIsReady) {
			// This tells the splash screen to hide immediately! If we call this after
			// `setAppIsReady`, then we may see a blank screen while the app is
			// loading its initial state and rendering its first pixels. So instead,
			// we hide the splash screen once we know the root view has already
			// performed layout.
			await SplashScreen.hideAsync();
		}
	}, [appIsReady]);

	if (!appIsReady) {
		return null;
	}

	const RootNavigator = Platform.OS === 'web' ? WebStack : DrawerNavigator;

	// Routing for web
	const config = {
		screens: {
			PreLogin: `${brand}/case/welcome`,
			Login: `${brand}/case/login`,
			Cases: `${brand}/cases`,
			Case: `${brand}/case/:lawsuitId`,
			Form: `${brand}/case/:lawsuitId/form/:clientFormId/page/:screenId?`,
			FormReview: `${brand}/case/:lawsuitId/form-review/:clientFormId`,
			TreatmentForm: `${brand}/case/:lawsuitId/treatment-form`,
			TreatmentDetails: `${brand}/case/:lawsuitId/treatment-details`,
			PasswordReset: `${brand}/case/password-reset`,
			PasswordResetEmail: `${brand}/case/password-reset-email`,
			Contact: `${brand}/contact`,
			Blogs: `${brand}/blogs`,
			Blog: {
				path: `${brand}/blog/:id`
			},
			About: `${brand}/about`,
			Register: `${brand}/case/sign-up`
		}
	};

	const linking = {
		config,
		getPathFromState(state, config) {
			let path = getPathFromState(state, config);
			const index = path.indexOf('?');
			if (index >= 0) {
				path = path.substr(0, index);
			}
			return path;
		}
	};

	return (
		<AppContext.Provider value={app}>
			<CaseContext.Provider value={_case}>
				<SafeAreaProvider>
					<PaperProvider theme={theme}>
						<Loading />
						<Notification />
						<View onLayout={onLayoutRootView} />
						<StatusBar style={isThemeDark ? 'light' : 'dark'} />
						<NavigationContainer
							ref={navigationRef}
							linking={linking}
							theme={theme}
							onReady={() => {
								if (deepLink)
									navigationRef.navigate(deepLink.page, deepLink.params);
							}}
						>
							<RootNavigator />
						</NavigationContainer>
					</PaperProvider>
				</SafeAreaProvider>
			</CaseContext.Provider>
		</AppContext.Provider>
	);
}
