import React, { useContext, useState } from 'react';
import { View, Platform, StyleSheet } from 'react-native';
import {
	Checkbox,
	useTheme,
	Modal,
	Portal,
	Button,
	Text
} from 'react-native-paper';
import { WebView } from 'react-native-webview';
import { AppContext } from '~context/appContext';

import Android_EULA from '@@eula/android-eula';
import IOS_EULA from '@@eula/ios-eula';

const EULA = Platform.OS === 'android' ? Android_EULA : IOS_EULA;

export const TermsOfService = ({ navigation }) => {
	const [visible, setVisible] = useState(true);
	const app = useContext(AppContext);
	const [agree, setAgree] = useState(false);
	const theme = useTheme();

	const styles = StyleSheet.create({
		modal: {
			flex: 1,
			backgroundColor: theme.colors.surface,
			padding: 10
		},
		webview: {
			flex: 0,
			flexGrow: 20
		},
		wrapperView: {
			minHeight: '100%'
		},
		checkview: {
			flexDirection: 'row',
			alignItems: 'center',
			alignSelf: 'center'
		},
		checktext: {
			fontFamily: 'proxima-nova-bold',
			fontSize: 16
		}
		
	});

	const handleContinue = () => {
		app.setAgreedTerms(true);
		setVisible(false);
		navigation.navigate('Register');
	};

	return <>{Platform.OS === 'web' ? null : MobileView()}</>;

	function MobileView(){
		return (
			<Portal>
				<Modal style={styles.modal} visible={visible}>
					<View style={styles.wrapperView}>
						<WebView
							style={styles.webview}
							visible={true}
							originWhitelist={['*']}
							source={{ html: EULA }}
						/>
						<View style={styles.checkview}>
							<Checkbox.Android
								color={theme.colors.primary}
								uncheckedColor={theme.colors.primary}
								status={agree ? 'checked' : ''}
								onPress={() => setAgree((a) => !a)}
							/>
							<Text style={styles.checktext}>I Agree</Text>
						</View>
						<Button disabled={!agree} mode="contained" onPress={() => handleContinue()}>
						Continue
						</Button>
					</View>
				</Modal>
			</Portal>
		);
	}
};
