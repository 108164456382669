import React, { useContext, useEffect, useState } from 'react';
import {
	ImageBackground,
	Platform,
	StyleSheet,
	useWindowDimensions,
	View,
	ScrollView
} from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { FAB, Text, useTheme } from 'react-native-paper';

import { AppContext } from '~context/appContext';
import { LoginRedirect } from '~utils/loginRedirect';
import { V1Request } from '~utils/apiRequest';

import bg_dark from '@@images/0_shared/generic_bg_dark.jpg';
import bg_light from '@@images/0_shared/generic_bg_light.jpg';
import { TreatmentItem } from '~screens/case/treatment/partials/treatmentItem';


export const Treatment = () => {
	const app = useContext(AppContext);
	const theme = useTheme();
	const navigation = useNavigation();
	const route = useRoute();
	const [mpts, setMpts] = useState([]); // List of Medical Providers and treatments combined
	const [mps, setMps] = useState([]); // List of Medical Providers
	const [loaded, setLoaded] = useState(false);

	const [bg, setBg] = useState(
		theme.dark ? bg_dark : bg_light
	);

	useEffect(() => {
		setBg(theme.dark ? bg_dark : bg_light);
	}, [theme]);

	useEffect(() => {
		const loadData = async () => {
			const response = await V1Request(
				'get',
				'GetMedicalProviderTreatments',
				null,
				`?lawsuitId=${route.params.lawsuitId}`,
				'json',
				app.setLoading
			);
			if (response.status === 401 || response.status === 403) {
				LoginRedirect(app, navigation);
				return;
			} else {
				setLoaded(true);
				setMpts(response.data.mpts ? response.data.mpts : [] );
				response.data.mps.unshift({Id: null, Name: 'Add a New Medical Provider'});
				setMps(response.data.mps ? response.data.mps : [] );
			}
		};
		loadData();
	},[]);


	const { height } = useWindowDimensions();

	const contentViewPadding = Platform.OS === 'web' ? 0 : 25;

	const styles = StyleSheet.create({
		backgroundImage: {
			flex: 1,
			justifyContent: 'center'
		},
		contentView: {
			flex: 1,
			justifyContent: 'flex-start',
			padding: contentViewPadding,
			paddingTop: 25
		},
		fab: {
			backgroundColor: theme.colors.primary,
			position: 'absolute',
			margin: 16,
			right: 0,
			bottom: 0,
			zIndex: 1000
		},
		noNotes: {
			fontSize: 28,
			margin: 28,
			textAlign: 'center',
			color: theme.colors.primary
		}
	});

	const stylesWeb = StyleSheet.create({
		treatmentContainer: {
			minHeight: 400,
			height: height - 360,
			marginBottom: -40,
		},
		contentView: {
			flex: 1,
			marginTop: 20
		}
	});

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<>
				<FAB
					icon="clipboard-plus-outline"
					style={styles.fab}
					onPress={() => {
						navigation.push('TreatmentForm', {lawsuitId: route.params.lawsuitId, mps: mps});
					}}
				/>
				<ScrollView style={stylesWeb.treatmentContainer}>
					<View style={stylesWeb.contentView}>
						{loaded && mpts.length == 0 && (
							<Text style={styles.noNotes}>
								Treatment notes that you add will be displayed here.
							</Text>
						)}
						{mpts.map((t, index) => {
							return(<TreatmentItem key={index} treatment={t} />);
						})}
					</View>				
				</ScrollView>
			</>
		);
	}

	function MobileView() {
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={bg}
			>
				<ScrollView>
					<View style={styles.contentView}>
						{loaded && mpts.length === 0 && (
							<Text style={styles.noNotes}>
								Treatment notes that you add will be displayed here.
							</Text>
						)}
						{mpts.map((t, index) => {
							return(<TreatmentItem key={index} treatment={t} />);
						})}
					</View>
				
				</ScrollView>
				<FAB
					icon="clipboard-plus-outline"
					style={styles.fab}
					onPress={() => {
						navigation.push('TreatmentForm', {lawsuitId: route.params.lawsuitId, mps: mps });
					}}
				/>			
			</ImageBackground>
		);
	}
};
