import React, { useContext, useLayoutEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { AppContext } from '~context/appContext';

import { Cases } from '~screens/case/cases';
import { Case } from '~screens/case/case';
import { Form } from '~screens/case/forms/form';
import { FormReview } from '~screens/case/forms/formReview';
import { LogoTitle } from '~components/logoTitle';
import { HeaderRight } from '~components/headerRight';
import { HeaderLeft } from '~components/headerLeft';
import { TreatmentForm } from '~screens/case/treatment/partials/treatmentForm';
import { TreatmentDetails } from '~screens/case/treatment/partials/treatmentDetails';

const Stack = createStackNavigator();

export const CaseStack = ({ navigation, route }) => {
	const app = useContext(AppContext);
	const initialRouteName = app.auth?.loggedIn ? 'Cases' : 'PreLogin';

	useLayoutEffect(() => {
		navigation.setOptions({ headerShown: false});
	}, [navigation, route]);

	return (
		<Stack.Navigator
			initialRouteName={initialRouteName}
			screenOptions={{
				headerTitle: () => <LogoTitle />,
				headerTitleAlign: 'center',
				headerRight: () => <HeaderRight />,
				headerMode: 'float',
				headerBackTitleVisible: false,
				headerBackVisible: false
			}}
		>
			<Stack.Screen
				name="Cases"
				component={Cases}
				options={{
					title: 'My Cases',
					headerLeft: () => <HeaderLeft screen="BlogsTab" />
				}}
			/>
			<Stack.Screen
				name="Case"
				component={Case}
				options={{
					headerLeft: () => <HeaderLeft screen="Cases" />
				}}
			/>
			<Stack.Screen
				name="Form"
				component={Form}
				options={{
					title: 'Form',
				}}
			/>
			<Stack.Screen
				name="FormReview"
				component={FormReview}
				options={{
					title: 'Review Form',
				}}
			/>
			<Stack.Screen
				name="TreatmentForm"
				component={TreatmentForm}
				options={{
					title: 'Treatment Form',
				}}
			/>
			<Stack.Screen
				name="TreatmentDetails"
				component={TreatmentDetails}
				options={{
					title: 'Treatment Details',
				}}
			/>
		</Stack.Navigator>
	);
};
