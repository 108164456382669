import { useMediaQuery } from 'react-responsive';

const useMQ = () => {
	
	// These values are the Bootstrap media queries sizes/names
	const xs = true;
	const sm = useMediaQuery({ query: '(min-width: 576px)' });
	const md = useMediaQuery({ query: '(min-width: 768px)' });
	const lg = useMediaQuery({ query: '(min-width: 992px)' });
	const xl = useMediaQuery({ query: '(min-width: 1200px)' });

	const portrait = useMediaQuery({ query: '(orientation: portrait)' });
	const landscape = useMediaQuery({ query: '(orientation: portrait)' });

	const mq = { xs, sm, md, lg, xl, portrait, landscape };	
	return mq;
};

export default useMQ;
