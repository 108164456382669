import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';

import AttorneyListItem from './attorneyListItem';

const AttorneyList = ({ attorneys }) => {
	if (!attorneys || Object.keys(attorneys)?.length === 0) {
		return null;
	}

	return <>{Platform.OS === 'web' ? <WebView /> : <MobileView />}</>;

	function WebView() {
		return (
			<View style={styles.attorneyListContainer}>
				{attorneys.map((attorney) => {
					return <AttorneyListItem key={attorney.PartyId} attorney={attorney} />;
				})}
			</View>
		);
	}
	
	function MobileView() {
		return (
			<>
				{attorneys.map((attorney) => {
					return <AttorneyListItem key={attorney.PartyId} attorney={attorney} />;
				})}
			</>
		);
	}
};

const styles = StyleSheet.create({
	attorneyListContainer: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start',
		flexWrap: 'wrap'
	}
});

export default AttorneyList;
