import * as React from 'react';
import { StyleSheet } from 'react-native';
import { FAB, useTheme } from 'react-native-paper';
import call from 'react-native-phone-call';

const CallNow = ({number}) => {
	const { colors } = useTheme();

	const args = {
		number: number, // String value with the number to call
		prompt: true, // Optional boolean property. Determines if the user should be prompted prior to the call
		skipCanOpen: true, // Skip the canOpenURL check
	};

	const styles = StyleSheet.create({
		fab: {
			position: 'absolute',
			margin: 16,
			right: 0,
			bottom: 0,
			backgroundColor: colors.primary,
		},
	});

	return (
		<FAB
			icon="phone"
			label="  Call Now"
			style={styles.fab}
			onPress={() => {
				call(args).catch(console.error);
			}}
		/>
	);
};

export default CallNow;
