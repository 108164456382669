import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, TextInput, useTheme } from 'react-native-paper';
import { Validation } from '~components/formInputs/validation';

const styles = StyleSheet.create({
	questionView: {
		marginVertical: 10
	}
});

export const CurrencyField = ({ question, updateScreen }) => {
	const [answer, setAnswer] = useState(question.Answer);
	const theme = useTheme();

	const onChangeTextInput = (text) => {
		const numericRegex = /^(?!0\d)\d{1,8}(?:\.\d{1,2})?$/;
		const numericRegex2 = /^$|^\d+(\.)$/;

		if (text.slice(0, text.length - 1) === '0') setAnswer(text.slice(text.length - 1));

		if (numericRegex.test(text) || numericRegex2.test(text)) {
			setAnswer(text);
		}
	};

	return (
		<View style={styles.questionView}>
			<Text>{question.Question}</Text>
			<TextInput
				left={
					<TextInput.Icon icon="currency-usd" color={theme.colors.primary} />
				}
				returnKeyType={'done'}
				mode="outlined"
				value={answer}
				keyboardType={'numeric'}
				onChangeText={(answer) => {
					onChangeTextInput(answer);
					// setAnswer(answer);
					updateScreen(question.Id, answer);
				}}
			/>
			<Validation question={question} />
		</View>
	);
};
