import React, { useContext, useEffect, useRef, useState } from 'react';
import {
	Animated,
	Easing,
	ImageBackground,
	Keyboard,
	Platform,
	StyleSheet,
	useWindowDimensions,
	View
} from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useTheme } from 'react-native-paper';
import update from 'immutability-helper';

import useInterval from '~hooks/useInterval';
import { AppContext } from '~context/appContext';
import { CaseContext } from '~context/caseContext';
import { ApiRequest, V1Request } from '~utils/apiRequest';
import { LoginRedirect } from '~utils/loginRedirect';

import { MessageList } from '~screens/case/messages/partials/messageList';
import { MessageResponse } from '~screens/case/messages/partials/messageResponse';

import bg_dark from '@@images/0_shared/generic_bg_dark.jpg';
import bg_light from '@@images/0_shared/generic_bg_light.jpg';

const inactiveInterval = 60000; // Check for messages every 60 seconds when messages tab is inactive
const activeInterval = 10000; // Check for messages every 10 seconds when messages tab is active

const useMessageState = (init) => {
	const [messages, _setMessages] = useState(init);
	const setMessages = (mNew) =>
		_setMessages((mOld) => {
			const m = typeof mNew === 'function' ? mNew(mOld) : mNew;
			const mFinal = m
				.sort((a, b) => b.id - a.id) // re-sort descending
				.filter((x, i, a) => x.id !== a[i - 1]?.id); // remove duplicates
			return mFinal;
		});
	return [messages, setMessages];
};

const responseViewHeight = Platform.OS === 'ios' ? 60 : 65;

export const Messages = () => {
	const theme = useTheme();
	const navigation = useNavigation();
	const route = useRoute();
	const { height } = useWindowDimensions();
	const translateAnim = useRef(new Animated.Value(0)).current;
	const app = useContext(AppContext);
	const _case = useContext(CaseContext);

	const [bg, setBg] = useState(
		theme.dark ? bg_dark : bg_light
	);

	const [messages, setMessages] = useMessageState([]);
	const [lastId, setLastId] = useState(0);
	const [messageSending, setMessageSending] = useState(false);
	const [sendersAvatars, setSendersAvatars] = useState([]);

	//Interval Refresh Messages
	useInterval(() => {
		if (_case.activeRoute === 'messages' && !messageSending){
			_case.setMessagesBadge(false);
			loadMessages(lastId, 10, 0, true); //loads 20 due to interval, don't think more than that can be sent between intervals
		} 
	}, activeInterval);

	//Interval Refresh Messages
	useInterval(() => {
		if (_case.activeRoute !== 'messages' && !messageSending){
			loadMessages(lastId, 10, 0, false); // Don't clear unread messages if tab isn't active
		} 
	}, inactiveInterval);

	useEffect(() => {
		setBg(theme.dark ? bg_dark : bg_light);
	}, [theme]);

	// Keyboard Stuff
	useEffect(() => {
		// Keyboard listeners for moving view when keyboard visibility changes
		const showSubscription = Keyboard.addListener('keyboardWillShow', (e) => {
			Animated.timing(translateAnim, {
				toValue: -(e.endCoordinates.height - 80),
				duration: 200,
				easing: Easing.ease,
				useNativeDriver: true
			}).start();
		});

		const hideSubscription = Keyboard.addListener('keyboardWillHide', () => {
			Animated.timing(translateAnim, {
				toValue: 0,
				duration: 100,
				easing: Easing.ease,
				useNativeDriver: true
			}).start();
		});

		return () => {
			showSubscription.remove();
			hideSubscription.remove();
		};
	}, []);

	const loadMessages = async (lastId, top, skip, clearUnread = true) => {
		const response = await V1Request(
			'get',
			`GetSubMessages?lawsuitId=${route.params.lawsuitId}&top=${top}&skip=${skip}&lastId=${lastId}&clearUnread=${clearUnread}`,
			null,
			null,
			'json'
		);
		if (response.status === 401 || response.status === 403) {
			LoginRedirect(app, navigation);
			return;
		}

		if (response.data.includes('<!DOCTYPE html>')) return;

		
		if (response.data?.length > 0) {
			if (lastId === 0) {
				setMessages(response.data);
			} else {
				if (response.data[0].id > lastId) {
					setMessages((m) => {
						if (
							m[m.length - 1].id >= response.data[response.data.length - 1].id
						)
							return m;
						return update(m, { $unshift: response.data });
					});
					_case.setMessagesBadge(true);
				}
			}
			setLastId(response.data[0].id);
			let arr = [];
			response.data.forEach(m => {
				if(m.userId)
					arr.push(m.userId);
			});
			arr = arr.filter((value, index) => arr.indexOf(value) === index);
			const responseImage = await ApiRequest('post', 'PublicImage/AttorneyListingImages', arr, '', 'json', app.setLoading);
			if (!responseImage.isAxiosError) {
				setSendersAvatars((prevSendersAvatars) => [...prevSendersAvatars, ...responseImage.data].filter((a, index) => [...prevSendersAvatars, ...responseImage.data].indexOf(a) === index));
			}
		}
	};

	const GetUnreadMessageCount = async () => {
		const response = await V1Request(
			'get',
			`GetUnreadMessageCount/${route.params.lawsuitId}`,
			null,
			null,
			'json',
			app.setLoading
		);
		if (response.status === 401 || response.status === 403) {
			LoginRedirect(app, navigation);
			return;
		} else if (response.data.includes !== '<!DOCTYPE html>') {
			if (response.data > 0) {
				_case.setMessagesBadge(true);
			} else {
				_case.setMessagesBadge(false);
			}
		}
	};

	useEffect(() => {
		const loadData = async () => {
			GetUnreadMessageCount();
			await loadMessages(0, 10, 0, true);
		};
		loadData();
	},[]);

	const styles = StyleSheet.create({
		messagesContainer: {
			height: '100%',
			// backgroundColor: theme.colors.surface,
			transform: [{ translateY: translateAnim }, { perspective: 1000 }]
		},
		messageListView: {
			height: '100%',
		},
		responseView: {
			flexDirection: 'row',
			width: '100%',
			height: responseViewHeight,
			position: 'absolute',
			bottom: 0,
			right: 0,
			backgroundColor: theme.colors.messageSendBox
		}
	});

	const stylesWeb = StyleSheet.create({
		messagesContainer: {
			minHeight: 400,
			height: height - 360,
			marginBottom: 0
		}
	});

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<View style={stylesWeb.messagesContainer}>
				<MessageList messages={messages} setMessages={setMessages} sendersAvatars={sendersAvatars} />
				<MessageResponse
					lawsuitId={route.params.lawsuitId}
					messages={messages}
					setMessages={setMessages}
					lastId={lastId}
					setLastId={setLastId}
					setMessageSending={setMessageSending}
				/>
			</View>
		);
	}

	function MobileView() {
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={bg}>
				<Animated.View style={styles.messagesContainer}>
					<View style={styles.messageListView}>
						<MessageList messages={messages} setMessages={setMessages} sendersAvatars={sendersAvatars} />
					</View>

					<View style={styles.responseView}>
						<MessageResponse
							lawsuitId={route.params.lawsuitId}
							messages={messages}
							setMessages={setMessages}
							lastId={lastId}
							setLastId={setLastId}
							setMessageSending={setMessageSending}
						/>
					</View>
				</Animated.View>
			</ImageBackground>
		);
	}
};
