import React, { useContext } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Text, useTheme } from 'react-native-paper';
import { format, isValid, parseISO } from 'date-fns';
import IconDocusign from '@@icons/docusign.svg';
import { HoverCard } from '~components/hoverCard';
import { useNavigation } from '@react-navigation/native';
import { V1Request } from '~utils/apiRequest';
import { AppContext } from '~context/appContext';

export const DocusignItem = ({ file }) => {
	const app = useContext(AppContext);
	const navigation = useNavigation();
	const theme = useTheme();

	const fmt = 'MM/dd/yyyy';
	const dateToString = (date) => (!date ? '' : format(date, fmt));

	const stringToDate = (date) => {
		const parsedDate = parseISO(date, fmt, 0);
		if (isValid(parsedDate)) return parsedDate;
		return null;
	};

	const handleSignFile = async () => {
		const response = await V1Request('get', 'GetRecipientViewUrl', null, `?recordId=${file.RecordId}&userEmail=${app.auth.email}`, 'json', null);
		if (response) {
			const link = response.data.Url;
			if (Platform.OS === 'web') window.open(link, '_blank');
			else navigation.navigate('CPWebView', { link: link});
		}
	};

	const styles = StyleSheet.create({
		fileStatus: {
			flex: Platform.OS === 'web' ? 2 : 1.5,
			height: 46,
			display: 'flex',
			justifyContent: 'space-around',
			alignItems: 'center'
		},
		fileInfo: {
			letterSpacing: -0.35,
			color: theme.colors.placeholder,
			flex: Platform.OS === 'web' ? 6 : 4
		},
		uploadedDate: {
			fontSize: 14,
			letterSpacing: -0.35,
			color: theme.colors.placeholder,
			textAlign: 'right',
			marginRight: 15
		},
		cardTextContainer: {
			flex: 1,
			flexDirection: 'row',
		},
		rightContainer: {
			flex: Platform.OS === 'web' ? 9 : 2,
			flexDirection: 'column'
		},
		detailsView: {
			marginTop: 15
		},
		fileUploadType: {
			fontSize: 15,
			color: theme.colors.placeholder
		},
		iconWrapper: {
			overflow: 'hidden',
			borderRadius: 4
		}
	});
	return (
		<>
			<TouchableOpacity
				onPress={() => {
					handleSignFile();
				}}
			>
				<HoverCard>
					<View style={styles.detailsView}>
						<View style={styles.cardTextContainer}>
							<View style={styles.fileStatus}>
								<View style={styles.iconWrapper}>
									<IconDocusign style={{ borderRadius: 4, border: '3px black' }}/>
								</View>
							</View>
							<View style={styles.fileInfo}>
								<Text style={{ fontSize: 15 }}>
									{file.FileName || file.RecordName}
								</Text>
								<Text style={styles.fileUploadType}>
									Signature Requested
								</Text>
							</View>
							<View style={styles.rightContainer}>
								<Text style={styles.uploadedDate}>
									{dateToString(stringToDate(file.UploadedAt)) ||
										format(new Date(), fmt)}
								</Text>
							</View>
						</View>
					</View>
				</HoverCard>
			</TouchableOpacity>
		</>
	);
};
