import React from 'react';
import cpdirect from '@@images/0_shared/background.jpg';
import powrog from '@@images/0_shared/background.jpg';
import flg from '@@images/0_shared/background.jpg';
import mmlf from '@@images/0_shared/background.jpg';
import klaw from '@@images/0_shared/background.jpg';
import flaw from '@@images/0_shared/background.jpg';
import cfs from '@@images/0_shared/background.jpg';
import slaw from '@@images/0_shared/background.jpg';
import mmdc from '@@images/0_shared/background.jpg';
import ksbr from '@@images/0_shared/background.jpg';
import vanstone from '@@images/0_shared/background.jpg';
import { Brand } from '~utils/brand';
import { ImageBackground, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
	backgroundImage: {
		flex: 1,
		justifyContent: 'center'
	}
});

const BrandBackground = ({ children }) => {
	switch (Brand()) {
	case 'cpdirect':
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={cpdirect}
			>
				{children}
			</ImageBackground>
		);
	case 'powrog':
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={powrog}
			>
				{children}
			</ImageBackground>
		);
	case 'flg':
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={flg}
			>
				{children}
			</ImageBackground>
		);
	case 'mmlf':
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={mmlf}
			>
				{children}
			</ImageBackground>
		);
	case 'klaw':
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={klaw}
			>
				{children}
			</ImageBackground>
		);
	case 'flaw':
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={flaw}
			>
				{children}
			</ImageBackground>
		);
	case 'cfs':
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={cfs}
			>
				{children}
			</ImageBackground>
		);
	case 'slaw':
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={slaw}
			>
				{children}
			</ImageBackground>
		);
	case 'mmdc':
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={mmdc}
			>
				{children}
			</ImageBackground>
		);
	case 'ksbr':
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={slaw}
			>
				{children}
			</ImageBackground>
		);
	case 'vanstone':
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={slaw}
			>
				{children}
			</ImageBackground>
		);
	default:
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={ksbr}
			>
				{children}
			</ImageBackground>
		);
	}
};

export default BrandBackground;
