import React from 'react';
import { StyleSheet, View } from 'react-native';
import useMQ from '~hooks/useMQ';

export const WebContainer = ({ children, size = 'normal', color = '#ffffff', paddingBottom = 45  }) => {
	const mq = useMQ();
	
	let containerWidth = '80%';
	let containerWidth_md = '90%';
	let containerWidth_sm = '100%';

	if (size === 'sm') {
		containerWidth = '500px';
		containerWidth_md = '500px';
		containerWidth_sm = '100%';
	}

	if (size === 'md') {
		containerWidth = '750px';
		containerWidth_md = '750px';
		containerWidth_sm = '100%';
	}

	const webContainer_sm = {
		width: containerWidth_sm,
		margin: 0,
		marginTop: 0,
		marginBottom: 0,
		borderRadius: 0
	};

	const webContainer_md = {
		width: containerWidth_md,
		marginTop: 40,
		marginBottom: 40
	};

	const styles = StyleSheet.create({
		webContainer: {
			width: containerWidth,
			maxWidth: 1024,
			backgroundColor: color,
			border: '1px solid ' +  color,
			borderRadius: 15,
			margin: 'auto',
			marginTop: 40,
			marginBottom: 40,
			padding: 45,
			paddingBottom: paddingBottom,
			...(!mq.lg ? webContainer_md : null), // If at least md
			...(!mq.md ? webContainer_sm : null) // If at least sm
		}
	});

	return <View style={styles.webContainer}>{children}</View>;
};