import React from 'react';
import { Button } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

export const HeaderRight = () => {
	const navigation  = useNavigation();
	return (
		<Button
			icon={'menu'}
			labelStyle={{ fontSize: 30, marginLeft:-10 }}
			onPress={() => {
				
				navigation.getParent('root').openDrawer();
			}}
		/>
	);
};
