import React from 'react';
import Autolink from 'react-native-autolink';
import {  useTheme } from 'react-native-paper';


export const MessageBody = ({ messageBody, msgType }) => {

	const theme = useTheme();

	const SentHyperlink = (
		<Autolink 
			text={messageBody}
			linkStyle={{ color: theme.colors.hyperlink2, textDecorationLine: 'underline' }}
		/>
	);

	const ReceivedHyperlink = (
		<Autolink 
			text={messageBody}
			linkStyle={{ color: theme.colors.hyperlink, textDecorationLine: 'underline' }}
		/>
	);

	return (
		<>
			{msgType === 'received' ? ReceivedHyperlink : SentHyperlink}
		</>
	
	);
};
