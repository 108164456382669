import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, Checkbox, useTheme } from 'react-native-paper';
import { Validation } from '~components/formInputs/validation';

const styles = StyleSheet.create({
	questionView: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-start',
		marginVertical: 10,
		marginLeft: -5,
		marginRight: 35
	},
	questionText: {
		textAlign: 'left',
		marginLeft: 10,
		marginTop: 8
	}
});

export const CheckboxForm = ({ question, updateScreen, disabled = false }) => {
	const [answer, setAnswer] = useState(question.Answer);
	const [formatedQuestion, setFormatedQuestion] = useState(false);
	const theme = useTheme();

	// To remove excess spaces in questions and format correctly
	useEffect(() => {
		const format = () => {
			const reformat = question.Question.replace(/\s\s+/g, ' ');
			setFormatedQuestion(reformat);
		};
		format();
	}, []);

	return (
		<>
			<View style={styles.questionView}>
				<Checkbox.Android
					disabled={disabled}
					uncheckedColor={theme.colors.primary}
					color={theme.colors.primary}
					status={answer ? 'checked' : 'unchecked'}
					onPress={() => {
						const newAnswer = !answer;
						setAnswer(newAnswer);
						updateScreen(question.Id, newAnswer);
					}}
				/>
				<Text style={styles.questionText}>{formatedQuestion}</Text>
			</View>
			<Validation question={question} />
		</>
	);
};
