import { merge } from 'lodash';
import { Brand } from '~utils/brand';

const brands = {
	cpdirect: {
		light: {
			colors: {
				primary: '#825FE8',
				primaryLight: '#E3DBFA',
				hyperlink: '#825FE8',
				hyperlink2: '#ffffff'
			}
		},
		dark: {
			colors: {
				primary: '#825FE8',
				primaryLight: '#E3DBFA',
				hyperlink: '#825FE8',
				hyperlink2: '#ffffff'

			}
		}
	},
	flg: {
		light: {
			colors: {
				primary: '#A59468',
				primaryLight: '#d6c584',
				hyperlink: '#1b4984',
				hyperlink2: '#ffffff'
			}
		},
		dark: {
			colors: {
				primary: '#CCB44C',
				primaryLight: '#FFEA9E',
				hyperlink: '#a59468',
				hyperlink2: '#ffffff'
			}
		}
	},
	powrog: {
		light: {
			colors: {
				primary: '#AD0317',
				primaryLight: '#C9A139',
				hyperlink: '#AD0317',
				hyperlink2: '#ffffff'
			}
		},
		dark: {
			colors: {
				primary: '#AD0317',
				primaryLight: '#C9A139',
				hyperlink: '#EF0424',
				hyperlink2: '#ffffff'
			}
		}
	},
	flaw: {
		light: {
			colors: {
				primary: '#B49D75',
				primaryLight: '#B29B74',
				hyperlink: '#B49D75',
				hyperlink2: '#ffffff'
			}
		},
		dark: {
			colors: {
				primary: '#B49D75',
				primaryLight: '#B29B74',
				hyperlink: '#B49D75',
				hyperlink2: '#ffffff'
			}
		}
	},
	mmlf: {
		light: {
			colors: {
				primary: '#c30202',
				primaryLight: '#db4e4e',
				hyperlink: '#c30202',
				hyperlink2: '#ffffff'
			}
		},
		dark: {
			colors: {
				primary: '#c30202',
				primaryLight: '#db4e4e',
				hyperlink: '#e51919',
				hyperlink2: '#ffffff'
			}
		}
	},
	klaw: {
		light: {
			colors: {
				primary: '#003f78',
				primaryLight: '#E3DBFA',
				hyperlink: '#003f78',
				hyperlink2: '#ffffff'
			}
		},
		dark: {
			colors: {
				primary: '#0359a7', //#003f78
				primaryLight: '#E3DBFA',
				hyperlink: '#003f78',
				hyperlink2: '#ffffff'
			}
		}
	},
	cfs: {
		light: {
			colors: {
				primary: '#012A62',
				primaryLight: '#4081d6',
				hyperlink: '#0663d6',
				hyperlink2: '#ffffff'
			}
		},
		dark: {
			colors: {
				primary: '#2d62a3',
				primaryLight: '#5c98e6',
				hyperlink: '#2d62a3',
				hyperlink2: '#ffffff'
			}
		}
	},
	slaw: {
		light: {
			colors: {
				primary: '#041b2a',
				primaryLight: '#c7b37a',
				hyperlink: '#041b2a'
			}
		},
		dark: {
			colors: {
				primary: '#c7b37a',
				primaryLight: '#c7b37a',
				hyperlink: '#c7b37a'
			
			}
		}
	},	
	mmdc: {
		light: {
			colors: {
				primary: '#02387F',
				primaryLight: '#0571FF',
				hyperlink: '#02387F'
			}
		},
		dark: {
			colors: {
				primary: '#0347A0',
				primaryLight: '#0571FF',
				hyperlink: '#0347A0'

			}
		}
	},
	ksbr: {
		light: {
			colors: {
				primary: '#30405F',
				primaryLight: '#546FA5',
				hyperlink: '#30405F',
				hyperlink2: '#ffffff'
			}
		},
		dark: {
			colors: {
				primary: '#546FA5',
				primaryLight: '#546FA5',
				hyperlink: '#546FA5',
				hyperlink2: '#ffffff'

			}
		}
	},
	vanstone: {
		light: {
			colors: {
				primary: '#38BC69',
				primaryLight: '#41DB7A',
				hyperlink: '#38BC69',
				hyperlink2: '#ffffff'
			}
		},
		dark: {
			colors: {
				primary: '#38BC69',
				primaryLight: '#41DB7A',
				hyperlink: '#38BC69',
				hyperlink2: '#ffffff'
			}
		}
	},
	wieand: {
		light: {
			colors: {
				primary: '#131F40',
				primaryLight: '#131F40',
				hyperlink: '#FF3043',
				hyperlink2: '#ffffff'
			}
		},
		dark: {
			colors: {
				primary: '#f7001c',
				primaryLight: '#f7001c',
				hyperlink: '#FF3043',
				hyperlink2: '#ffffff'
			}
		}
	},
};

const brandLight = brands[Brand()].light;
const brandDark = brands[Brand()].dark;

const defaultShared = {	
	version: 2,
	animation: {
		scale: 1
	},
	colors: {
		primary: '#825FE8',
		primaryLight: '#E3DBFA',
		accent: '#525361',
		background: 'rgb(242, 242, 242)',
		surface: '#ffffff',
		error: '#B00020',
		text: 'rgb(28, 28, 30)',
		onSurface: '#000000',
		disabled: 'rgba(0, 0, 0, 0.26)',
		placeholder: 'rgba(0, 0, 0, 0.54)',
		backdrop: 'rgba(0, 0, 0, 0.5)',
		notification: 'rgb(255, 59, 48)',
		card: 'rgb(255, 255, 255)',
		border: 'rgb(216, 216, 216)',
		tabBackground: '#F9F7F5',
		tabCompleteBackground: 'grey',
		semiTransparent: 'rgba(0, 0, 0, .75)',
		transparent: 'rgba(0, 0, 0, 0)',
		hyperlink: '#825FE8',
		lightGray: '#F0F0F0',
		messageSendBox: 'rgba(255, 255, 255, .95)',
		messageInputBox: 'rgba(0, 0, 0, 0.1)',
		messageSentText: '#FFFFFF',
		messageReceivedText: '#000000'
	},
	dark: false,
	fonts: {
		light: {
			fontFamily: 'proxima-nova-light',
			fontWeight: 'normal'
		},
		medium: {
			fontFamily: 'proxima-nova-bold',
			fontWeight: 'normal'
		},
		regular: {
			fontFamily: 'proxima-nova-regular',
			fontWeight: 'normal'
		},
		thin: {
			fontFamily: 'proxima-nova-thin',
			fontWeight: 'normal'
		}
	},
	roundness: 4
};
const darkShared = {
	version: 2,
	animation: {
		scale: 1
	},
	colors: {
		primary: '#825FE8',
		primaryLight: '#E3DBFA',
		accent: '#525361',
		background: '#303443',
		surface: '#121212',
		error: '#CF6679',
		text: 'rgb(229, 229, 231)',
		onSurface: '#FFFFFF',
		disabled: 'rgba(255, 255, 255, 0.38)',
		placeholder: 'rgba(255, 255, 255, 0.54)',
		backdrop: 'rgba(0, 0, 0, 0.5)',
		notification: 'rgb(255, 69, 58)',
		card: 'rgb(18, 18, 18)',
		border: 'rgb(39, 39, 41)',
		tabBackground: '#1B1D26',
		tabCompleteBackground: 'grey',
		semiTransparent: 'rgba(0, 0, 0, .75)',
		transparent: 'rgba(0, 0, 0, 0)',
		hyperlink: '#825FE8',
		lightGray: '#F0F0F0',
		messageSendBox: 'rgba(0, 0, 0, 0.95)',
		messageInputBox: '#303443',
		messageSentText: '#ffffff',
		messageReceivedText: 'rgb(229, 229, 231)'
	},
	dark: true,
	fonts: {
		light: {
			fontFamily: 'proxima-nova-light',
			fontWeight: 'normal'
		},
		medium: {
			fontFamily: 'proxima-nova-bold',
			fontWeight: 'normal'
		},
		regular: {
			fontFamily: 'proxima-nova-regular',
			fontWeight: 'normal'
		},
		thin: {
			fontFamily: 'proxima-nova-thin',
			fontWeight: 'normal'
		}
	},
	roundness: 4
};

export const DefaultTheme = merge(defaultShared, brandLight);
export const DarkTheme = merge(darkShared, brandDark);
