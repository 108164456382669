import React, { useContext } from 'react';
import { LogBox } from 'react-native';
import { createMaterialTopTabNavigator as createTabNavigator } from '@react-navigation/material-top-tabs';
import { useTheme } from 'react-native-paper';
import { AppContext } from '~context/appContext';

import { AuthStack } from '~navigators/stacks/authStack';
import { Blogs } from '~screens/blog/blogs';
import { Contact } from '~screens/contact/contact';
import { About } from '~screens/about/about';

import IconCase from '@@icons/case.svg';
import IconContact from '@@icons/contact.svg';
import IconAbout from '@@icons/about.svg';
import IconBlog from '@@icons/blog.svg';

const Tab = createTabNavigator();

export const BottomTabNavigator = ({navigation}) => {
	LogBox.ignoreLogs(['onAnimatedValueUpdate']); // Ignore log notification by message
	const theme = useTheme();
	const app = useContext(AppContext);
	
	return (
		<>
			<Tab.Navigator
				tabBarPosition="bottom"
				keyboardDismissMode="on-drag"
				screenOptions={{
					tabBarActiveTintColor: theme.colors.primary,
					tabBarInactiveTintColor: theme.colors.text,
					tabBarLabelStyle: { fontSize: 14 },
					tabBarItemStyle: { padding: 3, paddingTop: 7, paddingBottom: 10 },
					tabBarShowLabel: true,
					tabBarShowIcon: true,
					tabBarIndicatorStyle: { backgroundColor: theme.colors.primary },
					tabBarPressColor: theme.dark
						? theme.colors.accent
						: theme.colors.primaryLight,
					tabBarPressOpacity: 0.9
				}}
			>
				<Tab.Screen
					id='CaseTab'
					name='CaseTab'
					component={AuthStack}
					options={{
						lazy: true,
						title: 'Case',
						tabBarShowIcon: true,
						tabBarIcon: ({ focused }) => {
							return (
								<IconCase
									fill={focused ? theme.colors.primary : theme.colors.accent}
								/>
							);
						}
					}}
					listeners={{
						tabPress: (e) => {
							// Skip to CaseStack if already logged in
							if (app.auth?.loggedIn){
								e.preventDefault();
								navigation.navigate('CasesDrawerNavigator');
							}
						},
						focus: () => {
							// Skip to CaseStack if already logged in
							if (app.auth?.loggedIn){
								navigation.navigate('CasesDrawerNavigator');
							}
						},
					}}
				/>
				<Tab.Screen
					name="BlogsTab"
					component={Blogs}
					options={{
						title: 'Blogs',
						tabBarShowIcon: true,
						tabBarIcon: ({ focused }) => {
							return (
								<IconBlog
									fill={focused ? theme.colors.primary : theme.colors.accent}
								/>
							);
						}
					}}
				/>
				<Tab.Screen
					name="ContactTab"
					component={Contact}
					options={{
						title: 'Contact',
						tabBarShowIcon: true,
						tabBarIcon: ({ focused }) => {
							return (
								<IconContact
									fill={focused ? theme.colors.primary : theme.colors.accent}
								/>
							);
						}
					}}
				/>
				<Tab.Screen
					name="AboutTab"
					component={About}
					options={{
						title: 'About',
						tabBarShowIcon: true,
						tabBarIcon: ({ focused }) => {
							return (
								<IconAbout
									fill={focused ? theme.colors.primary : theme.colors.accent}
								/>
							);
						}
					}}
				/>
			</Tab.Navigator>
		</>
	);
};