import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Divider, Text } from 'react-native-paper';
import { CheckboxForm } from '~components/formInputs/checkbox';
import { FilesList, parseFileInfo } from '~components/formInputs/attachment';

const styles = StyleSheet.create({
	questionView: {
		marginVertical: 10
	},
	answerText: {
		fontFamily: 'proxima-nova-bold',
		marginVertical: 10,
		fontSize: 18,
		fontStyle: 'italic'
	}
});

const types = {
	textField: 1,
	textArea: 2,
	checkbox: 3,
	currencyField: 4,
	datePicker: 5,
	dateTimePicker: 6,
	radioButton: 7,
	dropDown: 8,
	yesNo: 9,
	attachment: 10,
};

export const Review = ({ question }) => {
	if (question.ComponentId === types.checkbox) {
		return checkbox();
	} else if (question.ComponentId === types.attachment) {
		const files = question.FormattedAnswer?.map(x => parseFileInfo(x));
		return (
			<>
				<Text>{question.Question}</Text>
				<FilesList files={files} />
				<Divider />
			</>
		);
	} else {
		return (
			<View style={styles.questionView}>
				<Text>{question.Question}</Text>
				<Text style={styles.answerText}>
					{question.FormattedAnswer || question.Answer}
				</Text>
				<Divider />
			</View>
		);
	}

	function checkbox() {
		return (
			<View>
				<CheckboxForm key={question.Id} style={styles.checkboxQuestion} question={question} disabled={true} />
				<Divider />
			</View>
		);
	}
};
