import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import * as SecureStore from '~utils/secureStore';

// Adapted from: https://stackoverflow.com/questions/46863644/expo-get-unique-device-id-without-ejecting/68553627#68553627
export const Uuid = async () => {
	let fetchUUID = undefined;
	try {
		fetchUUID = await SecureStore.getItemAsync('secure_deviceid');
	} catch {
		// An error occurred trying getItemAsync.  This seems to happen sometimes on Android production builds only.
		console.log(
			'Error occured trying to get existing UUID via SecureStore.getItemAsync(secure_deviceid)'
		);
	}
	//if user has already signed up prior
	if (fetchUUID) {
		return fetchUUID;
	}
	let uuid = uuidv4();
	await SecureStore.setItemAsync('secure_deviceid', uuid);
	return uuid;
};
