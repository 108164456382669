import React, { useState } from 'react';
import { Picker } from '@react-native-picker/picker';
import { useTheme, IconButton } from 'react-native-paper';

export const DropdownWeb = ({ pickerItems, answer, handleValueChanged }) => {
	const theme = useTheme();
	const [focused, setFocused] = useState(false);

	const pickerStyle = {
		...theme.fonts.regular,
		fontSize: 20,
		color: theme.colors.text,
		backgroundColor: theme.colors.background,
		borderRadius: 4,
		borderWidth: focused ? 2 : 1,
		minHeight: 56,
		marginTop: 5,
		padding: 10,
		borderColor: focused ? theme.colors.primary : theme.colors.placeholder,
		outline: 'none',
		appearance: 'none',
	};
	const iconStyle = {
		position: 'absolute',
		display: 'inline-block',
		right: -10,
		top: 25,
		pointerEvents: 'none',
	};

	return (
		<>
			<Picker
				style={pickerStyle}
				selectedValue={answer}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				onValueChange={(value) => handleValueChanged(value)}
			>
				{pickerItems}
			</Picker>
			{/* <IconButton icon="chevron-down" style={iconStyle} size={28}
				color={focused ? theme.colors.primary : theme.colors.placeholder} /> */}
		</>
	);
};
