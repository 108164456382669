import React, { useState, useRef } from 'react';
import { Keyboard, StyleSheet} from 'react-native';
import { FAB, TextInput, useTheme } from 'react-native-paper';
import { Picker } from '@react-native-picker/picker';

export const AttachmentAndroid = ({ confirmPicker }) => {
	const [selectorValue, setSelectorValue] = useState('select');

	const theme = useTheme();
	const pickerRef = useRef();

	function open() {
		Keyboard.dismiss();
		pickerRef.current.focus();
	}

	function close() {
		pickerRef.current.blur();
	}

	const handleValueChanged = (value) => {
		setSelectorValue('select');
		confirmPicker(value);
	};

	return (
		<>
			<TextInput
				right={
					<TextInput.Icon icon="tray-arrow-up" color={theme.colors.primary} />
				}
				mode="outlined"
				showSoftInputOnFocus={false}
				placeholder="Camera, Photo or File Upload"
				onFocus={() => open()}
			/>
			<Picker
				ref={pickerRef}
				style={{ display: 'none' }}
				selectedValue={selectorValue}
				onValueChange={(value) => handleValueChanged(value)}
			>
				<Picker.Item value="select" key={0} label="" />
				<Picker.Item value="camera" key={1} label="Take a Picture" />
				<Picker.Item value="photo" key={2} label="Select a Photo" />
				<Picker.Item value="file" key={3} label="Upload a File" />
			</Picker>
		</>
	);
};

export const FABAttachmentAndroid = ({ confirmPicker }) => {
	const [selectorValue, setSelectorValue] = useState('select');

	const theme = useTheme();
	const pickerRef = useRef();

	const styles = StyleSheet.create({
		fab: {
			backgroundColor: theme.colors.primary,
			position: 'absolute',
			margin: 16,
			right: 0,
			bottom: 0,
			zIndex: 1000
		}
	});

	function open() {
		Keyboard.dismiss();
		pickerRef.current.focus();
	}

	function close() {
		pickerRef.current.blur();
	}

	const handleValueChanged = (value) => {
		setSelectorValue('select');
		confirmPicker(value);
	};

	return (
		<>
			<FAB style={styles.fab} icon="upload" onPress={() => open()} />
			<Picker
				ref={pickerRef}
				style={{ display: 'none' }}
				selectedValue={selectorValue}
				onValueChange={(value) => handleValueChanged(value)}
			>
				<Picker.Item value="select" key={0} label="" />
				<Picker.Item value="camera" key={1} label="Take a Picture" />
				<Picker.Item value="photo" key={2} label="Select a Photo" />
				<Picker.Item value="file" key={3} label="Upload a File" />
			</Picker>
		</>
	);
};
