import parse from 'date-fns/parse';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

// export function DateToString(date, fmt) {
// 	if (!fmt) fmt = 'MM/dd/yyyy'; 
// 	const formatDate = format (date, fmt);
// 	return formatDate;
// }

export const DateToString = (date, fmt) => {
	if (!fmt) fmt = 'MM/dd/yyyy'; 
	return format(date, fmt);
};

export const StringToDate= async (date, fmt) => {
	if (!fmt) fmt = 'MM/dd/yyyy'; 
	const parsedDate = parse(date, fmt, 0);
	if (isValid(parsedDate)) return parsedDate;
	return null;
};
