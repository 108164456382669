import React, { useRef, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useTheme } from 'react-native-paper';
import CircularProgress from 'react-native-circular-progress-indicator';

export const FormProgress = ({ complete, delay, submitted }) => {
	const theme = useTheme();

	const [initialized, setInitialized] = useState(false);
	const [value, setValue] = useState(0);
	const [activeStrokeColor, setActiveStrokeColor] = useState(theme.colors.primary);
	const [inActiveStrokeColor, setInActiveStrokeColor] = useState(theme.colors.primary);
	const [dashedStrokeConfig, setDashedStrokeConfig] = useState({ count: 60, width: 2 });
	const [progressValueColor, setProgressValueColor] = useState(theme.colors.text);
	
	const [title, setTitle] = useState('');

	useEffect(() => {
		setProgressValueColor(theme.colors.text);
	}, [theme]);

	const animationComplete = () => {
		if (!initialized) {
			setTimeout(function () {
				setValue(complete);
				setDashedStrokeConfig({ count: null, width: null });
				setTitle(Platform.OS === 'web' ? '%' : '');

				if (submitted) {
					setActiveStrokeColor('#2ecc71');
					setInActiveStrokeColor('black');
					setValue(100);
				}
			}, delay);
			setInitialized(true);
		}
	};

	return (
		<CircularProgress
			onAnimationComplete={() => { animationComplete(); }}
			initialValue={100}
			maxValue={100}
			value={value}
			valueSuffix={Platform.OS === 'web' ? '' : '%'}
			duration={1000}
			radius={35}
			activeStrokeColor={activeStrokeColor}
			activeStrokeWidth={10}
			inActiveStrokeColor={inActiveStrokeColor}
			inActiveStrokeOpacity={0.2}
			progressValueColor={progressValueColor}
			showProgressValue={true}
			dashedStrokeConfig={dashedStrokeConfig}
			title={title}
			titleColor={theme.colors.text}
		/>
	);
};

