import * as SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';

export const getItemAsync = async (key) => {
	if (Platform.OS === 'web') {
		return localStorage.getItem(key);
	} else {
		return await SecureStore.getItemAsync(key);
	}
};

export const setItemAsync = async (key, value) => {
	if (typeof value !== 'string') value = JSON.stringify(value);
	if (Platform.OS === 'web') {
		localStorage.setItem(key, value);
	} else {
		await SecureStore.setItemAsync(key, value);
	}
};