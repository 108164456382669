import React, { useContext }from 'react';
import { Platform, StyleSheet } from 'react-native';
import { Badge, useTheme } from 'react-native-paper';
import { TabBar } from 'react-native-tab-view';

import { CaseContext } from '~context/caseContext';

import IconForm from '@@icons/form.svg';
import IconMessage from '@@icons/message.svg';
import IconMessageWeb from '@@icons/message-web.svg';
import IconFile from '@@icons/files.svg';
import IconTreatment from '@@icons/treatment.svg';
import IconCalendar from '@@icons/calendar.svg';

export const CaseTabBar = (props) => {
	const theme = useTheme();
	const _case = useContext(CaseContext);

	const {index, setIndex} = props;

	const MessageIcon = Platform.OS === 'web' ? IconMessageWeb : IconMessage;
	const tabBarHeight = Platform.OS === 'web' ? 75 : 65;
	const badgeRightMargin = Platform.OS === 'web' ? 50 : 15;

	const styles = StyleSheet.create({
		tabBar: { 
			backgroundColor: theme.colors.surface, 
			height: tabBarHeight
		},
		indicator: { backgroundColor: theme.colors.primary },
		badge: { 
			marginRight:badgeRightMargin,
			marginTop: 5
		}
	});

	return (
		<TabBar 
			{...props} 
			onIndexChange={setIndex} 
			index={index} 
			setIndex={setIndex}
			indicatorStyle={styles.indicator}
			style={styles.tabBar}
			renderLabel={() => null}
			renderIcon={({ route, focused }) => {
				switch(route.key){
				case 'forms':
					return <IconForm fill={focused ? theme.colors.primary : theme.colors.text} />;
				case 'messages':
					return <MessageIcon fill={focused ? theme.colors.primary : theme.colors.text} />;								
				case 'files':
					return <IconFile fill={focused ? theme.colors.primary : theme.colors.text} />;								
				case 'treatment':
					return <IconTreatment fill={focused ? theme.colors.primary : theme.colors.text} />;
				case 'calendar':
					return <IconCalendar fill={focused ? theme.colors.primary : theme.colors.text} />;								
				}
			}}
			renderBadge={({ route }) => {
				switch(route.key){
				case 'forms':					
					return <Badge visible={_case.formsBadge}  size={12} style={styles.badge} />;
				case 'messages':
					return <Badge visible={_case.messagesBadge}  size={12} style={styles.badge} />;
				case 'files':
					return <Badge visible={_case.filesBadge}  size={12} style={styles.badge} />;
				case 'treatment':
					return <Badge visible={_case.treatmentsBadge}  size={12} style={styles.badge} />;
				}			
			}} 		
		/>
	);
};

