import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet, View, TouchableOpacity, Platform } from 'react-native';
import { Card, Chip, Text, useTheme } from 'react-native-paper';
import relativeDate from 'relative-date';
import moment from 'moment';

const BlogItem = ({ id, summary, title, body, category, date, imgUrl }) => {
	const { colors } = useTheme();
	const navigation = useNavigation();

	// Format Date
	const currentDate = new Date();
	const publishedDate = new Date(date);
	const diffTime = Math.abs(currentDate - publishedDate);
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	let showTime;
	if (diffDays > 7) {
		showTime = `${moment(publishedDate).format('l')}`;
	} else {
		showTime = relativeDate(publishedDate);
	}

	const styles = StyleSheet.create({
		blogItemContainer: {
			padding: 25
		},
		card: {
			borderRadius: 12,
			backgroundColor: colors.tabBackground
		},
		cardCover: {
			borderTopLeftRadius: 12,
			borderTopRightRadius: 12,
			height: 170
		},
		cardContentView: {
			flex: 1,
			marginTop: 20,
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		title: {
			marginTop: 10
		},
		subtitle: {
			fontSize: 16,
			letterSpacing: -0.35,
		},
		categoryChip: {
			height: 24
		},
		categoryText: {
			textTransform: 'uppercase',
			fontFamily: 'proxima-nova-semibold',
			fontSize: 11,
			letterSpacing: 0.38,
			lineHeight: 20
		},
		formatDate: {
			color: colors.disabled,
			fontSize: 13,
			letterSpacing: -0.25,
			lineHeight: 22,
		}
	});

	return (
		<TouchableOpacity 
			key={id}
			style={styles.blogItemContainer}
			onPress={() => navigation.navigate( 'Blog', { id, summary, body, title, category, date, imgUrl })}
		> 
			<Card style={styles.card} elevation={Platform.OS === 'web' ? 5 : 1}>
				<Card.Cover style={styles.cardCover} source={{ uri: imgUrl }} />
				<Card.Title title={title} titleStyle={styles.title} subtitle={summary} subtitleNumberOfLines={2} subtitleStyle={styles.subtitle}/>
				<Card.Content>
					<View style={styles.cardContentView}>
						<View>
							<Chip style={styles.categoryChip} textStyle={styles.categoryText}>{category}</Chip>
						</View>
						<View>
							<Text style={styles.formatDate}>{showTime}</Text>
						</View>
					</View>
				</Card.Content>
			</Card>
		</TouchableOpacity>
	);
};

export default BlogItem;