import React, { useState } from 'react';
import { Keyboard, StyleSheet, TouchableHighlight, View } from 'react-native';
import {
	Divider,
	FAB,
	Modal,
	Portal,
	Text,
	TextInput,
	useTheme
} from 'react-native-paper';
import { Picker } from '@react-native-picker/picker';

export const AttachmentIOS = ({ confirmPicker }) => {
	const [selectorVisible, setSelectorVisible] = useState(false);
	const [selectorValue, setSelectorValue] = useState('camera');

	const theme = useTheme();

	const styles = StyleSheet.create({
		modalContent: {
			backgroundColor: theme.colors.surface,
			marginHorizontal: 10,
			borderRadius: 10
		},
		confirmView: {
			alignItems: 'center',
			justifyContent: 'center',
			height: 50,
			borderRadius: 10
		},
		confirmText: {
			color: theme.colors.primary,
			fontSize: 20,
			fontWeight: 'bold'
		},
		pickerItems: {
			color: theme.colors.text
		}
	});

	function open() {
		Keyboard.dismiss();
		setSelectorVisible(true);
	}

	function close() {
		setSelectorVisible(false);
	}

	const handleValueChanged = (value) => {
		setSelectorValue(value);
	};

	return (
		<>
			<TextInput
				right={
					<TextInput.Icon icon="tray-arrow-up" color={theme.colors.primary} />
				}
				mode="outlined"
				showSoftInputOnFocus={false}
				placeholder="Camera, Photo or File Upload"
				onFocus={() => open()}
			/>
			<Portal>
				<Modal
					style={styles.modalBackground}
					visible={selectorVisible}
					transparent={true}
					presentationStyle="overFullScreen"
					onDismiss={() => close()}
					animationType="fade"
				>
					<View style={styles.modalContent}>
						<Picker
							placeholder="Select an option"
							itemStyle={styles.pickerItems}
							selectedValue={selectorValue}
							onValueChange={(value) => handleValueChanged(value)}
						>
							<Picker.Item value="camera" key={1} label="Take a Picture" />
							<Picker.Item value="photo" key={2} label="Select a Photo" />
							<Picker.Item value="file" key={3} label="Upload a File" />
						</Picker>
						<Divider />
						<TouchableHighlight
							style={styles.confirmView}
							onPress={() => {
								setSelectorVisible(false);
								confirmPicker(selectorValue);
							}}
						>
							<Text style={styles.confirmText}>Confirm</Text>
						</TouchableHighlight>
					</View>
				</Modal>
			</Portal>
		</>
	);
};

export const FABAttachmentIOS = ({ confirmPicker }) => {
	const [selectorVisible, setSelectorVisible] = useState(false);
	const [selectorValue, setSelectorValue] = useState('camera');

	const theme = useTheme();

	const styles = StyleSheet.create({
		modalContent: {
			backgroundColor: theme.colors.surface,
			marginHorizontal: 10,
			borderRadius: 10
		},
		confirmView: {
			alignItems: 'center',
			justifyContent: 'center',
			height: 50,
			borderRadius: 10
		},
		confirmText: {
			color: theme.colors.primary,
			fontSize: 20,
			fontWeight: 'bold'
		},
		pickerItems: {
			color: theme.colors.text
		},
		fab: {
			backgroundColor: theme.colors.primary,
			position: 'absolute',
			margin: 16,
			right: 0,
			bottom: 0,
			zIndex: 1000
		}
	});

	function open() {
		Keyboard.dismiss();
		setSelectorVisible(true);
	}

	function close() {
		setSelectorVisible(false);
	}

	const handleValueChanged = (value) => {
		setSelectorValue(value);
	};

	return (
		<>
			<FAB style={styles.fab} icon="upload" onPress={() => open()} />
			<Portal>
				<Modal
					style={styles.modalBackground}
					visible={selectorVisible}
					transparent={true}
					presentationStyle="overFullScreen"
					onDismiss={() => close()}
					animationType="fade"
				>
					<View style={styles.modalContent}>
						<Picker
							placeholder="Select an option"
							itemStyle={styles.pickerItems}
							selectedValue={selectorValue}
							onValueChange={(value) => handleValueChanged(value)}
						>
							<Picker.Item value="camera" key={1} label="Take a Picture" />
							<Picker.Item value="photo" key={2} label="Select a Photo" />
							<Picker.Item value="file" key={3} label="Upload a File" />
						</Picker>
						<Divider />
						<TouchableHighlight
							style={styles.confirmView}
							onPress={() => {
								setSelectorVisible(false);
								confirmPicker(selectorValue);
							}}
						>
							<Text style={styles.confirmText}>Confirm</Text>
						</TouchableHighlight>
					</View>
				</Modal>
			</Portal>
		</>
	);
};
