import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, TextInput } from 'react-native-paper';
import { Validation } from '~components/formInputs/validation';

const styles = StyleSheet.create({
	questionView: {
		marginVertical: 10
	}
});

export const TextField = ({ question, updateScreen }) => {
	const [answer, setAnswer] = useState(question.Answer);
	
	return (
		<View style={styles.questionView}>
			<Text>{question.Question}</Text>
			<TextInput
				mode="outlined"
				returnKeyType={'done'}
				returnKeyLabel={'done'}
				value={answer ?? ''}
				onChangeText={(answer) => {
					setAnswer(answer);
					updateScreen(question.Id, answer);
				}}
			/>
			<Validation question={question} />
		</View>
	);
};

/// Used for non Case Forms
export const TextFieldBasic = ({ question, answer, setAnswer }) => {
	return (
		<View style={styles.questionView}>
			<Text>{question}</Text>
			<TextInput
				mode="outlined"
				returnKeyType={'next'}
				returnKeyLabel={'next'}
				value={answer}
				onChangeText={(answer) => {
					setAnswer(answer);
				}}
			/>
		</View>
	);
};
