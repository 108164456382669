import { V1Request } from '~utils/apiRequest';

const useExpoPushTokenRefresh = async (pushToken) => {
	if (!pushToken) return;
	await V1Request(
		'post',
		`ValidatePushToken?pushToken=${pushToken}`,
		null,
		null,
		'json',
		null
	);
};

export default useExpoPushTokenRefresh;
