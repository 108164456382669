import React from 'react';

export const AppContext = React.createContext({
	agreedTerms: false,
	setAgreedTerms: () => {},
	auth: {},
	setAuth: () => {},
	brand: '',
	setBrand: () => {},
	lastException: {},
	setLastException: () => {},
	loading: false,
	setLoading: () => {},
	loadingMessage: '',
	setLoadingMessage: () => {},
	toggleTheme: () => {},
	isThemeDark: false,
	setLoggedIn: () => {},
	loggedIn: false,	
	notificationVisible: false,
	setNotificationVisible: () => {},
	expoPushToken: '',
	setExpoPushToken: () => {},	
});