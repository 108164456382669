import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { Blog } from '~screens/blog/blog';
import {Redirect} from '~screens/Redirect';

import { LogoTitle } from '~components/logoTitle';
import { HeaderRight } from '~components/headerRight';
import { HeaderLeft } from '~components/headerLeft';

import { BottomTabNavigator } from '~navigators/tab/bottomTabNavigator';
import CasesDrawerNavigator  from '~navigators/drawer/casesDrawerNavigator';

import CPWebView from '~screens/cpWebView/cpWebView';

const Stack = createStackNavigator();

export const MainStack = () => {
	return (
		<Stack.Navigator
			screenOptions={{
				headerTitle: () => <LogoTitle />,
				headerTitleAlign: 'center',
				headerRight: () => <HeaderRight />,
				headerMode: 'float',
				headerBackTitleVisible: false,
				headerBackVisible: false
			}}
		>
			<Stack.Screen name='MainTabNavigator' component={BottomTabNavigator} />
			<Stack.Screen name='CasesDrawerNavigator' component={CasesDrawerNavigator} options={{headerShown:false}} />
			<Stack.Screen
				name="Blog"
				component={Blog}
				options={{
					headerLeft: () => <HeaderLeft screen="BlogsTab" />
				}}
			/>
			<Stack.Screen
				name="Redirect"
				component={Redirect}
			/>
			<Stack.Screen name="CPWebView" component={CPWebView} />
		</Stack.Navigator>
	);
};
