import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { HeaderBackButton } from '@react-navigation/elements';

export const HeaderLeft = ({screen}) => {
	const navigation = useNavigation();
	return (
		<HeaderBackButton
			onPress={() => {
				navigation.navigate(screen);
			}}
		/>
	);
};
