import React from 'react';
import { StyleSheet } from 'react-native';
import { HelperText } from 'react-native-paper';

const styles = StyleSheet.create({
	helperText: {
		fontSize: 14
	}
});

export const Validation = ({ question }) => {
	return (
		<HelperText
			type="error"
			visible={question.ErrorMessage ? true : false}
			style={styles.helperText}
		>
			{question.ErrorMessage}
		</HelperText>
	);
};
