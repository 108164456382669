import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import Constants from 'expo-constants';
import { Text, useTheme } from 'react-native-paper';
import UserAvatar from '../../../../modified_node_modules/react-native-user-avatar/index';

const apiBaseUrl = Constants.expoConfig.extra.apiBaseUrl;

const AttorneyListItem = ({ attorney }) => {
	const { colors } = useTheme();

	const webContainer = { borderBottomWidth: 0 }; // Hide underline/border for web

	const styles = StyleSheet.create({
		container: {
			flex: 1,
			flexBasis: 'auto',
			flexWrap: 'wrap',
			alignItems: 'center',
			flexDirection: 'row',
			minWidth: 75,
			paddingVertical: 20,
			borderBottomWidth: 1,
			borderBottomColor: colors.accent,
			...(Platform.OS === 'web' ? webContainer : null)
		},
		avatar: {
			marginRight: 20
		},
		textview: {
			display: 'flex',
			alignItems: 'flex-start'
		},
		name: {
			fontSize: 18,
			fontWeight: 'bold'
		},
		title: {
			fontSize: 18
		}
	});

	return (
		<>
			<View style={styles.container} key={attorney.name}>
				<UserAvatar
					style={styles.avatar}
					size={54}
					name={attorney.FirstName}
					src={`${apiBaseUrl}PublicImage/AttorneyListingImage?id=${attorney.PartyId}`}
				/>
				<View style={styles.textview}>
					<Text style={styles.name}>
						{attorney.FirstName} {attorney.LastName}{' '}
					</Text>
					<Text style={styles.title}>{attorney.AttorneyListingTitle}</Text>
				</View>
			</View>
		</>
	);
};

export default AttorneyListItem;
