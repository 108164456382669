import React, { useContext, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Appbar, Button, useTheme, Menu, Divider } from 'react-native-paper';

import { Profile } from '~navigators/drawer/drawerContent';
import { AppContext } from '~context/appContext';
import { LogoWeb } from '~components/logoTitle';
import useMQ from '~hooks/useMQ';

const RouteButton = ({
	screen,
	children,
	navigation,
	setMenuVisible,
	route
}) => {
	const theme = useTheme();
	const navpush = (...a) => {
		setMenuVisible(false);
		navigation.push(...a);
	};
	const active = screen === route.name;
	const styles = StyleSheet.create({
		link: {
			fontFamily: 'proxima-nova-light',
			fontWeight: active ? 'bold' : 'normal',
			color: active ? theme.colors.primary : theme.colors.backdrop
		}
	});
	return (
		<Button
			mode="text"
			compact={true}
			labelStyle={styles.link}
			onPress={() => navpush(screen)}
		>
			{children}
		</Button>
	);
};

const Buttons = ({ loggedIn, ...props }) => {
	return (
		<>
			<RouteButton {...props} screen={loggedIn ? 'Cases' : 'Login'}>
				MY CASE
			</RouteButton>
			<RouteButton {...props} screen="Contact">
				CONTACT US
			</RouteButton>
			<RouteButton {...props} screen="Blogs">
				BLOG
			</RouteButton>
			<RouteButton {...props} screen="About">
				ABOUT OUR FIRM
			</RouteButton>
		</>
	);
};

export const WebNavBar = () => {
	const app = useContext(AppContext);
	const [route, setRoute] = useState({});
	const [menuVisible, setMenuVisible] = useState(false);
	const [menuFired, setMenuFired] = useState(0);
	const navigation = app.navigationRef;

	/// Note the shenanagins with open/close menu and checking fired time is because
	/// of a react-native-paper bug that causes the menu to be dismissed immediately
	/// after opening  This is an intermittent bug that seems to be dependent on
	///  screen width and height, and location of the menu.  This "fix" is temporary
	///  until react-native-paper has a permanent fix.
	const openMenu = () => {
		setMenuVisible(true);
		setMenuFired(Date.now());  // sets last time menu was fired
	};
	
	const closeMenu = () => {
		// refuses to close menu in a period of time shorter than 400ms
		if (Date.now() - menuFired > 400) setMenuVisible(false);
	};

	if (!navigation.push) {
		navigation.push = navigation.navigate;
	}
	navigation.addListener('state', () => {
		const r = navigation.getCurrentRoute();
		setRoute(r);
	});

	const theme = useTheme();
	const { lg: wide } = useMQ();
	

	const fontSize = {
		fontSize: 38
	};

	const styles = StyleSheet.create({
		appBar: {
			backgroundColor: theme.colors.surface,
			height: 93,
			borderStyle: 'solid',
			borderTopColor: theme.colors.primary,
			borderTopWidth: 11,
			position: 'relative'
		},
		link: {
			...(!wide ? fontSize : null) // Make hamburger menu larger on small screens
		},
		menu: {
			flex: 1,
			flexDirection: 'row',
			justifyContent: 'flex-end',
			alignSelf: 'flex-end',
			marginTop: 25,
			borderWidth: 1,
			borderRadius: 4,
			borderColor: theme.colors.disabled
		},
		menuContents: {
			paddingHorizontal: 25
		},
		divider: {
			marginBottom: 25
		}
	});

	const SettingsButton =
		app.auth?.loggedIn && app.auth.email ? (
			<Button
				contentStyle={{ flexDirection: 'row-reverse' }}
				icon={wide ? 'chevron-down' : 'menu'}
				labelStyle={styles.link}
				mode="text"
				onPress={() => openMenu()}
			>
				{wide ? app.auth.email : ''}
			</Button>
		) : (
			<Button
				icon={wide ? 'account-outline' : 'menu'}
				labelStyle={styles.link}
				mode="text"
				onPress={() =>
					wide ? navigation.push('Login') : openMenu()
				}
			>
				{wide ? 'LOGIN' : ''}
			</Button>
		);

	const myprops = {
		navigation,
		setMenuVisible,
		route,
		loggedIn: app.auth?.loggedIn && app.auth.email
	};
	return (
		<Appbar style={styles.appBar}>
			<Appbar.Content title={<LogoWeb />} />

			{wide && <Buttons {...myprops} />}
			<Menu
				style={styles.menu}
				visible={menuVisible}
				onDismiss={() => closeMenu()}
				anchor={SettingsButton}
			>
				<View style={styles.menuContents}>
					<Profile {...myprops} />
					{!wide && (
						<>
							<Divider style={styles.divider} />
							<Buttons {...myprops} />
						</>
					)}
				</View>
			</Menu>
		</Appbar>
	);
};
