import React from 'react';

export const CaseContext = React.createContext({
	lawsuitId: {},
	setLawsuitId: () => {},
	caseName: {},
	setCaseName: () => {},
	client: {},
	setClient: () => {},
	caseProgress: [],
	setCaseProgress: () => {},
	initialRoute: {},
	setInitialRoute: () => {},
	formsBadge: false,
	setFormsBadge: () => {},
	messagesBadge: false,
	setMessagesBadge: () => {},
	filesBadge: false,
	setFilesBadge: () => {},
	treatmentsBadge: false,
	setTreatmentsBadge: () => {},
	activeRoute: {},
	setActiveRoute: () => {}
});