import React, { useState } from 'react';
import {
	Text,
	View,
	Image,
	ScrollView,
	StyleSheet,
	useWindowDimensions,
	Platform
} from 'react-native';
import { Card, Chip, useTheme } from 'react-native-paper';
import moment from 'moment';
import RenderHTML from 'react-native-render-html';
import BrandBackground from '~components/brandBackground';
import { WebContainer } from '~components/webContainer';

export const Blog = ({ route }) => {
	const { colors } = useTheme();
	const [blog] = useState(route.params);
	const { width } = useWindowDimensions();

	const styles = StyleSheet.create({
		aboutImage: {
			width: '100%',
			aspectRatio: 2.6,
			margin: 'auto',
			marginBottom: 30,
			borderRadius: 10
		},
		bodyContainer: {
			paddingLeft: 25,
			paddingRight: 25,
			marginTop: 25,
			marginBottom: 50
		},
		headlineText: {
			fontFamily: 'proxima-nova-semibold',
			fontSize: 26,
			color: colors.text
		},
		categoryChip: {
			height: 24
		},
		categoryText: {
			textTransform: 'uppercase',
			fontFamily: 'proxima-nova-semibold',
			fontSize: 12,
			letterSpacing: 0.38,
			lineHeight: 20
		},
		formatDate: {
			backgroundColor: colors.transparent
		},
		cardContentView: {
			flex: 1,
			marginTop: 20,
			flexDirection: 'row',
			justifyContent: 'space-between'
		},
	});

	const source = {
		html: blog.body
	};

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<>
				<BrandBackground>
					<ScrollView>
						<WebContainer>
							<Image
								style={blog.imgUrl ? styles.aboutImage : null}
								source={{ uri: blog.imgUrl }}
							/>
							<View style={styles.cardContentView}>
								<View>
									<Chip
										style={styles.categoryChip}
										textStyle={styles.categoryText}
									>
										{blog.category}
									</Chip>
								</View>
								<View>
									<Chip style={styles.formatDate}>
										{moment(blog.date).format('l')}
									</Chip>
								</View>
							</View>
							<View>
								<Text style={styles.headlineText}>{blog.title}</Text>
								<RenderHTML
									contentWidth={width}
									source={source}
									ignoredStyles={['color', 'backgroundColor']}
									tagsStyles={{
										body: {
											// backgroundColor: colors.background,
											color: colors.text
										},
										a: {
											color: colors.hyperlink,
											textDecorationColor: colors.hyperlink
										}
									}}
								/>
							</View>
						</WebContainer>
					</ScrollView>
				</BrandBackground>
			</>
		);
	}

	function MobileView() {
		return (
			<>
				<ScrollView>
					<Image
						style={blog.imgUrl ? styles.aboutImage : null}
						source={{ uri: blog.imgUrl }}
					/>
					<Card.Content>
						<View style={styles.cardContentView}>
							<View>
								<Chip
									style={styles.categoryChip}
									textStyle={styles.categoryText}
								>
									{blog.category}
								</Chip>
							</View>
							<View>
								<Chip style={styles.formatDate}>
									{moment(blog.date).format('l')}
								</Chip>
							</View>
						</View>
					</Card.Content>
					<View style={styles.bodyContainer}>
						<Text style={styles.headlineText}>{blog.title}</Text>
						<RenderHTML
							contentWidth={width}
							source={source}
							ignoredStyles={['color', 'backgroundColor']}
							tagsStyles={{
								body: {
									backgroundColor: colors.background,
									color: colors.text
								},
								a: {
									color: colors.hyperlink,
									textDecorationColor: colors.hyperlink
								}
							}}
						/>
					</View>
				</ScrollView>
			</>
		);
	}
};
