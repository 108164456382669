import React, { useContext, useState, useEffect, useRef } from 'react';
import {
	Keyboard,
	View,
	ScrollView,
	StyleSheet,
	TouchableOpacity,
	Platform
} from 'react-native';
import {
	Button,
	Card,
	Caption,
	HelperText,
	TextInput,
	Title,
	useTheme
} from 'react-native-paper';
import { AppContext } from '~context/appContext';

import { V1Request } from '~utils/apiRequest';
import { SetAuth } from '~utils/auth';
import useExpoPushTokenRefresh from '~hooks/useExpoPushTokenRefresh';

import call from 'react-native-phone-call';
import CallSvg from '@@icons/ic-call-us.svg';
import { useLocalData } from '~utils/localStorage';
import { WebContainer } from '~components/webContainer';
import BrandBackground from '~components/brandBackground';
import { WebAppLinks } from '~components/webAppLinks';

const CallCardTitle = 'Don\'t have a login?';
const CallCardBody =
	'Tell us what happened and we\'ll evaluate your case for free.';

export const Register = ({ navigation, route }) => {
	const app = useContext(AppContext);
	const theme = useTheme();
	const settingsData = useLocalData('settingsData', {});
	const [userName, setUserName] = useState(route.params?.username ?? '');
	const [password] = useState('');
	const [token] = useState(route.params?.token ?? '');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [registerFail, setRegisterFail] = useState(false);
	const [registerFailMessage, setRegisterFailMessage] = useState('');
	
	const ref_Password = useRef();
	const ref_ConfirmPassword = useRef();

	const callOptions = {
		number: settingsData?.FirmContactPhoneNumber,
		prompt: true,
		skipCanOpen: true
	};

	const styles = StyleSheet.create({
		contentView: {
			flex: 1,
			justifyContent: 'flex-start',
			padding: 25
		},
		card: {
			padding: 5,
			borderRadius: 12,
			backgroundColor: theme.colors.tabBackground,
			marginTop: 25
		},
		cardIcon: {
			marginLeft: 15,
			marginTop: 15
		},
		cardSubtitle: {
			fontSize: 16,
			letterSpacing: -0.35
		},
		textInput: {
			marginTop: 10
		},
		header: { marginBottom: 15 },
		title: { fontSize: 24 },
		caption: { fontSize: 18 },
		inputView: {
			marginBottom: 15
		},
		submitButton: {
			marginTop: 15,
			marginBottom: 15
		},
		submitButtonText: {
			fontSize: 18,
			padding: 5
		},
		helperText: {
			marginLeft: 'auto',
			marginRight: 'auto',
			fontSize: 20
		},
		contentViewWeb: {
			maxWidth: '400px',
			margin: 'auto',
			marginTop: '40px'
		},
		weblinks: {
			flex: 1,
			alignItems: 'center'
		}
	});

	const handleSubmitPress = async () => {
		const response = await V1Request(
			'post',
			'resetpassword',
			null,
			`?userName=${userName}&password=${password}&token=${token}&newPassword=${newPassword}&confirmPassword=${confirmPassword}`,
			'json',
			app.setLoading
		);

		Keyboard.dismiss();

		if (response.status === 0) {
			setRegisterFail(false);
			app.setNotificationTitle('Oops, something went wrong.');
			app.setNotificationMsg('Please try again later');
			app.setNotificationVisible(true);
		} else if (response.status === 403) {
			setRegisterFailMessage('Access Denied.  Please contact your firm.');
			setRegisterFail(true);
		}

		if (response.data?.success) {
			setRegisterFail(false);
			// Register successful, now login
			const response = await V1Request(
				'get',
				'Login',
				null,
				`?userName=${userName}&password=${newPassword}`,
				'json',
				app.setLoading
			);

			response.data.email = userName;
			response.data.loggedIn = true;
			app.setAuth(response.data);
			SetAuth(response.data);

			if (Platform.OS !== 'web') { useExpoPushTokenRefresh(app.expoPushToken); }
			Platform.OS === 'web' ? navigation.push('Cases') : navigation.push('CasesDrawerNavigator');
		} else {
			setRegisterFailMessage(response.data?.error);
			setRegisterFail(true);
		}
	};

	useEffect(() => {
		if (!app.agreedTerms) {
			navigation.navigate('CaseTab', {
				screen: 'TermsOfService',
				params: route.params
			});
		}
	}, []);

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<BrandBackground>
				<ScrollView>
					<WebContainer size={'sm'}>
						<View style={styles.contentViewWeb}>
							<View style={styles.header}>
								<Title style={styles.title}>Create your password</Title>
								<Caption style={styles.caption}>to view your case</Caption>
							</View>

							<View style={styles.inputView}>
								<TextInput
									autoCapitalize="none"
									mode="outlined"
									label="Email Address"
									value={userName}
									onChangeText={(userName) => setUserName(userName)}
									style={styles.textInput}
									disabled
									blurOnSubmit={false}
									onSubmitEditing={() => ref_Password.current.focus()}
									returnKeyType="next"
								/>

								<TextInput
									secureTextEntry
									mode="outlined"
									label="Password"
									value={newPassword}
									onChangeText={(newPassword) => setNewPassword(newPassword)}
									style={styles.textInput}
									blurOnSubmit={false}
									onSubmitEditing={() => ref_ConfirmPassword.current.focus()}
									ref={ref_Password}
									returnKeyType="next"
								/>

								<TextInput
									secureTextEntry
									mode="outlined"
									label="Confirm Password"
									value={confirmPassword}
									onChangeText={(confirmPassword) =>
										setConfirmPassword(confirmPassword)
									}
									style={styles.textInput}
									blurOnSubmit={false}
									onSubmitEditing={(e) => handleSubmitPress(e)}
									ref={ref_ConfirmPassword}
									returnKeyType="go"
								/>
							</View>

							<Button
								mode="contained"
								onPress={(e) => handleSubmitPress(e)}
								style={styles.submitButton}
								labelStyle={styles.submitButtonText}
								uppercase={false}
							>
								Register
							</Button>

							<HelperText
								type="error"
								visible={registerFail}
								style={styles.helperText}
							>
								{registerFailMessage}
							</HelperText>

							<TouchableOpacity>
								<Card
									style={styles.card}
									onPress={() => call(callOptions).catch(console.error)}
								>
									<CallSvg
										fill={theme.colors.text}
										highlight={theme.colors.primary}
										width={35}
										height={35}
										style={styles.cardIcon}
									/>
									<Card.Title
										title={CallCardTitle}
										subtitle={CallCardBody}
										subtitleNumberOfLines={2}
										subtitleStyle={styles.cardSubtitle}
									/>
								</Card>
							</TouchableOpacity>
							<View style={styles.weblinks}>
								<WebAppLinks
									firebaseAndroidApn={settingsData.Firebase_Android_Apn}
									firebaseAppleIsi={settingsData.Firebase_Apple_Isi}
								/>
							</View>
						</View>
					</WebContainer>
				</ScrollView>
			</BrandBackground>
		);
	}

	function MobileView() {
		return (
			<ScrollView keyboardShouldPersistTaps={'handled'}>
				<View style={styles.contentView}>
					<View style={styles.header}>
						<Title style={styles.title}>Create your password</Title>
						<Caption style={styles.caption}>to view your case</Caption>
					</View>

					<View style={styles.inputView}>
						<TextInput
							autoCapitalize="none"
							mode="outlined"
							label="Email Address"
							value={userName}
							onChangeText={(userName) => setUserName(userName)}
							style={styles.textInput}
							disabled
						/>

						<TextInput
							secureTextEntry
							mode="outlined"
							label="Password"
							value={newPassword}
							onChangeText={(newPassword) => setNewPassword(newPassword)}
							style={styles.textInput}
						/>

						<TextInput
							secureTextEntry
							mode="outlined"
							label="Confirm Password"
							value={confirmPassword}
							onChangeText={(confirmPassword) =>
								setConfirmPassword(confirmPassword)
							}
							style={styles.textInput}
						/>
					</View>

					<Button
						mode="contained"
						onPress={(e) => handleSubmitPress(e)}
						style={styles.submitButton}
						labelStyle={styles.submitButtonText}
						uppercase={false}
					>
						Register
					</Button>

					<HelperText
						type="error"
						visible={registerFail}
						style={styles.helperText}
					>
						{registerFailMessage}
					</HelperText>

					<TouchableOpacity>
						<Card
							style={styles.card}
							onPress={() => call(callOptions).catch(console.error)}
						>
							<CallSvg
								fill={theme.colors.text}
								highlight={theme.colors.primary}
								width={35}
								height={35}
								style={styles.cardIcon}
							/>
							<Card.Title
								title={CallCardTitle}
								subtitle={CallCardBody}
								subtitleNumberOfLines={2}
								subtitleStyle={styles.cardSubtitle}
							/>
						</Card>
					</TouchableOpacity>
				</View>
			</ScrollView>
		);
	}
};
