import React, { useState } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { Card, useTheme } from 'react-native-paper';

export const HoverCard = ({ children }) => {
	const { colors } = useTheme();
	const [borderWidth, setBorderWidth] = useState(Platform.OS === 'web' ? 1 : 0);
	
	
	const styles = StyleSheet.create({
		card: {
			cursor: 'pointer',
			padding: 5,
			borderRadius: 8,
			backgroundColor: colors.surface,
			marginBottom: 25,
			marginRight:10,
			paddingBottom: 15,
			borderColor: colors.primary,
			...{borderWidth}
			// ...{boxShadow}
		}
		
	});

	return (
		<Card
			mode='elevated'
			style={styles.card}
			elevation={2}
			onMouseEnter={() => setBorderWidth(2)}
			onMouseLeave={() => setBorderWidth(1)}
		>
			{children}
		</Card>
	);
};
