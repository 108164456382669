import * as Linking from 'expo-linking';

export const ProcessLink = (dynamicLink) => {
	const parsedDynamicLink = Linking.parse(dynamicLink);
	let deepLink = undefined;

	if (
		parsedDynamicLink.hostname === 'cpdirect.casepacer.com' ||
		parsedDynamicLink.hostname === 'cpdirect-qa.casepacer.com' ||
		parsedDynamicLink.hostname === 'jjdirect.casepacer.com'
	) {
		deepLink = dynamicLink;
	} else if (parsedDynamicLink?.hostname === 'google') {
		const dynamicLinkQueryParams = parsedDynamicLink?.queryParams;
		deepLink = dynamicLinkQueryParams?.deep_link_id;
	} else if (parsedDynamicLink?.hostname === 'casepacerdirect.page.link') {
		const dynamicLinkQueryParams = parsedDynamicLink?.queryParams;
		deepLink = dynamicLinkQueryParams?.link;
	}

	if (deepLink) {
		const { path, queryParams } = Linking.parse(deepLink);
		if (path) {
			if (path.endsWith('/sign-up') || path.endsWith('/sign-up/')) {
				return { page: 'Register', params: queryParams };
			} else if (path.endsWith('/password-reset') || path.endsWith('/password-reset/')) {
				return { page: 'PasswordReset', params: queryParams };
			}
		}
	} else return undefined;
};
