import React, { useContext, useEffect, useLayoutEffect, useState} from 'react';
import {
	ImageBackground,
	Platform,
	ScrollView,
	StyleSheet,
	useWindowDimensions,
	View
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Card, Text, useTheme } from 'react-native-paper';
import { useNavigation, useRoute } from '@react-navigation/native';

import { AppContext } from '~context/appContext';
import { CaseContext } from '~context/caseContext';
import { V1Request } from '~utils/apiRequest';
import { LoginRedirect } from '~utils/loginRedirect';

import { HoverCard } from '~components/hoverCard';
import { FormProgress } from '~components/formProgress';

import bg_dark from '@@images/0_shared/generic_bg_dark.jpg';
import bg_light from '@@images/0_shared/generic_bg_light.jpg';

export const Forms = () => {
	const theme = useTheme();
	const { height } = useWindowDimensions();
	const app = useContext(AppContext);
	const _case = useContext(CaseContext);
	const navigation = useNavigation();
	const route = useRoute();
	const [loaded, setLoaded] = useState(false);
	
	const [bg, setBg] = useState(
		theme.dark ? bg_dark : bg_light
	);

	const [forms, setForms] = useState([]);

	useEffect(() => {
		setBg(theme.dark ? bg_dark : bg_light);
	}, [theme]);

	useLayoutEffect(() => {
		const loadData = async () => {
			const response = await V1Request(
				'get',
				`GetFormsForCase/${route.params.lawsuitId}`,
				null,
				null,
				'json',
				app.setLoading
			);
			if (response.status === 401 || response.status === 403) {
				LoginRedirect(app, navigation);
				return;
			}
			
			let incompleteForms = response.data.forms.filter((f) => !f.Submitted);
			let completeForms = response.data.forms.filter((f) => f.Submitted);
			// This will order the forms with incomplete on top	
			setForms([...incompleteForms, ...completeForms]);

			if (incompleteForms.length > 0) {
				_case.setFormsBadge(true);
			} else _case.setFormsBadge(false);
			setLoaded(true);
		};
		loadData();
		
	},[]);

	const contentViewPadding = Platform.OS === 'web' ? 0 : 25;
	
	const styles = StyleSheet.create({
		backgroundImage: {
			flex: 1,
			justifyContent: 'center'
		},
		contentView: {
			flex: 1,
			justifyContent: 'flex-start',
			padding: contentViewPadding,
			paddingTop: 25
		},
		cardText: {
			fontSize: 16,
			letterSpacing: -0.35,
			color: theme.colors.placeholder,
			marginLeft: 15
		},
		statsView: {
			marginTop: -5
		},
		formMessage: {
			fontSize: 28,
			margin: 28,
			textAlign: 'center',
			color: theme.colors.primary
		}
	});

	const stylesWeb = StyleSheet.create({
		formsContainer: {
			minHeight: 400,
			height: height - 360,
			marginBottom: -40,
			paddingTop: 15
		}
	});

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<View style={stylesWeb.formsContainer}>
				<ScrollView>
					{forms?.map((f,index) => {
						let delay = 500 * index;
						let complete = Math.round((f.ClientForm.AnsweredQuestionCount / f.ClientForm.QuestionCount) * 100);
						if (f.Submitted)
							f.ClientForm.AnsweredQuestionCount = f.ClientForm.QuestionCount;
						return (
							<TouchableOpacity
								key={f.Id}
								onPress={() => {
									const parms = {
										lawsuitId: f.LawsuitId,
										name: name,
										clientFormId: f.ClientFormId
									};
									if (f.Submitted) navigation.push('FormReview', parms);
									else navigation.push('Form', parms);
								}}
							>
								<HoverCard submitted={f.Submitted}>
									<Card.Title title={f.ClientForm.Name} />
									<View style={styles.statsView}>
										<Text style={styles.cardText}>
											Pages:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
											{f.ClientForm.ScreenCount}
										</Text>
										<Text style={styles.cardText}>
											Questions:&nbsp;&nbsp;&nbsp; {f.ClientForm.QuestionCount}
										</Text>
										<Text style={styles.cardText}>
											Answered:&nbsp;&nbsp;&nbsp; {f.ClientForm.AnsweredQuestionCount}
										</Text>
										<Text style={styles.cardText}>
											Status:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {f.Submitted ? 'Complete' : 'Incomplete'}
										</Text>
									</View>
									<View style={{ bottom: 0, right: 10, position: 'absolute' }}>
										<FormProgress complete={complete} delay={delay} submitted={f.Submitted} />
									</View>
								</HoverCard>
							</TouchableOpacity>
						);
					})}
				</ScrollView>
			</View>
		);
	}

	function MobileView() {
		return (
			<ImageBackground
				resizeMode="cover"
				style={styles.backgroundImage}
				source={bg}>
				<ScrollView>
					<View style={styles.contentView}>
						{loaded && forms.length == 0 && (
							<Text style={styles.formMessage}>
								You have no forms to complete.
							</Text>
						)}

						{forms?.map((f, index) => {
							let delay = 500 * index;
							let complete = Math.round((f.ClientForm.AnsweredQuestionCount / f.ClientForm.QuestionCount) * 100);
							if (f.Submitted)
								f.ClientForm.AnsweredQuestionCount = f.ClientForm.QuestionCount;
							return (
								<TouchableOpacity
									key={f.Id}
									onPress={() => {
										const parms = {
											lawsuitId: _case.lawsuitId,
											name: _case.caseName,
											clientFormId: f.ClientFormId
										};
										if (f.Submitted) navigation.push('FormReview', parms);
										else navigation.push('Form', parms);
									}}
								>
									<HoverCard submitted={f.Submitted}>
										<Card.Title title={f.ClientForm.Name} />
										<View style={styles.statsView}>
											<Text style={styles.cardText}>
											Pages:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
												{f.ClientForm.ScreenCount}
											</Text>
											<Text style={styles.cardText}>
											Questions:&nbsp;&nbsp;&nbsp; {f.ClientForm.QuestionCount}
											</Text>
											<Text style={styles.cardText}>
											Answered:&nbsp;&nbsp;&nbsp; {f.ClientForm.AnsweredQuestionCount}
											</Text>
											<Text style={styles.cardText}>
											Status:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {f.Submitted ? 'Complete' : 'Incomplete'}
											</Text>
										</View>
										<View style={{ bottom: 0, right: 10, position: 'absolute' }}>
											<FormProgress complete={complete} delay={delay} submitted={f.Submitted} />
										</View>
									</HoverCard>
								</TouchableOpacity>
							);
						})}
					</View>
				</ScrollView>
			</ImageBackground>
		);
	}
};
