/* eslint-disable react-native/no-color-literals */
import React, { useContext, useState } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { Text, useTheme } from 'react-native-paper';
import { CaseContext } from '~context/caseContext';
import StepIndicator from 'react-native-step-indicator';
import { useWindowDimensions } from 'react-native';
import RenderHtml from 'react-native-render-html';

export default React.memo(function CaseProgressVertical() {
	const _case = useContext(CaseContext);
	const theme = useTheme();
	const { width } = useWindowDimensions();
	const [currentPage, setCurrentPage] = useState(0);

	const styles = StyleSheet.create({
		container: {
			flex: 1,
			flexDirection: 'row',
			backgroundColor: '#ffffff',
		},
		stepIndicator: {
			marginVertical: 0,
			paddingHorizontal: 20,
		},
		rowItem: {
			flex: 3,
			paddingVertical: 140,
		},
		title: {
			flex: 1,
			fontSize: 24,
			color: '#333333',
			paddingVertical: 16,
			fontWeight: '800',
		}
	});

	const tagsStyles = {
		body: {
			whiteSpace: 'normal',
			fontSize: 20,
			flex: 1,
			color: '#606060',
			lineHeight: 24,
			marginRight: 8,
		},
	};

	const viewabilityConfig = React.useRef({ viewAreaCoveragePercentThreshold: 50, waitForInteraction: false }).current;

	const renderPage = (rowData) => {
		const item = rowData.item;
		return (
			<View style={styles.rowItem}>
				<Text style={styles.title}>{`${item.SortOrder + 1}. ${item.Name}`}</Text>
				<RenderHtml tagsStyles={tagsStyles} contentWidth={width} source={{html: item.Description}} />
			</View>
		);
	};

	const onViewableItemsChanged = React.useCallback(({ viewableItems }) => {
		const visibleItemsCount = viewableItems.length;
		if (visibleItemsCount !== 0) {
			setCurrentPage(viewableItems[visibleItemsCount - 1].index);
		}
	}, []);

	const stepIndicatorStyles = {
		stepIndicatorSize: 30,
		currentStepIndicatorSize: 40,
		separatorStrokeWidth: 3,
		currentStepStrokeWidth: 5,
		stepStrokeCurrentColor: theme.colors.primary,
		stepStrokeWidth: 3,
		stepStrokeFinishedColor: theme.colors.primary,
		stepStrokeUnFinishedColor: '#aaaaaa',
		separatorFinishedColor: theme.colors.primary,
		separatorUnFinishedColor: '#aaaaaa',
		stepIndicatorFinishedColor: theme.colors.primary,
		stepIndicatorUnFinishedColor: '#ffffff',
		stepIndicatorCurrentColor: '#ffffff',
		stepIndicatorLabelFontSize: 13,
		currentStepIndicatorLabelFontSize: 13,
		stepIndicatorLabelCurrentColor: theme.colors.primary,
		stepIndicatorLabelFinishedColor: '#ffffff',
		stepIndicatorLabelUnFinishedColor: '#aaaaaa',
		labelColor: theme.colors.text,
		labelSize: 12,
		currentStepLabelColor: theme.colors.primary
	};
	
	return (
		<View style={styles.container}>
			<View style={styles.stepIndicator}>
				<StepIndicator
					customStyles={stepIndicatorStyles}
					stepCount={6}
					direction="vertical"
					currentPosition={currentPage}
				/>
			</View>
			<FlatList
				style={{ flexGrow: 1 }}
				data={_case.caseProgress?.CaseProgresses}
				renderItem={renderPage}
				onViewableItemsChanged={onViewableItemsChanged}
				viewabilityConfig={viewabilityConfig}
			/>
		</View>
	);
});