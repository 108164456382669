import React, { useRef, useState } from 'react';
import { Platform, TouchableOpacity, View, StyleSheet } from 'react-native';
import { Button, Card, Text, TextInput, useTheme } from 'react-native-paper';
import call from 'react-native-phone-call';
import * as MailComposer from 'expo-mail-composer';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { ReactNativeEmailLink as openComposer } from '~components/reactNativeEmailLink';

import CallNow from '~components/callNow';
import { useLocalData } from '~utils/localStorage';

import CallSvg from '@@icons/ic-call-us.svg';
import MailSvg from '@@icons/ic-login.svg';

import BrandBackground from '~components/brandBackground';
import { ScrollView } from 'react-native-gesture-handler';
import { WebContainer } from '~components/webContainer';
import useMQ from '~hooks/useMQ';

const CallCardTitle = 'Give us a call';
const CallCardBody =
	'Were you injured?  Need an update on your case?  Speak to our team immediately!';

export const Contact = () => {
	const mq = useMQ();

	var settingsData = useLocalData('settingsData', {});
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [message, setMessage] = useState('');

	const { colors } = useTheme();
	const ref_LastName = useRef();
	const ref_Phone = useRef();
	const ref_Message = useRef();

	const styles = StyleSheet.create({
		bodyContainer: {
			paddingTop: 45,
			paddingLeft: 25,
			paddingRight: 25
		},
		card: {
			padding: 5,
			borderRadius: 15,
			backgroundColor: colors.tabBackground
		},
		cardIcon: {
			marginLeft: 15,
			marginTop: 15
		},

		cardSubtitle: {
			fontSize: 16,
			letterSpacing: -0.35
		},

		headlineText: {
			fontFamily: 'proxima-nova-regular',
			fontSize: 34,
			letterSpacing: -0.35,
			marginTop: 15
		},

		addressName: {
			fontFamily: 'proxima-nova-bold',
			fontWeight: 'bold',
			fontSize: 20,
			marginTop: 20,
			marginBottom: 5,
			letterSpacing: -0.35,
			lineHeight: 22
		},
		addressText: {
			fontFamily: 'proxima-nova-regular',
			fontSize: 18,
			letterSpacing: -0.35,
			lineHeight: 22
		},
		textInput: {
			marginTop: 10
		},
		submitButton: {
			marginTop: 15,
			marginBottom: 75
		},
		submitButtonText: {
			fontSize: 18,
			padding: 5
		}
	});

	const pageContainer_md = {
		flexDirection: 'column'
	};

	const emailUsContainer_md = {
		flex: 1,
		width: '100%'
	};

	const stylesWeb = StyleSheet.create({
		pageContainer: {
			flex: 1,
			flexDirection: 'row',
			justifyContent: 'flex-start',
			...(!mq.lg ? pageContainer_md : null) // If at least md
		},
		contactInfoContainer: {
			flex: 1,
			justifyContent: 'flex-start',
			marginRight: 50
		},
		headlineText: {
			fontFamily: 'proxima-nova-bold',
			fontSize: 44,
			letterSpacing: 0,
			marginBottom: 5
		},
		subtitleText: {
			fontFamily: 'proxima-nova-light',
			fontSize: 20,
			marginBottom: 25
		},
		contactInfoRow: {
			flex: 1,
			flexDirection: 'row',
			marginBottom: 15
		},
		icon: {
			marginRight: 15
		},
		contactInfoText: {
			fontFamily: 'proxima-nova-regular',
			fontSize: 18,
			letterSpacing: -0.35
		},
		locationContainer: {
			marginBottom: 25
		},
		locationText: {
			fontFamily: 'proxima-nova-regular',
			fontSize: 28,
			letterSpacing: 0,
			marginTop: 25,
			marginBottom: 15
		},
		locationTitleText: {
			fontFamily: 'proxima-nova-regular',
			fontSize: 20,
			letterSpacing: 0
		},
		locationAddressText: {
			fontFamily: 'proxima-nova-light',
			fontSize: 18,
			letterSpacing: 0
		},
		emailUsContainer: {
			flex: 2,
			...(!mq.lg ? emailUsContainer_md : null) // If at least md
		},
		submitButton: {
			marginTop: 25,
			marginBottom: 25
		}
	});

	const callOptions = {
		number: settingsData?.FirmContactPhoneNumber,
		prompt: true,
		skipCanOpen: true
	};

	const handleSubmitPress = async () => {
		const body = [
			`My name: ${firstName} ${lastName}%0D%0A`,
			`My phone number: ${phoneNumber}%0D%0A`,
			`Message:\n${message}`
		].join('\n\n');

		const options = {
			recipients: [settingsData?.FirmContactEmail],
			subject: 'CPDirect Contact',
			body: body
		};

		if (Platform.OS === 'web') {
			window.location.href = `mailto:${options.recipients[0]}?subject=${options.subject}&body=${options.body}`;
		} else {
			if (await MailComposer.isAvailableAsync()) {
				MailComposer.composeAsync(options);
			} else {
				openComposer({
					to: options.recipients[0],
					subject: options.subject,
					body: body
				});
			}
		}
	};

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<BrandBackground>
				<ScrollView>
					<WebContainer>
						<View style={stylesWeb.pageContainer}>
							<View style={stylesWeb.contactInfoContainer}>
								<View>
									<Text style={stylesWeb.headlineText}>{CallCardTitle}</Text>
									<Text style={stylesWeb.subtitleText}>{CallCardBody}</Text>

									<View style={stylesWeb.contactInfoRow}>
										<CallSvg
											fill={colors.text}
											highlight={colors.primary}
											width={20}
											height={20}
											style={stylesWeb.icon}
										/>
										<Text style={stylesWeb.contactInfoText}>
											{settingsData.FirmContactPhoneNumber}
										</Text>
									</View>
								</View>
								<View style={stylesWeb.contactInfoRow}>
									<MailSvg
										fill={colors.text}
										highlight={colors.primary}
										width={20}
										height={20}
										style={stylesWeb.icon}
									/>
									<Text style={stylesWeb.contactInfoText}>
										{settingsData.FirmContactEmail}
									</Text>
								</View>

								<Text style={stylesWeb.locationText}>Locations</Text>
								{settingsData?.CPDirectSettingLocations?.map((location) => {
									return (
										<View style={stylesWeb.locationContainer} key={location.Id}>
											<Text style={stylesWeb.locationTitleText}>
												{location.LocationName}
											</Text>
											<Text style={stylesWeb.locationAddressText}>
												{location.Address1}
											</Text>
											{location.Address2 && (
												<Text style={stylesWeb.locationAddressText}>
													{location.Address2}
												</Text>
											)}
											<Text style={stylesWeb.locationAddressText}>
												{location.City}, {location.State} {location.ZipCode}
											</Text>
										</View>
									);
								})}
							</View>

							<View style={stylesWeb.emailUsContainer}>
								<TextInput
									mode="outlined"
									label="First Name"
									value={firstName}
									onChangeText={(firstName) => setFirstName(firstName)}
									style={styles.textInput}
								/>
								<TextInput
									mode="outlined"
									label="Last Name"
									value={lastName}
									onChangeText={(lastName) => setLastName(lastName)}
									style={styles.textInput}
								/>
								<TextInput
									mode="outlined"
									label="Phone Number"
									value={phoneNumber}
									onChangeText={(phoneNumber) => setPhoneNumber(phoneNumber)}
									style={styles.textInput}
								/>
								<TextInput
									mode="outlined"
									multiline
									numberOfLines={6}
									label="Message"
									value={message}
									onChangeText={(message) => setMessage(message)}
									style={styles.textInput}
								/>

								<Button
									mode="contained"
									onPress={(e) => handleSubmitPress(e)}
									style={stylesWeb.submitButton}
									labelStyle={styles.submitButtonText}
									uppercase={false}
								>
									Send Message
								</Button>
							</View>
						</View>
					</WebContainer>
				</ScrollView>
			</BrandBackground>
		);
	}

	function MobileView() {
		return (
			<>
				<KeyboardAwareScrollView
					enableOnAndroid
					enableResetScrollToCoords={false}
				>
					<View style={styles.bodyContainer}>
						<TouchableOpacity>
							<Card
								style={styles.card}
								onPress={() => call(callOptions).catch(console.error)}
							>
								<CallSvg
									fill={colors.text}
									highlight={colors.primary}
									width={35}
									height={35}
									style={styles.cardIcon}
								/>
								<Card.Title
									title={CallCardTitle}
									subtitle={CallCardBody}
									subtitleNumberOfLines={2}
									subtitleStyle={styles.cardSubtitle}
								/>
							</Card>
						</TouchableOpacity>
					</View>

					<View style={styles.bodyContainer}>
						<Text style={styles.headlineText}>Locations</Text>
						{settingsData?.CPDirectSettingLocations?.map((location) => {
							return (
								<View key={location.Id}>
									<Text style={styles.addressName}>
										{location.LocationName}
									</Text>
									<Text style={styles.addressText}>{location.Address1}</Text>
									{location.Address2 && (
										<Text style={styles.addressText}>{location.Address2}</Text>
									)}
									<Text style={styles.addressText}>
										{location.City}, {location.State} {location.ZipCode}
									</Text>
								</View>
							);
						})}
					</View>

					<View style={styles.bodyContainer}>
						<Text style={styles.headlineText}>Email Us</Text>
						<TextInput
							mode="outlined"
							label="First Name"
							value={firstName}
							onChangeText={(firstName) => setFirstName(firstName)}
							style={styles.textInput}
							autoCorrect={false}
							blurOnSubmit={false}
							onSubmitEditing={() => ref_LastName.current.focus()}
							returnKeyType="next"
						/>
						<TextInput
							mode="outlined"
							label="Last Name"
							value={lastName}
							onChangeText={(lastName) => setLastName(lastName)}
							style={styles.textInput}
							autoCorrect={false}
							blurOnSubmit={false}
							onSubmitEditing={() => ref_Phone.current.focus()}
							ref={ref_LastName}
							returnKeyType="next"
						/>
						<TextInput
							mode="outlined"
							label="Phone Number"
							value={phoneNumber}
							onChangeText={(phoneNumber) => setPhoneNumber(phoneNumber)}
							style={styles.textInput}
							keyboardType={
								Platform.OS === 'android' ? 'number-pad' : 'default'
							}
							blurOnSubmit={false}
							onSubmitEditing={() => ref_Message.current.focus()}
							ref={ref_Phone}
						/>
						<TextInput
							mode="outlined"
							multiline
							numberOfLines={6}
							label="Message"
							value={message}
							onChangeText={(message) => setMessage(message)}
							style={styles.textInput}
							ref={ref_Message}
						/>

						<Button
							mode="contained"
							onPress={(e) => handleSubmitPress(e)}
							style={styles.submitButton}
							labelStyle={styles.submitButtonText}
							uppercase={false}
						>
							Submit
						</Button>
					</View>
				</KeyboardAwareScrollView>
				<CallNow number={settingsData?.FirmContactPhoneNumber} />
			</>
		);
	}
};
