import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { Button, Divider, Title } from 'react-native-paper';
import { HeaderBackButton } from '@react-navigation/elements';

import { LoginRedirect } from '~utils/loginRedirect';
import { AppContext } from '~context/appContext';
import { V1Request } from '~utils/apiRequest';
import { ShowQuestions } from '~utils/formLogic';

import { Review } from '~components/formInputs/review';
import BrandBackground from '~components/brandBackground';
import { WebContainer } from '~components/webContainer';

export const FormReview = ({ route, navigation }) => {
	const [questions, setQuestions] = useState([]);
	const [buttonText, setButtonText] = useState('');
	const [form, setForm] = useState({});
	const [elementVisibility, setElementVisibility] = useState(false);

	const app = useContext(AppContext);

	const { lawsuitId, name, clientFormId } = route.params;

	const styles = StyleSheet.create({
		contentView: {
			flex: 1,
			justifyContent: 'flex-start',
			padding: 25
		},
		submitButton: {
			justifyContent: 'center',
			height: 55,
			marginTop: 25,
			marginBottom: 25,
			display: elementVisibility ? null : 'none'
		},
		submitButtonText: {
			fontSize: 18,
			padding: 5
		},
		titleTextWeb: {
			textAlign: 'center',
			display: elementVisibility ? null : 'none'
		},
		divider: {
			marginTop: 15
		},
		formTopBar: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			display: elementVisibility ? null : 'none'
		}
	});

	const backButton = (
		<HeaderBackButton
			onPress={() => {
				if (form?.Submitted) {
					const parms = { lawsuitId, name };
					navigation.replace('Case', parms);
				} else {
					const parms = { lawsuitId, name, clientFormId };
					navigation.replace('Form', parms);
				}
			}}
		/>
	);

	useLayoutEffect(() => {
		navigation.setOptions({
			headerLeft: () => backButton,
			headerTitle: form?.ClientForm?.Name ?? ''
		});
	}, [form]);

	useEffect(() => {
		const loadData = async () => {
			const response = await V1Request(
				'get',
				`ReviewForm/${lawsuitId}?form=${clientFormId}`,
				null,
				null,
				'json',
				app.setLoading
			);

			if (response.status === 401 || response.status === 403) {
				LoginRedirect(app, navigation);
				return;
			}

			setQuestions(response.data.questions);
			setForm(response.data.form);
			if (response.data.form.Submitted) setButtonText('Back to Forms');
			else setButtonText('Submit Form');
			setElementVisibility(true);
		};
		loadData();
	}, []);

	const handleButton = async () => {
		if (form.Submitted) {
			navigation.replace('Case', {
				lawsuitId,
				name
			});
			return;
		} else {
			submitForm();
		}
	};

	const submitForm = async () => {
		const response = await V1Request(
			'post',
			`ReviewForm/${lawsuitId}`,
			null,
			{ form: clientFormId },
			'json',
			app.setLoading
		);
		if (response.status === 200) {
			navigation.replace('Case', {
				lawsuitId,
				name
			});
		} else {
			// Alert Something wrong in save form
			app.setNotificationTitle('Oops, something went wrong.');
			app.setNotificationMsg('Please try again later');
			app.setNotificationVisible(true);
		}
	};

	return <>{Platform.OS === 'web' ? WebView() : MobileView()}</>;

	function WebView() {
		return (
			<BrandBackground>
				<ScrollView>
					<WebContainer size={'md'}>
						<View style={styles.formTopBar}>
							{backButton}
							<Title style={styles.titleTextWeb}>
								{form?.ClientForm?.Name}
							</Title>
							<View style={styles.titleTextWeb}></View>
						</View>
						<Divider style={styles.divider} />
						<View style={styles.contentView}>
							{questions?.filter(ShowQuestions).map((q) => {
								return <Review key={q.Id} question={q} />;
							})}
							<Button
								mode="contained"
								onPress={handleButton}
								style={styles.submitButton}
								labelStyle={styles.submitButtonText}
								uppercase={false}
							>
								{buttonText}
							</Button>
						</View>
					</WebContainer>
				</ScrollView>
			</BrandBackground>
		);
	}

	function MobileView() {
		return (
			<ScrollView>
				<View style={styles.contentView}>
					{questions?.filter(ShowQuestions).map((q) => {
						return <Review key={q.Id} question={q} />;
					})}
					<Button
						mode="contained"
						onPress={handleButton}
						style={styles.submitButton}
						labelStyle={styles.submitButtonText}
						uppercase={false}
					>
						{buttonText}
					</Button>
				</View>
			</ScrollView>
		);
	}
};
