import { get } from 'lodash';

function eqeq(a, b) {
	/* eslint-disable-next-line eqeqeq */
	if (a == b) return true;
	if (a == null && b === '') return true;
	if (a === '' && b == null) return true;
	if (typeof a === 'string' && typeof b === 'boolean' && a === b.toString())
		return true;
	if (typeof a === 'boolean' && typeof b === 'string' && a.toString() === b)
		return true;
	return false;
}

export const ShowQuestions = (q, i, arr) => {
	if (q.Hide) return false;
	const values = this;
	const groups = [];
	if (q.ShowCriteria && q.ShowCriteria.length) {
		// eslint-disable-next-line no-restricted-syntax
		for (const c of q.ShowCriteria) {
			c.Group = c.Group || 0;
			const relE = arr.find((x) => x.RelatedEntity === c.RelatedEntity);
			if (relE) {
				const relAns = get(values, [relE.Id], relE.Answer);
				groups[c.Group] =
					groups[c.Group] !== false &&
					((c.ShowIfMatch && eqeq(relAns, c.MatchValue)) ||
						(!c.ShowIfMatch && !eqeq(relAns, c.MatchValue)));
			}
			/* eslint-enable eqeqeq */
		}
		return groups.indexOf(true) >= 0;
	}
	return true;
};
