import React, { useContext, useState } from 'react';
import * as Application from 'expo-application';
import * as Updates from 'expo-updates';
import * as Linking from 'expo-linking';
import Constants from 'expo-constants';
import { DrawerContentScrollView } from '@react-navigation/drawer';
import { StyleSheet, View } from 'react-native';
import {
	Button,
	Caption,
	Divider,
	Drawer,
	Switch,
	Text,
	Title,
	TouchableRipple
} from 'react-native-paper';
import * as SecureStore from '~utils/secureStore';

import { LogoTitle } from '~components/logoTitle';
import { SetAuth } from '~utils/auth';
import { AppContext } from '~context/appContext';
import { V1Request } from '~utils/apiRequest';

export default function DrawerContent(props) {
	const app = useContext(AppContext);
	const [debugDisplay, setDebugDisplay] = useState('none');
	const [initialUrl, setInitialUrl] = useState(null);
	const prefix = Linking.createURL();
	const linkingUrl = Linking.useURL();
	const schemes = Linking.collectManifestSchemes();
	const [uuid, setUuid] = useState('');

	const UpdatesPressed = async () => {
		console.log(app.lastException);
		try {
			setUuid(await SecureStore.getItemAsync('secure_deviceid'));
		} catch {
			// An error occurred trying getItemAsync.  This seems to happen sometimes on Android production builds only.
			console.log(
				'Error occured trying to get existing UUID via SecureStore.getItemAsync(secure_deviceid)'
			);
		}
		setInitialUrl(await Linking.getInitialURL());
		if (debugDisplay === 'none') setDebugDisplay(null);
		else setDebugDisplay('none');
	};

	return (
		<DrawerContentScrollView {...props}>
			<LogoTitle {...props} />

			<Profile {...props} />

			<Drawer.Section title="Preferences">
				<TouchableRipple onPress={app.toggleTheme}>
					<View style={styles.preference}>
						<Text>Dark Theme</Text>
						<View style={{pointerEvents:'none'}}>
							<Switch value={app.isThemeDark === true} />
						</View>
					</View>
				</TouchableRipple>
			</Drawer.Section>

			<Drawer.Section
				title="App Info"
				onStartShouldSetResponder={() => UpdatesPressed()}
			>
				<View style={styles.debugView}>
					<Text>Version: {Application.nativeApplicationVersion} </Text>
					<Text>Build: {Application.nativeBuildVersion} </Text>
				</View>
			</Drawer.Section>

			<Drawer.Section title="Push Notifications" style={{ display: debugDisplay }}>
				<View style={styles.debugView}>
					<Text>Token: {app.expoPushToken}</Text>
				</View>
			</Drawer.Section>

			<Drawer.Section title="API" style={{ display: debugDisplay }}>
				<View style={styles.debugView}>
					<Text>V1: {Constants.expoConfig.extra.v1ApiUrl} </Text>
					<Text>V2: {Constants.expoConfig.extra.apiBaseUrl} </Text>
				</View>
			</Drawer.Section>

			<Drawer.Section title="Updates" style={{ display: debugDisplay }}>
				<View style={styles.debugView}>
					<Text>UUID: {uuid} </Text>
					<Text>Id: {Updates.updateId} </Text>
					<Text>Channel: {Updates.channel} </Text>
					<Text>Created: {Updates.createdAt?.toDateString()} </Text>
					<Text>Runtime: {Updates.runtimeVersion} </Text>
					<Text>Fallback: {Updates.isEmergencyLaunch?.toString()} </Text>
				</View>
			</Drawer.Section>

			<Drawer.Section title="Last Exception" style={{ display: debugDisplay }}>
				<View style={styles.debugView}>
					<Text>Type: {app.lastException?.name} </Text>
					<Text>Message: {app.lastException?.message} </Text>
				</View>
			</Drawer.Section>

			<Drawer.Section title="Linking" style={{ display: debugDisplay }}>
				<View style={styles.debugView}>
					<Text>Linking Prefix: {prefix} </Text>
					<Text>Schemes: {schemes.toString()} </Text>
					<Divider style={styles.divider} />
					<Text>Linking Url: {linkingUrl} </Text>
					<Divider style={styles.divider} />
					<Text>Initial Url: {initialUrl} </Text>
				</View>
			</Drawer.Section>
		</DrawerContentScrollView>
	);
}

export const Profile = ({ navigation, setDrawerOpen }) => {
	const app = useContext(AppContext);
	const closeDrawer = () => {
		if (navigation.closeDrawer) navigation.closeDrawer();
		else if (setDrawerOpen) setDrawerOpen(false);
	};

	const Logout = () => {
		return (
			<Button
				mode="contained"
				onPress={() => {
					handleLogout();
				}}
				style={styles.button}
				uppercase={false}
			>
				Logout
			</Button>
		);
	};

	const Login = () => {
		return (
			<Button
				mode="contained"
				onPress={() => {
					handleLogin();
				}}
				uppercase={false}
				style={styles.button}
			>
				Login
			</Button>
		);
	};

	const ResetPassword = () => {
		return (
			<Button
				mode="text"
				compact={true}
				onPress={() => {
					closeDrawer();
					navigation.navigate('PasswordReset');
				}}
				style={styles.button}
				uppercase={false}
			>
				Reset Password
			</Button>
		);
	};

	const handleLogin = () => {
		closeDrawer();
		navigation.navigate('Login');
	};

	const handleLogout = async () => {
		await V1Request(
			'get',
			'Logout',
			null,
			`?userName=${app.auth.email}`,
			'json',
			app.setLoading
		);

		app.setAuth({});
		SetAuth(null);

		closeDrawer();
		navigation.navigate('PreLogin');
	};

	if (app.auth?.loggedIn && app.auth?.email) {
		return (
			<View style={styles.userInfoSection}>
				<Title style={styles.title}>Email Address</Title>
				<Caption style={styles.caption}>{app.auth?.email}</Caption>
				<Logout />
				<ResetPassword />
			</View>
		);
	} else {
		return (
			<Drawer.Section style={styles.drawerSection}>
				<View style={styles.userInfoSection}>
					<Login />
				</View>
			</Drawer.Section>
		);
	}
};

const styles = StyleSheet.create({
	userInfoSection: {
		paddingHorizontal: 20,
		paddingTop: 20,
		paddingBottom: 10
	},
	title: {
		fontSize: 16,
		marginTop: 0,
		fontWeight: 'bold'
	},
	caption: {
		fontSize: 14,
		lineHeight: 14,
		marginBottom: 10
	},
	drawerSection: {
		marginTop: 15
	},
	preference: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingVertical: 12,
		paddingHorizontal: 16
	},
	button: {
		marginVertical: 15
	},
	debugView: {
		flex: 1,
		paddingHorizontal: 16
	},
	divider: {
		marginVertical: 15
	}
});
