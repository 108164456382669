import Constants from 'expo-constants';
import { Platform } from 'react-native';

export const Brand = () => {
	if (Platform.OS === 'web') {
		const brand = location.pathname.split('/')[1];
		if (brand === '') return 'cpdirect'; // If no firm slug, use CPDirect brand
		return brand;
	}
	else return Constants.expoConfig.extra.brand;
};

export const FirmId = () => {
	if (Platform.OS === 'web') {
		switch (Brand()) {
		case 'cpdirect':
			return 211224;
		case 'powrog':
			return 513677;
		case 'flg':
			return 288118;
		case 'mmlf':
			return 170775;
		case 'klaw':
			return 128768;
		case 'flaw':
			return 20106;
		case 'cfs':
			return 477769;
		case 'mmdc':
			return 555149;
		case 'slaw':
			return 215010;
		case 'ksbr':
			return 459697;
		case 'vanstone':
			return 576329;
		case 'wieand':
			return 635527;
		default:
			return 211224;
		}
	} else return Constants.expoConfig.extra.firmId;
};
